import React from "react";
import { useHistory } from "react-router-dom";
import { ModalWrapper, ModalContent, ModalBody, ModalButton } from "./styled";

interface ModalProps {
  show: boolean;
  message: string;
}

const Modal: React.FC<ModalProps> = ({ show, message }) => {
  const history = useHistory();

  const handleOKClick = () => {
    history.push("/*");
  };

  if (!show) return null;

  return (
    <ModalWrapper>
      <ModalContent>
        <ModalBody>
          <h2>Alert</h2>
          <p>{message}</p>
          <ModalButton onClick={handleOKClick}>OK</ModalButton>
        </ModalBody>
      </ModalContent>
    </ModalWrapper>
  );
};

export default Modal;
