import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { showToast, successToast } from "components/Toast/toast.slice";
import store from "app/store";
import { parseJwt } from "utils";
import { useHistory } from "react-router-dom";
import updatePassword from "api/updatePasswordApi";
import CONSTANTS from "common/constants";
import getUserPassword from "api/getUserPassword";
import { hashAndCompareUserKeys } from "utils/hashUserKey";
import { SignInWrapper } from "./styled";

const ChangePasswordPage = () => {
  const [newPassword, setNewPassword] = useState("");
  const [email, setEmail] = useState("");
  const [roles, setRole] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [isValidOldPassword, setIsValidOldPassword] = useState(true);
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const userToken = localStorage.userAccessToken;
  const tokenPayload = parseJwt(userToken).parsedJWT;
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const userToken = localStorage.userAccessToken;
    const tokenPayload = parseJwt(userToken).parsedJWT;
    const userEmail = tokenPayload.email;
    const { userRole } = tokenPayload;
    setEmail(userEmail);
    setRole(userRole);
  }, []);

  const handleFormSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setSubmitting(true);

    const userDetails = await getUserPassword(tokenPayload.email);
    const userPassword = userDetails?.data?.result?.password;

    const isValidUserKey = await hashAndCompareUserKeys(
      oldPassword,
      userPassword
    );

    setIsValidOldPassword(isValidUserKey);

    if (isValidUserKey) {
      if (newPassword === oldPassword) {
        setIsValidPassword(false);
        return;
      }
    }

    if (
      isValidUserKey &&
      newPassword === confirmPassword &&
      isValidPassword &&
      signInFormValidation().isValid
    ) {
      await updatePassword(email, newPassword)
        .then(() => {
          successPassword();
          if (roles === CONSTANTS.ROLES.SUPERADMIN) {
            history.push("/encryptionkey");
          } else if (roles === CONSTANTS.ROLES.ADMIN) {
            history.push("/Dashboard");
          } else {
            history.push("/employee-management");
          }
        })
        .catch((error: any) => {
          showToast({
            message: "Failed to update password.",
          });
        });
    }
  };

  const signInFormValidation = () => {
    let errorMessage = "";
    let isValid = true;
    if (
      newPassword.length < 6 ||
      newPassword.search(/[a-zA-Z]+/) === -1 ||
      newPassword.search(/[0-9]+/) === -1
    ) {
      errorMessage = "Invalid Password Format";
      isValid = false;
    }
    const result = {
      isValid,
      visibility: !isValid,
      message: errorMessage,
    };
    if (!isValid) store.dispatch(showToast(result));
    return result;
  };

  const successPassword = () => {
    const successMessage = `Password Changed Successfully`;
    const isValid = false;
    const result = {
      isValid,
      visibility: !isValid,
      message: successMessage,
    };
    if (!isValid) store.dispatch(successToast(result));
    return result;
  };

  const handleOldPasswordChange = (e: { target: { value: any } }) => {
    const oldPasswordValue = e.target.value;
    setOldPassword(oldPasswordValue);
  };

  const handleNewPasswordChange = (e: { target: { value: any } }) => {
    const newPasswordValue = e.target.value;
    setNewPassword(newPasswordValue);
    setPasswordsMatch(newPasswordValue === confirmPassword);
  };

  const handleConfirmPasswordChange = (e: { target: { value: any } }) => {
    const confirmPasswordValue = e.target.value;
    setConfirmPassword(confirmPasswordValue);
    setPasswordsMatch(newPassword === confirmPasswordValue);
  };

  return (
    <SignInWrapper>
      <div className="home-wrapper">
        <div className="home-wrapper-left" role="presentation">
          <img
            src="../../images/AHA_White_logo.png"
            alt="American Heart Association logo"
          />
        </div>
        <div className="home-wrapper-right">
          <div className="home-content">
            <div className="home-content-logo d-flex flex-row-reverse">
              <img src="./images/Impelsyslogo.png" alt="Impelsys logo" />
            </div>
            <div>
              <div className="home-signin-title">
                <p className="font-red pb-3">
                  <a
                    className="mt-4 mt-sm-0"
                    href={
                      roles === CONSTANTS.ROLES.SUPERADMIN
                        ? "/encryptionkey"
                        : "/employee-management"
                    }
                  >
                    Back
                  </a>
                </p>
                <h1 className="signin mb-0 font-400">Reset Password</h1>
                <p className="home-helptext mb-0 pt-3">
                  Please Choose your new password
                </p>
              </div>
              <form autoComplete="off" id="signinForm">
                <div className="form-group row required">
                  <label htmlFor="oldPassword" className="col-12">
                    Old Password
                  </label>
                  <div className="col-12 input-group">
                    <input
                      type="password"
                      className={`form-control ${
                        isValidOldPassword ? "" : "is-invalid"
                      }`}
                      id="oldPassword"
                      onChange={handleOldPasswordChange}
                      value={oldPassword}
                      placeholder="Enter old password"
                      required
                    />
                    {!isValidOldPassword && (
                      <div className="invalid-feedback">
                        Invalid old password
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row required">
                  <label htmlFor="newPassword" className="col-12">
                    New Password
                  </label>
                  <div className="col-12 input-group">
                    <input
                      type="password"
                      className={`form-control ${
                        !isValidPassword ? "is-invalid" : ""
                      }`}
                      id="newPassword"
                      onChange={handleNewPasswordChange}
                      value={newPassword}
                      placeholder="Enter new password"
                      required
                    />
                    {!isValidPassword && (
                      <div className="invalid-feedback">
                        It Seems You Have Entered Same Password As Old Password
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row required">
                  <label htmlFor="password" className="col-12">
                    Confirm New Password
                  </label>
                  <div className="col-12 input-group">
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      onChange={handleConfirmPasswordChange}
                      value={confirmPassword}
                      placeholder="Confirm new password"
                      required
                    />
                  </div>
                  <div>
                    {!passwordsMatch && (
                      <div className="text-danger">Passwords do not match</div>
                    )}
                  </div>
                </div>
                <div className="form-group row required">
                  <label htmlFor="password" className="col-12">
                    Email
                  </label>
                  <div className="col-12 input-group">
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      value={email}
                      readOnly
                      placeholder="Email ID"
                      required
                    />
                  </div>
                </div>
                <div className="home-submit">
                  <button
                    type="submit"
                    className="btn btn-round btn-primary"
                    disabled={!passwordsMatch}
                    onClick={handleFormSubmit}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </SignInWrapper>
  );
};

export default ChangePasswordPage;
