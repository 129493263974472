/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import employeeProfile from "api/employeeProfileApi";
import employeeDeactive from "api/employeeDeactiveApi";
import employeeDelete from "api/employeeDelete";
import getActiveProjects from "api/getActiveProjectsOfEmployee";
import store from "app/store";
import { showToast, successToast } from "components/Toast/toast.slice";
import getTotalUtilization from "api/getTotalUtilization.Api";
import getProjectTeam from "api/projectTeamAPI";
import CONSTANTS from "common/constants";
import { parseJwt } from "utils";
import getEmpRole from "api/getRoleAPI";
import Navigator from "../../components/Navigator";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import getTeamName from "../../api/teamsAPI";
import getRoleName from "../../api/rolesAPI";
import getProjects from "../../api/getProjectAPI";
import assignProject from "../../api/assignProjectAPI";
import { EmployeeManagementWrapper } from "./styled";
import { EmployeeManagementMenu } from "./EmployeeManagementMenu";
import EmployeeManagementUserProfileForm from "./EmployeeManagementUserProfileForm";
import EmployeeManagementForms from "./EmployeeManagemnetForm";
import MultiSelectDropdown from "../../components/Multiselect";
import { getRoles, postRole } from "../../api/getRoles";

const UserProfile = (props: any) => {
  if (!localStorage.userAccessToken) return <Redirect to="/" />;
  const location = useLocation();
  const [userprofile, setUserProfile] = useState<any>([]);
  const [exitDate, setexitDate] = useState("");
  const [resignedDate, setresignedDate] = useState("");
  const [exitType, setexitType] = useState("");
  const { employeeId } = props.match.params;
  const [isEdit, setIsEdit] = useState(false);
  const [projectTeamName, setProjectTeamName] = useState<any>([]);
  const [roleName, setRoleName] = useState<any>([]);
  const [project, setProject] = useState<any>([]);
  const [activeProjects, setActiveProjects] = useState<any>([]);
  const [projectTeam, setProjectTeam] = useState("");
  const [roleId, setRoleId] = useState("");
  const [utilization, setUtilization] = useState("");
  const [projectId, setProjectId] = useState("");
  const [projectName, setProjectName] = useState("");
  const [totalUtilization, setTotalUtilization] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState(false);
  const [utilizationCriteria, setUtilizationCriteria] = useState(true);
  const [edit, setEdit] = useState(false);
  const [newRole, setNewRole] = useState("");
  const [selectedRole, setSelectedRole] = useState<string[]>([]);
  const [userRoles, setUserRoles] = useState<any>();
  const userToken = localStorage.userAccessToken;
  const tokenPayload = parseJwt(userToken).parsedJWT;
  const { userRole } = tokenPayload;
  useEffect(() => {
    setUserRoles(userRole);
  });

  const roleRequest = () => {
    getEmpRole()
      .then((Response) => {
        setRoleName(Response.data.uniqueRolesArray);
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };
  const employeeProfileRequest = () => {
    employeeProfile(employeeId)
      .then((employeeResponse) => {
        setUserProfile(employeeResponse.data.employeeDetails);
        setLoading(true);
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };
  const totalUtilizationRequest = () => {
    getTotalUtilization(employeeId)
      .then((employeeResponse) => {
        setTotalUtilization(employeeResponse.data.utilization);
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };

  const success = (employeeName: any) => {
    const successmessage = ` Employee ${employeeName} is deactivated successfully`;
    const isValid = false;
    const result = {
      isValid,
      visibility: !isValid,
      message: successmessage,
    };
    if (!isValid) store.dispatch(successToast(result));
    return result;
  };
  const successmsg = (employeeName: any) => {
    const successmessage = ` Employee ${employeeName} is deleted successfully`;
    const isValid = false;
    const result = {
      isValid,
      visibility: !isValid,
      message: successmessage,
    };
    if (!isValid) store.dispatch(successToast(result));
    return result;
  };

  const history = useHistory();
  const deactivateUser = (event: any) => {
    event.preventDefault();
    const employeeData = {
      exitType,
      exitDate,
      resignedDate,
    };
    employeeDeactive(employeeId, employeeData)
      .then(() => {
        success(userprofile.fullName);
        history.push(
          {
            pathname: "/employee-management",
          },
          { state: { backToPage: location.state.state.backToPage } }
        );
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };
  const deleteUser = (event: any) => {
    event.preventDefault();
    employeeDelete(employeeId)
      .then(() => {
        successmsg(userprofile.fullName);
        history.push(
          {
            pathname: "/employee-management",
          },
          { state: { backToPage: location.state.state.backToPage } }
        );
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };

  const onSuccess = (employeeName: any) => {
    const successmessage = ` Employee ${employeeName} is assign to project successfully`;
    const isValid = false;
    const result = {
      isValid,
      visibility: !isValid,
      message: successmessage,
    };
    store.dispatch(successToast(result));
    return result;
  };

  const projectRequest = () => {
    getProjects()
      .then((Response) => {
        setProject(Response.data.projects);
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };

  const activeProjectRequest = () => {
    getActiveProjects(employeeId)
      .then((Response) => {
        setActiveProjects(Response.data.projects);
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };

  const remainingProjects = project.filter((list1: any) =>
    activeProjects.every((list2: any) => list2.id !== list1.id)
  );
  const resetModal = (event: any) => {
    setexitType("");
    setexitDate("");
    setresignedDate("");
    setUtilization("");
    setProjectTeam("");
    setRoleId("");
    setSelectedRole([]);
    setProjectId("");
    setSelectedValue(false);
    setEdit(false);
    setUtilizationCriteria(true);
    setProjectName("");
  };

  const assignProjectEmployee = (event: any) => {
    event.preventDefault();
    const employeeData = {
      projectTeam,
      utilization,
      projectId,
      selectedRole,
      employeeId,
    };
    if (employeeData.projectTeam === "") {
      store.dispatch(showToast({ message: "Please select the Project Team" }));
    } else if (employeeData.selectedRole.length === 0) {
      store.dispatch(showToast({ message: "Please select the Role" }));
    } else if (employeeData.projectId === "") {
      store.dispatch(showToast({ message: "Please select the Project" }));
    } else {
      assignProject(employeeData)
        .then(() => {
          totalUtilizationRequest();
          activeProjectRequest();
          onSuccess(userprofile.fullName);
        })
        .catch((error: any) => {
          store.dispatch(showToast(error.details[0].message));
        });
    }
  };
  const projectHandler = (event: any) => {
    event.preventDefault();
    resetModal(event);
    assignProjectEmployee(event);
    if (CONSTANTS.WINDOW.AUI.Search) {
      CONSTANTS.WINDOW.AUI.Search.init();
    }
  };
  const checkUtilization = () => {
    if (edit === true) {
      if (parseInt(utilization, 10) <= 0) {
        setUtilizationCriteria(false);
        store.dispatch(
          showToast({ message: "Utilization cannot be Zero or Negative" })
        );
      } else if (parseInt(utilization, 10) > 100) {
        setUtilizationCriteria(false);
        store.dispatch(
          showToast({ message: "Utilization cannot be more than 100" })
        );
      } else if (
        parseInt(utilization, 10) + parseInt(totalUtilization, 10) >
        100
      ) {
        setUtilizationCriteria(false);
        store.dispatch(
          showToast({ message: "Total Utilization cannot exceed 100 " })
        );
      } else if (
        parseInt(utilization, 10) + parseInt(totalUtilization, 10) <=
        100
      ) {
        setUtilizationCriteria(false);
        store.dispatch(successToast({ message: "Total Utilization is Valid" }));
      } else if (!utilization) {
        setUtilizationCriteria(false);
        store.dispatch(showToast({ message: "Utilization cannot be null" }));
      } else {
        setUtilizationCriteria(true);
      }
    }
  };
  const deactivateEmployee = (event: any) => {
    resetModal(event);
    deactivateUser(event);
  };
  useEffect(() => {
    if (selectedValue === true) {
      getProjectTeam(projectId)
        .then((Response) => {
          setProjectTeamName(Response.data.project.project_teams);
        })
        .catch((error: any) => {
          store.dispatch(showToast(error.details[0].message));
        });
    }
  }, [projectId]);

  useEffect(() => {
    projectRequest();
    roleRequest();
    employeeProfileRequest();
    totalUtilizationRequest();
    activeProjectRequest();
    checkUtilization();
    if (CONSTANTS.WINDOW.AUI.Search) {
      CONSTANTS.WINDOW.AUI.Search.init();
    }
  }, [utilization]);

  const successPost = (eName: any) => {
    const successMessage = `${eName} is successfully added`;
    const isValid = false;
    const result = {
      isValid,
      visibility: !isValid,
      message: successMessage,
    };
    if (!isValid) store.dispatch(successToast(result));
    return result;
  };

  const addRole = (event: any) => {
    event.preventDefault();
    setNewRole("");
    const roleData = {
      name: newRole,
    };
    postRole(roleData)
      .then(() => {
        roleRequest();
        successPost(roleData.name);
        selectedRole.push(roleData.name);
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };

  return (
    <div className="d-flex flex-column">
      <Navigator isActive="employee-management" />
      <div className="d-flex flex-column flex-grow-1">
        <EmployeeManagementWrapper className="container-fluid">
          <div aria-label="breadcrumb">
            <ol className="breadcrumb aha-breadcrumb pl-0">
              <li className="breadcrumb-item">
                <a href="/employee-management">Employee List</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <a href={`/userprofile/${userprofile.employeeId}`}>
                  {userprofile.fullName}
                </a>
              </li>
            </ol>
          </div>
          <h1 className="h3 font-400 emp-mgmt-title d-flex">
            Employee Management
          </h1>
          <div className="d-lg-flex">
            <EmployeeManagementMenu />
            {loading ? (
              <div className="emp-mgt-table flex-grow-1">
                <div className="emp-heading position-relative justify-content-between align-items-center">
                  <div className="foldstructure" />
                  {(userRoles === CONSTANTS.ROLES.ADMIN ||
                    userRoles === CONSTANTS.ROLES.SUPERADMIN) && (
                    <button
                      type="button"
                      className="btn btn-text position-absolute settings settingsfocus ml-1 w-auto"
                      data-toggle="dropdown"
                      aria-label="Settings button"
                      aria-haspopup="true"
                      aria-expanded="true"
                    >
                      <i className="demo-icon aha-icon-settings font-500" />
                    </button>
                  )}
                  <ul className="dropdown-menu p-lg-0">
                    <li>
                      <a
                        className="dropdown-item settings-item py-2"
                        data-toggle="modal"
                        data-backdrop="static"
                        data-keyboard="false"
                        onClickCapture={() => {
                          setEdit(true);
                        }}
                        tabIndex={0}
                        aria-label="assign to project"
                        data-target="#assignproject"
                      >
                        Assign to Project
                      </a>
                    </li>
                    <li>
                      <button
                        className="dropdown-item settings-item py-2"
                        onClick={() => {
                          userprofile.isEdit = true;
                          setIsEdit(true);
                        }}
                        type="button"
                        tabIndex={0}
                        aria-label="edit user"
                      >
                        Edit User
                      </button>
                    </li>
                    <li>
                      <a
                        className="dropdown-item settings-item py-2"
                        data-toggle="modal"
                        data-backdrop="static"
                        data-keyboard="false"
                        data-target="#deactivateuser"
                        tabIndex={0}
                        aria-label="Deactivate User"
                      >
                        Deactivate User
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item settings-item py-2"
                        data-toggle="modal"
                        data-target="#deleteuser"
                        tabIndex={0}
                        aria-label="Delete User"
                      >
                        Delete User
                      </a>
                    </li>
                  </ul>
                  <div className="d-flex justify-content-between">
                    <div className="contentdiv">
                      <h2 className="h3 font-400 mb-0 pt-2">
                        {userprofile.fullName}
                      </h2>
                      <div className="d-flex flex-wrap pt-3 pt-md-0">
                        <div className="d-flex pt-md-2 pr-md-4">
                          <span className="font-bold pr-1">Role :</span>
                          <span>
                            {userprofile.role && userprofile.role.join(", ")}
                          </span>
                        </div>
                        <div className="pt-md-2">
                          <span className="font-bold pr-1">Email :</span>
                          <span>{userprofile.impelsysEmail}</span>
                          <span className="px-4">|</span>
                          <span className="d-inline-flex">
                            {userprofile.ahaEmail}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="imagediv">
                      <div className="userstyle position-relative">
                        <img src="../../images/user.svg" alt="User" />
                      </div>
                      <div className="position-absolute util">
                        <span>{totalUtilization}%</span>
                      </div>
                    </div>
                  </div>
                </div>
                {isEdit ? (
                  <EmployeeManagementForms
                    employee={userprofile}
                    backToProfile={() => {
                      userprofile.isEdit = false;
                      setIsEdit(false);
                      employeeProfileRequest();
                    }}
                  />
                ) : (
                  <EmployeeManagementUserProfileForm data={userprofile} />
                )}
              </div>
            ) : (
              <table
                className="aui-responsive-table aui-table-cols emp-mgt-table aui-table-loader"
                role="alert"
                aria-live="assertive"
                aria-label="Page is Loading"
              />
            )}
          </div>
          {/* Modal for Assign Project start */}
          <div
            className="modal fade show aui-org-modal aui-new-org aui-modal"
            id="assignproject"
            tabIndex={-1}
            aria-labelledby="AssignProject"
            aria-modal="true"
            role="dialog"
          >
            <div className="modal-dialog modal-md">
              <div className="modal-content">
                <div className="modal-header d-flex">
                  <h4 className="h4" id="AssignProject">
                    Assign to Project
                  </h4>
                  <div>Current Utilization: {totalUtilization}%</div>
                </div>
                <form autoComplete="off">
                  <div className="modal-body p-0">
                    <div className="form-group row required">
                      <label className="col-md-5 col-lg-3 col-form-label">
                        Project
                      </label>
                      <div className="col-md-7 col-lg-9">
                        <div
                          id="aui-search-dropdown"
                          className="aui-search-dropdown dropdown-style"
                        >
                          <div className="aui-input-div position-relative">
                            <input
                              id="aui-select-active-projectName"
                              className="w-100 text-truncate form-control"
                              type="text"
                              value={projectName}
                              onChange={(event: any) => {
                                setProjectName(event.target.value);
                              }}
                              placeholder="Select Project"
                              aria-label="Select Project"
                            />
                            <i className="aha-icon-arrow-down pr-2 position-absolute aui-downicon" />
                          </div>
                          <ul id="aui-select-ul" className="aui-search-ui">
                            {remainingProjects.map((obj: any) => {
                              return (
                                <li
                                  id={`aui-search-li-team+${obj.project_id}`}
                                  aria-label={obj.project_name}
                                  tabIndex={0}
                                  onClickCapture={() => {
                                    setProjectId(obj.id);
                                    setProjectName(obj.project_name);
                                    setSelectedValue(true);
                                    setProjectTeam("");
                                  }}
                                >
                                  <span className="aui-search-li">
                                    {obj.project_name}
                                  </span>
                                </li>
                              );
                            })}
                            <li className="aui-noresult-li">
                              <span className="aui-no-result-li">
                                No Results Found
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row required">
                      <label
                        className="col-md-5 col-lg-3 col-form-label"
                        htmlFor="projectTeam"
                      >
                        Project Team
                      </label>
                      <div className="col-md-7 col-lg-9">
                        <select
                          id="projectTeam"
                          className="form-control"
                          value={projectTeam}
                          onChange={(event: any) =>
                            setProjectTeam(event.target.value)
                          }
                          disabled={!projectId}
                        >
                          <option hidden> Select Project Team </option>
                          {projectTeamName.map((obj: any) => {
                            return <option value={obj}>{obj}</option>;
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="form-group row required">
                      <label
                        className="col-md-5 col-lg-3 col-form-label"
                        htmlFor="roleName"
                      >
                        Role
                      </label>
                      <div className="col-md-7 col-lg-9">
                        <MultiSelectDropdown
                          id="role"
                          add="Add new role"
                          post={newRole}
                          items={roleName}
                          check={addRole}
                          selectedValue={selectedRole}
                          change={(e: any) => setNewRole(e.target.value)}
                          callParentOnSelect={(value: any) => {
                            setSelectedRole(value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-group row required">
                      <label
                        className="col-md-5 col-lg-3 col-form-label"
                        htmlFor="utilization"
                      >
                        Utilization(%)
                      </label>
                      <div className="col-md-7 col-lg-9">
                        <input
                          id="utilization"
                          type="number"
                          value={utilization}
                          onChange={(event: any) => {
                            setUtilization(event.target.value);
                            checkUtilization();
                          }}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer mt-4">
                    <div className="w-100">
                      <div className="d-flex flex-row-reverse">
                        <button
                          type="button"
                          className="btn btn-primary btn-round"
                          data-dismiss="modal"
                          onClick={projectHandler}
                          disabled={
                            !(
                              projectId &&
                              projectTeam &&
                              selectedRole &&
                              utilization &&
                              parseInt(utilization, 10) > 0 &&
                              parseInt(utilization, 10) <= 100 &&
                              parseInt(utilization, 10) +
                                parseInt(totalUtilization, 10) <=
                                100
                            )
                          }
                        >
                          Submit
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary btn-round mr-4"
                          onClick={resetModal}
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/* Modal for Assign Project end */}
          {/* Modal for Deactivate User start */}
          <div
            className="modal fade show aui-modal"
            id="deactivateuser"
            tabIndex={-1}
            aria-labelledby="removeuser"
            aria-modal="true"
            role="dialog"
          >
            <div className="modal-dialog modal-md">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="h4" id="removeuser">
                    Deactivate User
                  </h4>
                </div>
                <form autoComplete="off">
                  <div className="modal-body p-0">
                    <div className="form-group row required">
                      <label
                        className="col-md-5 col-lg-3 col-form-label"
                        htmlFor="exitType"
                      >
                        Exit Type
                      </label>
                      <div className="col-md-7 col-lg-9">
                        <select
                          className="form-control"
                          id="exitType"
                          name="exitType"
                          value={exitType}
                          onChange={(event: any) =>
                            setexitType(event.target.value)
                          }
                        >
                          <option hidden>Select</option>
                          <option value="VOLUNTARY">Voluntary</option>
                          <option value="INVOLUNTARY">Involuntary</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group row required">
                      <label
                        className="col-md-5 col-lg-3 col-form-label"
                        htmlFor="resignedDate"
                      >
                        Resigned Date
                      </label>
                      <div className="col-md-7 col-lg-9">
                        <input
                          type="date"
                          className="form-control"
                          id="resignedDate"
                          name="resignedDate"
                          value={resignedDate}
                          onChange={(event: any) =>
                            setresignedDate(event.target.value)
                          }
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group row required">
                      <label
                        className="col-md-5 col-lg-3 col-form-label"
                        htmlFor="exitDate"
                      >
                        Exit date
                      </label>
                      <div className="col-md-7 col-lg-9">
                        <input
                          type="date"
                          className="form-control"
                          id="exitDate"
                          name="exitDate"
                          value={exitDate}
                          onChange={(event: any) =>
                            setexitDate(event.target.value)
                          }
                          required
                        />
                      </div>
                    </div>
                    {exitDate && resignedDate && exitDate < resignedDate && (
                      <p className="validateError">
                        Please enter a valid Exit Date{" "}
                      </p>
                    )}
                    {exitDate && resignedDate && exitDate === resignedDate && (
                      <p className="validateError">
                        Resigned Date & Exit Date cannot be the same{" "}
                      </p>
                    )}
                  </div>
                  <div className="modal-footer mt-4">
                    <div className="w-100">
                      <div className="d-flex flex-row-reverse">
                        <button
                          type="button"
                          className="btn btn-primary btn-round"
                          onClick={deactivateEmployee}
                          data-dismiss="modal"
                          disabled={
                            !(
                              exitType &&
                              exitDate &&
                              resignedDate &&
                              exitDate >= resignedDate
                            )
                          }
                        >
                          Submit
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary btn-round mr-4"
                          onClick={resetModal}
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/* Modal for Deactivate User end */}
          {/* Modal for Delete User start */}
          <div
            className="modal fade show aui-modal"
            id="deleteuser"
            tabIndex={-1}
            aria-labelledby="deleteuser"
            aria-modal="true"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered modal-sm">
              <div className="modal-content">
                <div className="modal-body p-0">
                  <div className="text-center">
                    <p>Are you sure you want to delete this user?</p>
                    <div className="mt-4">
                      <button
                        type="submit"
                        onClick={deleteUser}
                        data-dismiss="modal"
                        className="btn btn-primary btn-round btn-sm mx-2"
                      >
                        Yes
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary btn-round btn-sm mx-2"
                        data-dismiss="modal"
                        aria-label="No"
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Modal for Delete User end */}
        </EmployeeManagementWrapper>
      </div>
    </div>
  );
};

export default UserProfile;
