import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CONSTANTS from "common/constants";
import { parseJwt } from "utils";
import { hashAndCompareUserKeys } from "utils/hashUserKey";
import getAdminByEmail from "api/getAdminIndividual";
import Modal from "components/Modal";
import { showToast } from "../../components/Toast/toast.slice";
import { UserKeyWrapper } from "./styled";

const UserKeyPage = () => {
  const [userKey, setUserKey] = useState("");
  const [userRoles, setUserRoles] = useState<any>();
  const [showModal, setShowModal] = useState(false);
  const userToken = localStorage.userAccessToken;
  const tokenPayload = parseJwt(userToken).parsedJWT;
  const history = useHistory();
  const { userRole } = tokenPayload;

  useEffect(() => {
    setUserRoles(userRole);
  }, [userRole]);

  useEffect(() => {
    if (userRoles && userRoles !== CONSTANTS.ROLES.ADMIN) {
      showToast({
        message: "You do not have the required role to access this page.",
      });
      history.push("/Dashboard");
    }
  }, [userRoles, history]);

  const handleUserKeySubmission = async (event: {
    preventDefault: () => void;
  }) => {
    event.preventDefault();

    if (userRoles === CONSTANTS.ROLES.ADMIN) {
      const adminDetails = await getAdminByEmail(tokenPayload.email);
      const adminUserKey = adminDetails?.data?.result?.userKey;

      const isValidUserKey = await hashAndCompareUserKeys(
        userKey,
        adminUserKey
      );
      if (isValidUserKey) {
        const encodedUserkey = btoa(userKey);
        sessionStorage.setItem("userKey", encodedUserkey);
        history.push("/Dashboard");
      } else {
        setShowModal(true);
      }
      setUserKey("");
    }
  };

  return (
    <UserKeyWrapper>
      <div className="user-key-wrapper">
        <div className="user-key-content">
          <h1 className="user-key-title">Enter User Key</h1>

          <form onSubmit={handleUserKeySubmission}>
            <div className="form-group row required">
              <label htmlFor="userKey" className="col-12">
                Please enter the User key:
              </label>
              <div className="col-12">
                <input
                  value={userKey}
                  onChange={(event) => setUserKey(event.target.value)}
                  type="password"
                  className="form-control"
                  id="userKey"
                  required
                />
              </div>
            </div>
            <div className="user-key-submit">
              <button type="submit" className="btn btn-round btn-primary">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      <Modal show={showModal} message="Invalid User Key." />
    </UserKeyWrapper>
  );
};

export default UserKeyPage;
