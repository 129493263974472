import addSalaryHistory from "api/employeeSalaryHistoryApi";
import getRoleName from "api/rolesAPI";
import updateSalary from "api/updateSalaryApi";
import updateSalaryTillDate from "api/updateSalaryHistoryApi";
import store from "app/store";
import CONSTANTS from "common/constants";
import { showToast, successToast } from "components/Toast/toast.slice";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import getEmployeeInvoiceMonthWise from "api/getEmployeeInvoiceMonthWiseApi";
import * as XLSX from "xlsx";
import sendDownloadHistory from "api/sendDownloadHistory";
import getDownloadHistory from "api/getDownloadHistory";

const DownloadHistoryTable = (props: any) => {
  const [historyData, setHistoryData] = useState<any>([]);
  const { refresh } = props;
  const updateDownloadHistory = async () => {
    await getDownloadHistory()
      .then((invoiceResponse) => {
        setHistoryData(invoiceResponse.data.History);
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };
  useEffect(() => {
    updateDownloadHistory();
  }, [refresh]);
  return (
    <div>
      <table className="aui-responsive-status-table">
        <thead>
          <tr>
            <th scope="col">
              <div className="aui-th">Date</div>
            </th>
            <th scope="col">
              <div className="aui-th">File</div>
            </th>
            <th scope="col">
              <div className="aui-th">Action</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {historyData.map((history: any) => (
            <tr key={history.id} className="aui-table-status-deactivated">
              <td data-title="Date">
                <div className="aui-td">{history.downloaded_date}</div>
              </td>
              <td data-title="File">
                <div className="aui-td">{history.file_name}</div>
              </td>
              <td data-title="Action">
                <div className="aui-td">{history.action}</div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
export default DownloadHistoryTable;
