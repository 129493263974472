import styled from "styled-components";

const NavigatorWrapper = styled.div`
  margin-bottom: 20px;
  .active-menu {
    border-bottom: 3px solid #c10e21 !important;
    color: #c10e21 !important;
  }
`;
export default NavigatorWrapper;
