import config from "../config";
import store from "../app/store";
import { validJSON } from "../common/utils";
import { showToast } from "../components/Toast/toast.slice";

const employeeDelete = async (employeeId: number): Promise<any> => {
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("authorization", `${localStorage.userAccessToken}`);
  const userkey: any = sessionStorage.getItem("userKey");
  myHeaders.append("userkey", userkey);
  const requestOptions = {
    method: "DELETE",
    headers: myHeaders,
  };
  return new Promise((resolve, reject) => {
    fetch(
      `${
        config[config.env].apiEnspoints.accounts
      }/employee/employeeProfile/${employeeId}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        if (validJSON(result)) {
          const postResponse = JSON.parse(result);
          if (postResponse.statusCode === 200 && postResponse.success) {
            resolve(postResponse);
          } else {
            throw postResponse.error;
          }
        } else {
          const errorResponse = {
            error: "Error",
            message: "Invalid Response JSON Format",
            code: "Invalid JSON",
          };
          throw errorResponse;
        }
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
        reject(error);
      });
  });
};
export default employeeDelete;
