/* eslint-disable react/require-default-props */
import React, { createRef, useEffect, useState } from "react";
import "styles/custom-multiselect.scss";

interface Props {
  items: any;
  post: any;
  check: any;
  change: any;
  selectedValue: any;
  callParentOnSelect: any;
  id: string;
  add: string;
  disabled?: boolean;
  isLoading?: boolean;
  required?: boolean;
}

const MultiSelectDropdown = (props: Props) => {
  const [timeStamp, setTimeStamp] = useState<number>(Date.now());
  const {
    items,
    selectedValue,
    callParentOnSelect,
    id,
    post,
    check,
    change,
    add,
    disabled,
    isLoading,
    required,
  } = props;
  const [showItems, setShowItems] = useState<boolean>(false);
  const [focusOptionIndex, setFocusOptionIndex] = useState<number>(0);
  const refsArray = items.map(() => createRef());
  const dropDownRef = createRef<HTMLDivElement>();

  useEffect(() => {
    setFocusOptionIndex(0);
    document.addEventListener("click", function (e: any) {
      if (
        !document?.getElementById(`dropdown-wrapper-${id}`)?.contains(e.target)
      ) {
        setShowItems(false);
      }
    });
  }, [items, selectedValue, id]);

  useEffect(() => {
    if (showItems) {
      refsArray[focusOptionIndex]?.current?.focus();
    }
  }, [showItems]);

  const toggleDropDown = () => {
    if (disabled) return;
    setShowItems(!showItems);
  };

  const toggleDropdownOnKeyPress = (event: any) => {
    event?.preventDefault();
    if (event.which === 32 || event.which === 13) {
      toggleDropDown();
    }
  };

  const removeValueFromSelection = (item: any) => {
    let index: number = -1;
    for (let i = 0; i < selectedValue.length; i += 1) {
      if (selectedValue[i] === item) {
        index = i;
        break;
      }
    }

    const newSelectedValue = selectedValue;
    if (index > -1) {
      newSelectedValue.splice(index, 1);
    }
    return newSelectedValue;
  };

  const isChecked = (item: any) => {
    let checked = false;
    for (let i = 0; i < selectedValue.length; i += 1) {
      if (selectedValue[i] === item) {
        checked = true;
        break;
      }
    }
    return checked;
  };

  const selectOption = (item: any, index: number) => {
    if (isChecked(item)) {
      const newSelectedValues = removeValueFromSelection(item);
      callParentOnSelect(newSelectedValues);
    } else {
      const newSelectedValues = selectedValue;
      newSelectedValues.push(item);
      callParentOnSelect(newSelectedValues);
    }
    setTimeStamp(Date.now());
    refsArray[index].current.focus();
  };

  const upAndDownOption = (event: any, item: any, index: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.keyCode === 27 || event.keyCode === 9) {
      dropDownRef?.current?.focus();
      toggleDropDown();
      return;
    }
    if (event.which === 38 && focusOptionIndex > 0) {
      const setIndex = focusOptionIndex - 1;
      setFocusOptionIndex(setIndex);
      refsArray[setIndex].current.focus();
    }
    if (event.which === 40 && focusOptionIndex < items.length - 1) {
      const setIndex = focusOptionIndex + 1;
      setFocusOptionIndex(setIndex);
      refsArray[setIndex].current.focus();
    }
    if (event.which === 13) {
      selectOption(item, index);
    }
  };

  return (
    <div>
      <div
        id={`dropdown-wrapper-${id}`}
        className={`cst-select ${disabled ? "cst-select-disabled" : ""} ${
          isLoading ? "cst-select-loading" : ""
        }`}
      >
        <div
          role="combobox"
          ref={dropDownRef}
          aria-expanded={!!showItems}
          className="cst-select-fld cst-multiselect-fld"
          onClick={() => toggleDropDown()}
          onKeyPress={toggleDropdownOnKeyPress}
          aria-haspopup="listbox"
          aria-owns={`${id}-select-dropdown`}
          aria-controls={`${id}-select-dropdown`}
          id={`${id}-box`}
          tabIndex={0}
        >
          <input
            type="text"
            value=""
            className="cst-selected-item"
            id={`${id}`}
            tabIndex={-1}
            readOnly
          />
          {timeStamp && (
            <ul className="aui-tag-list">
              {selectedValue.length > 0 &&
                selectedValue.map((item: any, index: any) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <li key={`${index}${timeStamp}`} className="aui-tag-item">
                    {item}
                  </li>
                ))}
            </ul>
          )}
          <div
            className={`cst-arrow aha-icon-arrow-down ${
              showItems ? "cst-arrow-up" : "cst-arrow-down"
            }`}
          />
        </div>
        <ul
          style={{ display: showItems ? "block" : "none" }}
          className="cst-select-dropdown"
          aria-labelledby={`${id}`}
          role="listbox"
          aria-expanded={!!showItems}
          id={`${id}-select-dropdown`}
          aria-label="dropdown items"
        >
          <li key="li-">
            <div className="row">
              <input
                type="text"
                placeholder={add}
                value={post}
                onChange={change}
                className="col-sm-9 col-8 m-0 cst-select-fld cst-item-fld "
              />
              <button
                type="button"
                onClick={check}
                className="btn col-sm-3 col-4 cst-item-selected px-2"
              >
                Add
              </button>
            </div>
          </li>
          {items.length > 0 &&
            items
              .filter(
                (itemName: any) =>
                  itemName.toLowerCase().indexOf(post.toLowerCase()) !== -1
              )
              .map((item: any, index: number) => (
              /* eslint-disable */
            <li key={'li-' + index} role="option">
              <div
                onClick={() => selectOption(item, index)}
                className={`${focusOptionIndex === index }`}
                tabIndex={0}
                onKeyDown={(event: any) => upAndDownOption(event, item, index)}
                ref={refsArray[index]}
                key={`option-${index}`}
              >

                <div className="form-check-bordered">
                  {selectedValue.length > -1 && <input type="checkbox" value={item} onChange={() => {}} checked={isChecked(item)} id={`${id}-select-check-${index}`} />}
                  <label htmlFor={`${id}-select-check-${index}`}>{item}</label>
                </div>
              </div>
            </li>
                /* eslint-enable */
              ))}
        </ul>
      </div>
    </div>
  );
};

export default MultiSelectDropdown;
