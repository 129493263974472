import React, { useState, useEffect } from "react";
import { TbFileInvoice } from "react-icons/tb";
import Card from "react-bootstrap/Card";
import { ListGroup } from "react-bootstrap";
import "./styled.scss";
import { MdKeyboardArrowRight } from "react-icons/md";
import store from "app/store";
import { showToast } from "components/Toast/toast.slice";
import getRecentProjects from "api/getProjectLi";
import { useHistory } from "react-router-dom";
import Logo from "../Logo/index";
import { response } from "../../../amplify/backend/function/invoiceaccounts/src/app";

function RecentProjectList() {
  const [recentProjectList, SetRecentProjectList] = useState<any>([]);
  const [colors, setColors] = useState([]);
  const colorArray = ["green", "blue", "red", "peach", "purple"];
  const combineDataWithColors = (data: any) => {
    return data.map((item: any, index: any) => ({
      ...item,
      color: colorArray[index % colorArray.length],
    }));
  };
  useEffect(() => {
    getRecentProjects().then((response) => {
      SetRecentProjectList(response.data.projects.slice(0, 5));
    });
  }, []);
  useEffect(() => {
    const dataWithColors = combineDataWithColors(recentProjectList);
    setColors(dataWithColors);
  }, [recentProjectList]);

  const history = useHistory();

  const handleProjectClick = (projectId: string) => {
    history.push(`/project/${projectId}`);
  };

  return (
    <Card style={{ width: "18rem" }}>
      <Card.Body className="r-card-body">
        <Card.Title className="title">Recent Project List</Card.Title>
        {colors.map((item: any, index: any) => (
          <div>
            <div className="d-flex flex-row justify-content-space-between pb-2">
              <div>
                <Logo color={item.color} title={item.project_code} />
              </div>
              <div className="d-flex flex-column flex-grow-1 proj">
                <h4>{item.project_name}</h4>
                <p>Project ID#01220</p>
              </div>
              <button
                className="icon-button"
                type="button"
                onClick={() => handleProjectClick(item.id)}
              >
                {" "}
                <TbFileInvoice />
              </button>
            </div>
          </div>
        ))}
        <hr />
      </Card.Body>
    </Card>
  );
}

export default RecentProjectList;
