import styled from "styled-components";

const FooterWrapper = styled.footer`
  .footerblock {
    margin-top: 2%;
    background: #6d6e71;
    padding: 30px 28px 28px;
  }
  .footertext {
    text-align: center;
    font: 14px;
    color: #ffffff;
  }
`;
export default FooterWrapper;
