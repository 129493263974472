import React, {
  ReactElement,
  JSXElementConstructor,
  ReactFragment,
  ReactPortal,
} from "react";
import "./styled.scss";

const SummaryBar = (props: {
  classProp: any;
  image: string | undefined;
  title: string | undefined;
  number: number | string | undefined;
}) => {
  const { classProp } = props;
  const { image, title, number } = props;
  return (
    <div
      className={`d-flex flex-row flex-grow-1 box mb-3 mb-lg-0  ${classProp}`}
    >
      <div>
        <img className="logo" src={image} alt="Summary Logo" />
      </div>
      <div className="ml-3 data">
        <p>{title}</p>
        <p>{number}</p>
      </div>
    </div>
  );
};

export default SummaryBar;
