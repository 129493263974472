/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import projectProfileDetails from "api/getProjectDetailsApi";
import store from "app/store";
import CONSTANTS from "common/constants";
import { showToast, successToast } from "components/Toast/toast.slice";
import ProjectManagementForms from "pages/ProjectManagement/ProjectManagementForm";
import projectDelete from "api/projectDelete";
import { parseJwt } from "utils";
import Navigator from "../../components/Navigator";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { ProjectManagementWrapper } from "../ProjectManagement/styled";
import { ShopCPRMenu } from "./ShopCPRMenu";
import ShopCPRForm from "./ShopCPRForm";

const ProjectPage = (props: any) => {
  if (!localStorage.userAccessToken) return <Redirect to="/" />;
  const location = useLocation();
  const [projectProfile, setProjectProfile] = useState<any>([]);
  const { projectId } = props.match.params;
  const [isEdit, setIsEdit] = useState(false);

  const successmsg = (projectName: any) => {
    const successmessage = ` Project ${projectName} is deleted successfully`;
    const isValid = false;
    const result = {
      isValid,
      visibility: !isValid,
      message: successmessage,
    };
    if (!isValid) store.dispatch(successToast(result));
    return result;
  };

  const [loading, setLoading] = useState(false);
  const [userRoles, setUserRoles] = useState<any>();
  const userToken = localStorage.userAccessToken;
  const tokenPayload = parseJwt(userToken).parsedJWT;
  const { userRole } = tokenPayload;
  useEffect(() => {
    setUserRoles(userRole);
  });
  const projectProfileRequest = () => {
    projectProfileDetails(projectId)
      .then((projectResponse) => {
        setProjectProfile(projectResponse.data.projectDetails);
        setLoading(true);
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };
  useEffect(() => {
    projectProfileRequest();
  }, []);
  const history = useHistory();
  const deleteProject = (event: any) => {
    event.preventDefault();
    projectDelete(projectId)
      .then(() => {
        successmsg(projectProfile.projectName);
        history.push(
          {
            pathname: "/project-list",
          },
          { state: { backToPage: location.state.state.backToPage } }
        );
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };

  return (
    <div className="d-flex flex-column">
      <Navigator isActive="project-list" />
      <div className="d-flex flex-column flex-grow-1">
        <ProjectManagementWrapper className="container-fluid">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb aha-breadcrumb pl-0">
              <li className="breadcrumb-item">
                <a href="/project-list">Project List</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <a href={`/project/${projectId}`}>
                  {projectProfile.projectName}
                </a>
              </li>
            </ol>
          </nav>
          <h1 className="h3 font-400 proj-mgmt-title">
            {projectProfile.projectName}
          </h1>
          <div className="d-lg-flex">
            <ShopCPRMenu projectId={projectId} />
            {loading ? (
              <div className="proj-mgt-table flex-grow-1">
                <div className="proj-heading justify-content-between position-relative align-items-center">
                  <div className="position-relative">
                    <h2 className="h3 font-400 mb-0">
                      {projectProfile.projectName}
                    </h2>
                    {projectProfile.logoUrl ? (
                      <div className="position-absolute shoplogo">
                        <img src={projectProfile.logoUrl} alt="Logo" />
                      </div>
                    ) : (
                      <div />
                    )}
                  </div>
                  <div className="foldstructure" />
                  {(userRoles === CONSTANTS.ROLES.ADMIN ||
                    userRoles === CONSTANTS.ROLES.SUPERADMIN) && (
                    <button
                      type="button"
                      className="btn btn-text settingsfocus position-absolute settings ml-1 w-auto"
                      data-toggle="dropdown"
                      aria-label="Dropdownbutton"
                      aria-haspopup="true"
                      aria-expanded="true"
                    >
                      <i className="demo-icon aha-icon-settings font-500" />
                    </button>
                  )}
                  <ul className="dropdown-menu p-lg-0">
                    <li>
                      <a
                        className="dropdown-item settings-item py-2"
                        data-toggle="modal"
                        data-target="#deleteproject"
                      >
                        Delete Project
                      </a>
                    </li>
                    <li>
                      <button
                        className="dropdown-item settings-item py-2"
                        onClick={() => {
                          projectProfile.isEdit = true;
                          setIsEdit(true);
                        }}
                        type="button"
                      >
                        Edit Project Info
                      </button>
                    </li>
                  </ul>
                  <div className="d-md-flex py-2">
                    <span className="d-flex">
                      Team Size: {projectProfile.teamSize}
                    </span>
                    <span className="d-flex pl-md-3">
                      Start Date: {projectProfile.startDate}
                    </span>
                    <span className="pl-md-3">|</span>
                    <span className="pl-md-3">
                      End Date: {projectProfile.actualEndDate}
                    </span>
                  </div>
                </div>
                {isEdit ? (
                  <ProjectManagementForms
                    project={projectProfile}
                    backToProfile={() => {
                      projectProfile.isEdit = false;
                      setIsEdit(false);
                      projectProfileRequest();
                    }}
                  />
                ) : (
                  <ShopCPRForm data={projectProfile} />
                )}
              </div>
            ) : (
              <table
                className="aui-responsive-table aui-table-cols proj-mgt-table aui-table-loader"
                role="alert"
                aria-live="assertive"
                aria-label="Page is Loading"
              />
            )}
          </div>
        </ProjectManagementWrapper>
      </div>

      {/* Modal for Assign Project start */}
      <div
        className="modal fade show aui-modal"
        id="deleteproject"
        tabIndex={-1}
        aria-labelledby="deleteproject"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content">
            <div className="modal-body p-0">
              <div className="text-center">
                <p>Are you sure you want to delete this project?</p>
                <div className="mt-4">
                  <button
                    type="submit"
                    onClick={deleteProject}
                    data-dismiss="modal"
                    className="btn btn-primary btn-round btn-sm mx-2"
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary btn-round btn-sm mx-2"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal for Assign Project end */}
    </div>
  );
};

export default ProjectPage;
