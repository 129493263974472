import config from "../config";
import store from "../app/store";
import { validJSON } from "../common/utils";
import { showToast } from "../components/Toast/toast.slice";

const getTeamMembers = async (
  projectId: number,
  pageNumber: number,
  pageSize: number
): Promise<any> => {
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("authorization", `${localStorage.userAccessToken}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };
  return new Promise((resolve, reject) => {
    fetch(
      `${
        config[config.env].apiEnspoints.accounts
      }/project/teamMembers/${projectId}?pageNumber=` +
        `${pageNumber}` +
        "&pageSize=" +
        `${pageSize}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        if (validJSON(result)) {
          const loginResponse = JSON.parse(result);
          if (loginResponse.statusCode === 200 && loginResponse.success) {
            resolve(loginResponse);
          } else {
            throw loginResponse.error;
          }
        } else {
          const errorResponse = {
            error: "Error",
            message: "Invalid Response JSON Format",
            code: "Invalid JSON",
          };
          throw errorResponse;
        }
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
        reject(error);
      });
  });
};

export default getTeamMembers;
