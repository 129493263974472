import styled from "styled-components";

export const EncryptionKeyWrapper = styled.div`
  .encryption-key-wrapper {
    width: 100%;
    min-height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f4f4f4;
    position: relative;
  }

  .encryption-key-content {
    width: 100%;
    max-width: 400px;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    position: relative;
  }

  .encryption-key-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333333;
  }

  .form-group {
    margin-bottom: 20px;

    label {
      display: block;
      font-size: 16px;
      color: #333333;
      margin-bottom: 8px;
    }

    input {
      width: 100%;
      padding: 10px;
      font-size: 14px;
      border: 1px solid #cccccc;
      border-radius: 4px;
      box-sizing: border-box;
    }
  }

  .encryption-key-submit {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
    margin-right: 10px;

    .download-keys-btn {
      padding: 10px 20px;
      font-size: 16px;
      background-color: #c10e2a;
      color: #ffffff;
      border: none;
      border-radius: 25px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #0056b3;
      }
    }
  }

  .change-password {
    color: #c10e2a;
    text-decoration: none;
    position: absolute;
    bottom: 10px;
    right: 10px;
  }

  .change-password:hover {
    text-decoration: underline;
    text-decoration-color: #c10e2a;
  }

  .aha-icon-unlock {
    margin-right: 5px;
  }
`;
