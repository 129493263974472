import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CONSTANTS from "common/constants";
import { parseJwt } from "utils";
import * as XLSX from "xlsx";
import generateUserKeys from "utils/userKeyGeneration";
import { encrypt } from "utils/encryptionModule";
import store from "app/store";
import { getAdminUsers } from "api/getAdminUsers";
import adminPost from "api/addAdminApi";
import { hashUserKey } from "utils/hashUserKey";
import { showToast, IToast } from "../../components/Toast/toast.slice";
import { EncryptionKeyWrapper } from "./styled";
import Modal from "../../components/Modal";

type Admin = {
  name: string;
  email: string;
};

const EncryptionKeyPage = () => {
  const [encryptionKey, setEncryptionKey] = useState("");
  const [userKeys, setUserKeys] = useState<string[]>([]);
  const [userRoles, setUserRoles] = useState<any>();
  const [adminResponse, setAdminResponse] = useState<any>(null);
  const [allowDownload, setAllowDownload] = useState<boolean>(true);
  const [submitted, setSubmitted] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const history = useHistory();
  const userToken = localStorage.userAccessToken;
  const tokenPayload = parseJwt(userToken).parsedJWT;
  const { userRole } = tokenPayload;

  useEffect(() => {
    setUserRoles(userRole);
  }, [userRole]);

  useEffect(() => {
    if (userRoles && userRoles !== CONSTANTS.ROLES.SUPERADMIN) {
      showToast({
        message: "You do not have the required role to access this page.",
      });
      history.push("/*");
    }
  }, [userRoles, history]);

  useEffect(() => {
    const fetchAdmins = async () => {
      const adminDetails = await getAdminUsers();
      setAdminResponse(adminDetails);
    };

    fetchAdmins();
  }, []);

  const handleDownload = async () => {
    try {
      if (encryptionKey.length === 0) {
        showToast({
          message: "Please enter a valid encryption key.",
        });
        return;
      }
      const numAdmins = adminResponse?.data?.result?.length || 0;

      const filteredKeys = await generateUserKeys(numAdmins);
      if (filteredKeys.length === 0) {
        return;
      }

      setUserKeys(
        filteredKeys.filter((filteredKey) => filteredKey !== null) as string[]
      );
      setAllowDownload(false);

      const uniqueEncryptionKeys = new Set<string>();
      const keyData = [["Encryption Key", "User Key", "name", "email"]];

      if (adminResponse) {
        await Promise.all(
          adminResponse.data.result.map(async (admin: Admin, index: number) => {
            const userKey = filteredKeys[index] as string;

            const hashedUserKey = await hashUserKey(userKey);

            const encryptedEncryptionKey = await encrypt(
              userKey,
              encryptionKey
            );

            if (!uniqueEncryptionKeys.has(encryptedEncryptionKey)) {
              uniqueEncryptionKeys.add(encryptedEncryptionKey);

              if (uniqueEncryptionKeys.size === 1) {
                keyData.push([
                  encryptionKey,
                  userKey,
                  admin?.name || "",
                  admin?.email || "",
                ]);
              } else {
                keyData.push([
                  "",
                  userKey,
                  admin?.name || "",
                  admin?.email || "",
                ]);
              }

              const adminData = {
                name: admin?.name,
                email: admin?.email,
                roleId: 1,
                encEncryptionKey: encryptedEncryptionKey,
                userKey: hashedUserKey,
              };
              await adminPost(adminData);
            }
          })
        );

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(keyData);
        XLSX.utils.book_append_sheet(wb, ws, "Encryption Keys");

        const excelBuffer = XLSX.write(wb, {
          bookType: "xlsx",
          type: "array",
        });

        const blob = new Blob([excelBuffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "encryption_data.xlsx";
        link.click();
        URL.revokeObjectURL(url);

        setSubmitted(true);
        setShowModal(true);
      }
    } catch (error) {
      const toastError: IToast = {
        message: "Error during download. Please try again.",
      };
      store.dispatch(showToast(toastError));
      window.location.reload();
    }
  };

  return (
    <EncryptionKeyWrapper>
      <div className="encryption-key-wrapper">
        <div className="encryption-key-content">
          <h1 className="encryption-key-title">Enter Encryption Key</h1>

          <form>
            <div className="form-group row required">
              <label htmlFor="encryptionKey" className="col-12">
                Please enter the Encryption key:
              </label>
              <div className="col-12">
                <input
                  value={encryptionKey}
                  onChange={(event) => setEncryptionKey(event.target.value)}
                  type="password"
                  className="form-control"
                  id="encryptionKey"
                  required
                />
              </div>
            </div>

            <div className="encryption-key-submit">
              <button
                className="btn btn-round btn-primary download-keys-btn"
                type="button"
                onClick={handleDownload}
                disabled={!allowDownload}
              >
                Download Keys
              </button>

              {submitted && (
                <div className="success-message">Successfully added admin.</div>
              )}
            </div>
          </form>
        </div>
        <a className="change-password" href="/ChangePasswordPage">
          <i className="aha-icon-unlock" />
          Change Password
        </a>
      </div>

      <Modal
        show={showModal}
        message=" Please log in with your Impelsys email address to access the
            application."
      />
    </EncryptionKeyWrapper>
  );
};

export default EncryptionKeyPage;
