import React, { useState, useEffect } from "react";
import Pagination, { IPagination } from "components/Pagination/index";
import { showToast } from "components/Toast/toast.slice";
import employeelist from "api/employeeApi";
import EmployeeTable from "components/EmployeeTable";
import store from "app/store";
import { Redirect } from "react-router-dom";
import Navigator from "../../components/Navigator";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { EmployeeManagementMenu } from "./EmployeeManagementMenu";
import { EmployeeManagementWrapper } from "./styled";
import EmployeeManagementForms from "./EmployeeManagemnetForm";

const AddNewEmployee = () => {
  if (!localStorage.userAccessToken) return <Redirect to="/" />;
  const [employee, setEmployee] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalCount, setTotalCount] = useState();
  const paginationSetLimit = 5;
  const defaultPagination: IPagination = {
    pageNumber: 1,
    pageSize: 6,
    totalCount: 60,
    totalPages: 5,
    isFirst: true,
    isLast: false,
    setPageNumber,
    paginationSetLimit,
  };
  const [employeeForm, setemployeeForm] = useState({
    fullName: "",
    employeeId: "",
    ahaEmail: "",
    impelsysEmail: "",
    phone: "",
    reportingManager: "",
    salary: "",
    vendorCost: "",
    vendorCostExpiryDate: "",
    salaryeffectivefromDate: "",
    impelsysjoinDate: "",
    ahajoinDate: "",
    employeeType: "",
    systemRole: "",
    contractName: "",
    isBillable: "",
    workCategorization: "",
    teamDetails: [],
    isEdit: false,
  });
  const [pagination, setPagination] = useState<IPagination>(defaultPagination);
  useEffect(() => {
    employeelist(
      pageNumber,
      defaultPagination.pageSize,
      "ASC",
      "ALL",
      "ALL",
      ""
    )
      .then((employeeResponse) => {
        setEmployee(employeeResponse.data.employee);
        setPagination({ ...employeeResponse.data._pagination, setPageNumber });
        setTotalCount(employeeResponse.data._pagination.totalCount);
        setLoading(true);
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  }, [pageNumber]);
  return (
    <div className="d-flex flex-column">
      <Navigator isActive="employee-management" />
      <div className="d-flex flex-column flex-grow-1">
        <EmployeeManagementWrapper className="container-fluid">
          <div aria-label="breadcrumb">
            <ol className="breadcrumb aha-breadcrumb pl-0">
              <li className="breadcrumb-item" aria-current="page">
                <a href="/employee-management">Employee List</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <a href="/addnewemployee">Add New Employee</a>
              </li>
            </ol>
          </div>
          <h1 className="h3 font-400 emp-mgmt-title">Employee Management</h1>
          <div className="d-lg-flex">
            <EmployeeManagementMenu />
            {loading ? (
              <div className="emp-mgt-table flex-grow-1">
                <div className="emp-heading justify-content-between align-items-center d-md-flex">
                  <h2 className="h3 font-400 mb-0">Add New Employee</h2>
                  <div>
                    Total no of Employees :
                    <span className="total-emp ml-2 font-600">
                      {totalCount}
                    </span>
                  </div>
                </div>
                <EmployeeManagementForms
                  employee={employeeForm}
                  backToProfile={() => {}}
                />
              </div>
            ) : (
              <table
                className="aui-responsive-table aui-table-cols emp-mgt-table aui-table-loader"
                role="alert"
                aria-live="assertive"
                aria-label="Form is Loading"
              />
            )}
          </div>
        </EmployeeManagementWrapper>
      </div>
    </div>
  );
};

export default AddNewEmployee;
