import React from "react";
import FooterWrapper from "./styled";

const Footer = () => {
  return (
    <FooterWrapper>
      <div className="footerblock">
        <div className="footertext">
          <p className="m-0">&copy;2024 Impelsys Private Limited.</p>
          <p className="m-0">
            <a
              href="https://www.impelsys.com/privacy-policy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </p>
        </div>
      </div>
    </FooterWrapper>
  );
};
export default Footer;
