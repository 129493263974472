import config from "../config";
import store from "../app/store";
import { validJSON } from "../common/utils";
import { showToast } from "../components/Toast/toast.slice";

const getMonthlyProjectInvoice = async (
  projectId: any,
  month: any,
  year: any
): Promise<any> => {
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("authorization", `${localStorage.userAccessToken}`);
  const userkey: any = sessionStorage.getItem("userKey");
  myHeaders.append("userkey", userkey);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };
  return new Promise((resolve, reject) => {
    const orgId = "1";
    fetch(
      `${
        config[config.env].apiEnspoints.accounts
      }/invoice/monthInvoice?orgId=${orgId}&projectId=${projectId}&month=${month}&year=${year}
    `,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        if (validJSON(result)) {
          const defaultInvoiceResponse = JSON.parse(result);
          if (
            defaultInvoiceResponse.statusCode === 200 &&
            defaultInvoiceResponse.success
          ) {
            resolve(defaultInvoiceResponse);
          } else {
            throw defaultInvoiceResponse.error;
          }
        } else {
          const errorResponse = {
            error: "Error",
            message: "Invalid Response JSON Format",
            code: "Invalid JSON",
          };
          throw errorResponse;
        }
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
        reject(error);
      });
  });
};

export default getMonthlyProjectInvoice;
