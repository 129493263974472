import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";
import { ECharts, EChartsOption } from "echarts";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/title";

interface PieChartProps {
  legendData: string[];
  data: { name: string; value: number }[];
}

const DoughnutChart: React.FC<PieChartProps> = ({ legendData, data }) => {
  const chartRef = useRef<HTMLDivElement>(null);
  let chartInstance: ECharts | null = null;

  useEffect(() => {
    const getTotalValue = () => {
      return data.reduce((total, item) => total + item.value, 0);
    };
    if (chartRef.current) chartInstance = echarts.init(chartRef.current);

    const options = {
      tooltip: {
        trigger: "item",
        show: true,
      },

      legend: {
        textStyle: {
          fontWeight: "bold",
        },
        data: legendData,
        top: "bottom",
        itemGap: 30,
        itemWidth: 20,
        itemHeight: 15,
        formatter(name: string) {
          const dataIndex = legendData.indexOf(name);
          const { value } = data[dataIndex];
          const percentage = (value / getTotalValue()) * 100;

          if (name.length <= 6) {
            return `${name} ${percentage}%`;
          }
          return `${name.substring(0, 8)}\n${name.substring(8)} ${percentage}%`;
        },
      },
      series: [
        {
          name: "Legend",
          type: "pie",
          radius: ["60%", "70%"],
          data,
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: "center",
          },
          emphasis: {
            label: {
              show: false,
              fontSize: 40,
              fontWeight: "bold",
            },
          },
          labelLine: {
            show: true,
          },
        },
      ],
    };

    if (chartInstance !== null) {
      chartInstance.setOption(options);
    }

    return () => {
      if (chartInstance) {
        chartInstance.dispose();
        chartInstance = null;
      }
    };
  }, [legendData, data]);

  return <div ref={chartRef} style={{ width: "92%", height: "350px" }} />;
};

export default DoughnutChart;
