// import 'polyfills'
import "whatwg-fetch";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./app/store";
import App from "./app/App";

function render() {
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById("root")
  );
}

render();

if (process.env.ENV === "local" && module.hot) {
  module.hot.accept("./app/App", render);
}
