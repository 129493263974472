import config from "../config";
import store from "../app/store";
import { validJSON } from "../common/utils";
import { showToast } from "../components/Toast/toast.slice";

const deleteEmployeeFromProject = async (id: any): Promise<any> => {
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("authorization", `${localStorage.userAccessToken}`);

  const requestOptions = {
    method: "Delete",
    headers: myHeaders,
  };

  const url = `${
    config[config.env].apiEnspoints.accounts
  }/employeeProject/deleteEmployeeFromProject/${id}`;

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        if (validJSON(result)) {
          const patchResponse = JSON.parse(result);
          if (patchResponse.statusCode === 200 && patchResponse.success) {
            resolve(patchResponse);
          } else {
            throw patchResponse.error;
          }
        } else {
          const errorResponse = {
            error: "Error",
            message: "Invalid Response JSON Format",
            code: "Invalid JSON",
          };
          throw errorResponse;
        }
      })
      .catch((error: any) => {
        store.dispatch(showToast(error));
        reject(error);
      });
  });
};
export default deleteEmployeeFromProject;
