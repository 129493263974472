const CryptoJS = require("crypto-js");

export async function encrypt(userKey, encryptionKey) {
  return new Promise((resolve, reject) => {
    try {
      const encryptedEncryptionKey = CryptoJS.AES.encrypt(
        encryptionKey,
        userKey
      ).toString();
      resolve(encryptedEncryptionKey);
    } catch (error) {
      reject(new Error("Encryption failed"));
    }
  });
}

export async function decrypt(encryptedEncryptionKey, userKey) {
  return new Promise((resolve, reject) => {
    try {
      const decryptedBytes = CryptoJS.AES.decrypt(
        encryptedEncryptionKey,
        userKey
      );
      const decryptedEncryptionKey = decryptedBytes.toString(CryptoJS.enc.Utf8);
      resolve(decryptedEncryptionKey);
    } catch (error) {
      reject(new Error("Decryption failed"));
    }
  });
}
