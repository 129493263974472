import config from "../config";
import store from "../app/store";
import { validJSON } from "../common/utils";
import { showToast } from "../components/Toast/toast.slice";

const updatePassword = async (
  email: any,
  newPassword: string
): Promise<any> => {
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("authorization", `${localStorage.userAccessToken}`);

  const requestOptions = {
    method: "PATCH",
    headers: myHeaders,
    body: JSON.stringify({ email, newPassword }),
  };

  return new Promise((resolve, reject) => {
    fetch(
      `${config[config.env].apiEnspoints.accounts}/users/updatepassword`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        if (validJSON(result)) {
          const updateResponse = JSON.parse(result);
          if (updateResponse.statusCode === 200 && updateResponse.success) {
            resolve(updateResponse);
          } else {
            throw updateResponse.error;
          }
        } else {
          const errorResponse = {
            error: "Error",
            message: "Invalid Response JSON Format",
            code: "Invalid JSON",
          };
          throw errorResponse;
        }
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
        reject(error);
      });
  });
};

export default updatePassword;
