import React, { useEffect, useState } from "react";
import employeelist from "api/employeeApi";
import store from "app/store";
import UploadCSV from "components/UploadCSV";
import Pagination, { IPagination } from "components/Pagination/index";
import { showToast } from "components/Toast/toast.slice";
import EmployeeTable from "components/EmployeeTable";
import EmployeeExcelData from "components/ExportToExcel";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import CONSTANTS from "common/constants";
import { parseJwt } from "utils";
import Navigator from "../../components/Navigator";
import { EmployeeManagementWrapper } from "./styled";
import { EmployeeManagementMenu } from "./EmployeeManagementMenu";

const EmployeeManagement = () => {
  if (!localStorage.userAccessToken) return <Redirect to="/" />;
  const location = useLocation();
  const [employee, setEmployee] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState<number>(
    location?.state?.state.backToPage ? location?.state?.state.backToPage : 1
  );
  const history = useHistory();
  const [totalCount, setTotalCount] = useState();
  const [utilizationSort, setUtilizationSort] = useState("");
  const [roleFilter, setRoleFilter] = useState(CONSTANTS.ALL);
  const [searchFilter, setSearchFilter] = useState("");
  const [handleRoleFilterPage, setHandleRoleFilterPage] = useState(
    CONSTANTS.ALL
  );
  const [
    handleWorkCategorizationFilterPage,
    setHandleWorkCategorizationFilterPage,
  ] = useState(CONSTANTS.ALL);
  const [workCategorizationFilter, setWorkCategorizationFilter] = useState(
    CONSTANTS.ALL
  );
  const [userRoles, setUserRoles] = useState<any>();
  const userToken = localStorage.userAccessToken;
  const tokenPayload = parseJwt(userToken).parsedJWT;
  const { userRole } = tokenPayload;
  useEffect(() => {
    setUserRoles(userRole);
  });
  const paginationSetLimit = 5;
  const defaultPagination: IPagination = {
    pageNumber: 1,
    pageSize: 6,
    totalCount: 6,
    totalPages: 5,
    isFirst: true,
    isLast: false,
    setPageNumber,
    paginationSetLimit,
  };
  const [pagination, setPagination] = useState<IPagination>(defaultPagination);
  const employeeListRequest = () => {
    employeelist(
      pageNumber,
      defaultPagination.pageSize,
      utilizationSort,
      roleFilter,
      workCategorizationFilter,
      searchFilter
    )
      .then((employeeResponse) => {
        setEmployee(employeeResponse.data.employee);
        setPagination({ ...employeeResponse.data._pagination, setPageNumber });
        setTotalCount(employeeResponse.data._pagination.totalCount);
        setLoading(true);
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };

  const handleUploadClick = () => {
    history.push("/employee-upload");
  };

  const page = {
    defaultPagination,
    pagination,
    pageNumber,
    totalCount,
  };

  const fetchUtilizationSort = (utilizationSortBy: string) => {
    setUtilizationSort(utilizationSortBy);
    employeeListRequest();
  };

  const fetchRoleFilter = (roleFilterBy: string) => {
    setRoleFilter(roleFilterBy);
    if (handleRoleFilterPage !== roleFilterBy) {
      setHandleRoleFilterPage(roleFilterBy);
      setPageNumber(1);
    }
    employeeListRequest();
  };
  const fetchWorkCategorizationFilter = (
    workCategorizationFilterBy: string
  ) => {
    setWorkCategorizationFilter(workCategorizationFilterBy);
    if (handleWorkCategorizationFilterPage !== workCategorizationFilterBy) {
      setHandleWorkCategorizationFilterPage(workCategorizationFilterBy);
      setPageNumber(1);
    }
    employeeListRequest();
  };
  const fetchSearchData = (searchBy: string) => {
    setSearchFilter(searchBy);
    employeeListRequest();
  };

  useEffect(() => {
    employeeListRequest();
    fetchRoleFilter(roleFilter);
    fetchWorkCategorizationFilter(workCategorizationFilter);
    fetchUtilizationSort(utilizationSort);
  }, [pageNumber, roleFilter, workCategorizationFilter, utilizationSort]);

  return (
    <div className="d-flex flex-column">
      <Navigator />
      <div className="d-flex flex-column flex-grow-1">
        <EmployeeManagementWrapper className="container-fluid">
          <h1 className="h3 font-400 emp-mgmt-title">Employee Management</h1>
          <div className="d-lg-flex">
            <EmployeeManagementMenu />
            {loading ? (
              <div className="emp-mgt-table flex-grow-1">
                <div className="emp-heading justify-content-between align-items-center d-md-flex">
                  <h2 className="h3 font-400 mb-0">Employee List</h2>
                  <div>
                    Total no of Employees :
                    <span className="total-emp ml-2 font-600">
                      {totalCount}
                    </span>
                  </div>
                </div>
                <div>
                  <div
                    className="d-flex flex-wrap aui-accordion-tab"
                    id="measuesTabs"
                    role="tablist"
                  >
                    <button
                      className="aui-acc-tab-item aui-accordion-tab1 btn btn-text"
                      data-toggle="collapse"
                      data-target="#data"
                      aria-expanded="true"
                      role="tab"
                      type="button"
                      style={{ zIndex: "inherit" }}
                    >
                      Employee Data
                      <i className="aha-icon-arrow-down mx-2 d-inline-block d-md-none" />
                    </button>
                    {(userRoles === CONSTANTS.ROLES.ADMIN ||
                      userRoles === CONSTANTS.ROLES.SUPERADMIN) && (
                      <button
                        className="aui-acc-tab-item aui-accordion-tab2 btn btn-text"
                        data-toggle="collapse"
                        data-target="#spreadsheet"
                        aria-expanded="false"
                        role="tab"
                        type="button"
                      >
                        Download Data
                        <i className="aha-icon-arrow-down mx-2 d-inline-block d-md-none" />
                      </button>
                    )}
                    {(userRoles === CONSTANTS.ROLES.ADMIN ||
                      userRoles === CONSTANTS.ROLES.SUPERADMIN) && (
                      <button
                        className="aui-acc-tab-item aui-accordion-tab2 btn btn-text"
                        onClick={handleUploadClick}
                        type="button"
                      >
                        Upload Data
                        <i className="aha-icon-arrow-down mx-2 d-inline-block d-md-none" />
                      </button>
                    )}
                    <div
                      id="data"
                      className="collapse show row w-100 no-gutters aui-accordion-content aui-accordion-content1 pt-4"
                      data-parent="#measuesTabs"
                      role="tabpanel"
                    >
                      <EmployeeTable
                        employee={employee}
                        page={page}
                        backToTable={() => {
                          employeeListRequest();
                        }}
                        utilizationSortBy={fetchUtilizationSort}
                        roleFilterBy={fetchRoleFilter}
                        workCategorizationFilterBy={
                          fetchWorkCategorizationFilter
                        }
                        searchBy={fetchSearchData}
                      />
                      <div className="w-100">
                        {pagination && Number(pagination.totalPages) > 0 ? (
                          <Pagination
                            pageNumber={pagination.pageNumber}
                            pageSize={pagination.pageSize}
                            totalCount={pagination.totalCount}
                            totalPages={pagination.totalPages}
                            isFirst={pagination.isFirst}
                            isLast={pagination.isLast}
                            setPageNumber={pagination.setPageNumber}
                            paginationSetLimit={5}
                          />
                        ) : null}
                      </div>
                    </div>
                    <div
                      id="spreadsheet"
                      className="collapse row w-100 no-gutters aui-accordion-content aui-accordion-content2 pt-4"
                      data-parent="#measuesTabs"
                      role="tabpanel"
                    >
                      <div className="w-100">
                        <EmployeeExcelData />
                      </div>
                    </div>
                    <div
                      id="uploadsheet"
                      className="collapse row w-100 no-gutters aui-accordion-content aui-accordion-content2 pt-4"
                      data-parent="#measuesTabs"
                      role="tabpanel"
                    >
                      <div className="w-100">
                        <UploadCSV />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="aui-block-loader emp-mgt-table"
                role="alert"
                aria-live="assertive"
                aria-label="Page is Loading"
              />
            )}
          </div>
        </EmployeeManagementWrapper>
      </div>
    </div>
  );
};

export default EmployeeManagement;
