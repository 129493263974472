import addSalaryHistory from "api/employeeSalaryHistoryApi";
import { MonthPicker } from "react-date-dropdown";
import getRoleName from "api/rolesAPI";
import updateSalary from "api/updateSalaryApi";
import updateSalaryTillDate from "api/updateSalaryHistoryApi";
import store from "app/store";
import CONSTANTS from "common/constants";
import { showToast, successToast } from "components/Toast/toast.slice";
import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import getEmployeeInvoiceMonthWise from "api/getEmployeeInvoiceMonthWiseApi";
import * as XLSX from "xlsx";
import sendDownloadHistory from "api/sendDownloadHistory";
import getDownloadHistory from "api/getDownloadHistory";

import { debounce } from "lodash";

import DownloadHistoryTable from "./DownLoadHistoryTable";
import CustomSelect from "./style";

const EmployeeExcelData = () => {
  const history = useHistory();
  const [projectInvoiceList, setProjectInvoiceList] = useState<any>([]);
  const [historyData, setHistoryData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selectedStartMonth, setSelectedStartMonth] = useState<
    number | undefined
  >(undefined);
  const [selectedEndMonth, setSelectedEndMonth] = useState<number | undefined>(
    undefined
  );
  const [name, setName] = useState("");
  const [currentTime, setCurrentTime] = useState(new Date().toISOString());
  const [actionData, setActionData] = useState("");

  const handleDownload = (projectlist: any, StartMonth: any, EndMonth: any) => {
    const worksheet = XLSX.utils.json_to_sheet(projectlist);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const filename = `EmpInvoicedata.xlsx`;
    setName(filename);
    XLSX.writeFile(workbook, filename);
  };

  const err = {
    visibility: true,
    message: "Enter month details in correct format",
    title: "",
    type: "",
  };
  const errNoData = {
    visibility: true,
    message: "There is no data available for selected time period",
    title: "",
    type: "",
  };

  const getEmployeeInvoiceMonthWiselist = async () => {
    const strmonth = selectedStartMonth;
    const endmonth = selectedEndMonth;

    if (strmonth && endmonth && strmonth > endmonth) {
      store.dispatch(showToast(err));
    } else if (!selectedStartMonth || !selectedEndMonth) {
      store.dispatch(showToast(err));
    } else if (selectedStartMonth === 0 || selectedEndMonth === 0) {
      store.dispatch(showToast(err));
    } else {
      await getEmployeeInvoiceMonthWise(selectedStartMonth, selectedEndMonth)
        .then((invoiceResponse) => {
          const invoiceList = invoiceResponse.data.apiResult.employeeListObj;
          setProjectInvoiceList(invoiceList);
          if (invoiceList.length === 0) {
            store.dispatch(showToast(errNoData));
          } else {
            handleDownload(invoiceList, selectedStartMonth, selectedEndMonth);
            setLoading(true);
            setRefresh(true);
          }
        })
        .catch((error: any) => {
          store.dispatch(showToast(error.details[0].message));
        });
    }
  };

  const debouncedGetEmployeeInvoiceMonthWiselist = useCallback(
    debounce(getEmployeeInvoiceMonthWiselist, 500),
    [selectedStartMonth, selectedEndMonth]
  );

  const handleExportClick = () => {
    debouncedGetEmployeeInvoiceMonthWiselist();
  };

  const handleMonthChangeStart = (month: number) => {
    setSelectedStartMonth(month);
  };

  const handleMonthChangeEnd = (month: number) => {
    setSelectedEndMonth(month);
  };

  const setInputValues = (SMonth: any, EMonth: any) => {
    const newdate = new Date().toISOString();
    const fname = `EmpInvoicedata.xlsx`;
    setCurrentTime(newdate);
    setName(fname);
    setActionData("downloaded");
  };

  useEffect(() => {
    setInputValues(selectedStartMonth, selectedEndMonth);
    setRefresh(false);
  }, [projectInvoiceList]);

  return (
    <div className="w-100">
      <CustomSelect>
        <div className="d-flex flex-column flex-sm-row">
          <div className="d-flex col-lg-10 col-md-10 col-sm-8 justify-content-center m-0 p-0 ">
            <div className="input-group flex-nowrap d-flex flex-column flex-sm-row justify-content-end form-select form-select-lg p-2 ExportSection">
              <div className="aui-label">
                <label className="input-group-text aui-buyfor-txt">
                  Select Time frame
                </label>
              </div>
              <div className="inputs">
                <MonthPicker
                  onMonthChange={handleMonthChangeStart}
                  selectedMonth={selectedStartMonth}
                  placeholder="From"
                  className={{ select: "m-1 dropdown" }}
                />
                <MonthPicker
                  onMonthChange={handleMonthChangeEnd}
                  selectedMonth={selectedEndMonth}
                  placeholder="To"
                  className={{ select: "m-1 dropdown" }}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-4 d-flex justify-content-center mt-1">
            <CustomSelect>
              <button
                onClick={handleExportClick}
                type="button"
                className="btn btn-round btn-primary ExportButton"
              >
                Export
              </button>
            </CustomSelect>
          </div>
        </div>

        <div className="mt-4 mb-4">
          <h3>Downloaded Spreadsheet History</h3>
        </div>
        <DownloadHistoryTable refresh={refresh} />
      </CustomSelect>
    </div>
  );
};

export default EmployeeExcelData;
