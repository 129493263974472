import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import editEmployees from "api/editEmployeeApi";
import updateSalary from "api/updateSalaryApi";
import addSalaryHistory from "api/employeeSalaryHistoryApi";
import getEmployeesManager from "api/getManagerApi";
import updateSalaryTillDate from "api/updateSalaryHistoryApi";
import MultiSelectDropdown from "../../components/Multiselect";
import employeePost from "../../api/addEmployeeApi";
import { getTeams, postTeam } from "../../api/getTeams";
import { getWorkCategorizations } from "../../api/getWorkCategorizations";
import { getRoles, postRole } from "../../api/getRoles";
import { getSkills, postSkill } from "../../api/getSkills";
import { getEmployees } from "../../api/getEmployees";
import { showToast, successToast } from "../../components/Toast/toast.slice";
import store from "../../app/store";
import { getSubteams, postSubteam } from "../../api/getSubteams";
import { getDesignation, postDesignation } from "../../api/getDesignation";

interface Props {
  employee: any;
  backToProfile: any;
}

interface IToast {
  isValid: boolean;
  visibility: boolean;
  message: string;
}

const EmployeeManagementForms = ({ employee, backToProfile }: Props) => {
  const dbDate = new Date(employee.vendorCostExpiryDate);
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  const [employeeForm, setemployeeForm] = useState({
    fullName: employee.fullName,
    employeeId: employee.employeeId,
    ahaEmail: employee.ahaEmail,
    impelsysEmail: employee.impelsysEmail,
    phone: employee.phone,
    reportingManager: employee.reportingManager,
    salary: employee.salary,
    vendorCost:
      dbDate.getTime() >= currentDate.getTime() ? employee.vendorCost : null,
    vendorCostExpiryDate:
      dbDate.getTime() >= currentDate.getTime()
        ? employee.vendorCostExpiryDate
        : null,
    salaryeffectivefromDate: employee.impelsysjoinDate,
    impelsysjoinDate: employee.impelsysjoinDate,
    ahajoinDate: employee.ahajoinDate,
    employeeType: employee.employeeType,
    contractName: employee.contractName,
    isBillable: employee.isBillable,
    workCategorization: employee.workCategorization,
    isEdit: employee.isEdit,
    password: employee.password,
  });
  const [selectedTeam, setSelectedTeam] = useState<string[]>([]);
  const [selectedSubteam, setSelectedSubteam] = useState<string[]>([]);
  const [selectedRole, setSelectedRole] = useState<string[]>([]);
  const [selectedPrimary, setSelectedPrimary] = useState<string[]>([]);
  const [selectedSecondary, setSelectedSecondary] = useState<string[]>([]);
  const [selectedDesignation, setSelectedDesignation] = useState<string[]>([]);
  const [newRole, setNewRole] = useState("");
  const [newSkill, setNewSkill] = useState("");
  const [state, setState] = useState(false);
  const [newTeam, setNewTeam] = useState("");
  const [newSubteam, setNewSubteam] = useState("");
  const [newDesignation, SetnewDesignation] = useState("");
  const EmployeeFormValidation = () => {
    const nameformat = /^[A-Za-z ]+$/;
    const phoneformat = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    let errorMessage = "";
    let isValid = true;

    if (!employeeForm.fullName.match(nameformat)) {
      errorMessage = "Invalid fullName";
      isValid = false;
    }
    if (!employeeForm.phone.match(phoneformat)) {
      errorMessage = "Invalid phoneNumber";
      isValid = false;
    }
    if (employeeForm.employeeType === "Contract") {
      if (!employeeForm.contractName.match(nameformat)) {
        errorMessage = "Invalid contractorName";
        isValid = false;
      }
    }
    if (employeeForm.vendorCost && !employeeForm.vendorCostExpiryDate) {
      if (employeeForm.vendorCostExpiryDate === null) {
        errorMessage = "vendorCostExpiryDate is not allowed to be empty";
        isValid = false;
      }
    }
    if (employeeForm.isBillable === "") {
      errorMessage = "billable is not allowed to be empty";
      isValid = false;
    }
    if (!employeeForm.employeeType) {
      errorMessage = "employeeType is not allowed to be empty";
      isValid = false;
    }
    if (!employeeForm.workCategorization) {
      errorMessage = "Work categorization is not allowed to be empty";
      isValid = false;
    }

    if (employeeForm.impelsysjoinDate > employeeForm.ahajoinDate) {
      errorMessage = "Invalid, provide valid date for Heart Bangalore";
      isValid = false;
    }
    const result = {
      isValid,
      visibility: !isValid,
      message: errorMessage,
    };
    if (!isValid) store.dispatch(showToast(result));
    return result;
  };

  const success = (eId: any) => {
    const successMessage = employee.isEdit
      ? `Employee Id ${eId} details are successfully updated`
      : `Employee Id ${eId} details are successfully added`;
    const isValid = false;
    const result = {
      isValid,
      visibility: !isValid,
      message: successMessage,
    };
    if (!isValid) store.dispatch(successToast(result));
    return result;
  };
  const successPost = (eName: any) => {
    const successMessage = `${eName} is successfully added`;
    const isValid = false;
    const result = {
      isValid,
      visibility: !isValid,
      message: successMessage,
    };
    if (!isValid) store.dispatch(successToast(result));
    return result;
  };
  const [teamName, setTeamName] = useState<any>([]);
  const [subteamName, setSubteamName] = useState<any>([]);

  const [designationName, setDesignationName] = useState<any>([]);

  const teamRequest = () => {
    getTeams()
      .then((Response) => {
        setTeamName(Response.data.teams);
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };
  const subteamRequest = () => {
    getSubteams()
      .then((Response) => {
        setSubteamName(Response.data.subteams);
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };
  const [workCategorizationName, setWorkCategorizationName] = useState<any>([]);
  const workCategorizationRequest = () => {
    getWorkCategorizations()
      .then((Response) => {
        setWorkCategorizationName(Response.data.workCategorizations);
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };
  const designRequest = () => {
    getDesignation()
      .then((Response) => {
        setDesignationName(Response.data.designation);
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };
  const [skillName, setSkillName] = useState<any>([]);
  const skillRequest = () => {
    getSkills()
      .then((Response) => {
        setSkillName(Response.data.skills);
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };
  const [roleName, setRoleName] = useState<any>([]);
  const roleRequest = () => {
    getRoles()
      .then((Response) => {
        const filteredRoles = Response.data.roles.filter(
          (role: string) => role !== "ADMIN" && role !== "SUPERADMIN"
        );
        setRoleName(filteredRoles);
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };
  const [manager, setManager] = useState<any>([]);
  const employeeRequest = () => {
    getEmployeesManager()
      .then((Response) => {
        const employee = Response?.data?.employees;
        if (employee && employee.length > 0) {
          setManager(employee);
        } else {
          setManager([]);
        }
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };

  useEffect(() => {
    teamRequest();
    subteamRequest();
    designRequest();
    roleRequest();
    skillRequest();
    employeeRequest();
    workCategorizationRequest();
  }, []);
  const onChangeForm = (event: any) => {
    event.persist();
    if (event.target.name === "employeeType") {
      if (event.target.value === "Contract") {
        setState(true);
      } else {
        setState(false);
      }
      if (event.target.value === "Permanent") {
        setState(false);
        employeeForm.contractName = "";
      } else {
        setState(true);
      }
    }
    setemployeeForm({
      ...employeeForm,
      [event.target.name]: event.target.value,
    });
  };
  if (employee.isEdit) {
    employee.secondarySkills.forEach((element: any) => {
      if (!selectedSecondary.includes(element)) {
        selectedSecondary.push(element);
      }
    });
    employee.primarySkills.forEach((element: any) => {
      if (!selectedPrimary.includes(element)) {
        selectedPrimary.push(element);
      }
    });
    employee.role.forEach((element: any) => {
      if (!selectedRole.includes(element)) {
        selectedRole.push(element);
      }
    });
    if (employee.teamDetails && employee.teamDetails.length > 0) {
      employee.teamDetails.forEach((element: any) => {
        if (!selectedTeam.includes(element)) {
          selectedTeam.push(element);
        }
      });
    }
    if (employee.subteam && employee.subteam.length > 0) {
      employee.subteam.forEach((element: any) => {
        if (!selectedSubteam.includes(element)) {
          selectedSubteam.push(element);
        }
      });
    }
    if (employee.designationDetails && employee.designationDetails.length > 0) {
      employee.designationDetails.forEach((element: any) => {
        if (!selectedDesignation.includes(element)) {
          selectedDesignation.push(element);
        }
      });
    }
  }
  const history = useHistory();

  const updateSalaryHistoryTable = () => {
    const employeeUpdate = {
      employeeId: employeeForm.employeeId,
      salary: parseFloat(employeeForm.salary),
      salaryeffectivefromDate: employeeForm.impelsysjoinDate,
    };
    updateSalary(employeeUpdate)
      .then((patchResponse: any) => {
        addSalaryHistory(employeeUpdate)
          .then((postResponse: any) => {
            if (employeeForm.isEdit) {
              updateSalaryTillDate(employeeUpdate)
                .then((putResponse: any) => {})
                .catch((error: any) => {
                  store.dispatch(showToast(error));
                });
            }
          })
          .catch((error: any) => {
            store.dispatch(showToast(error.details[0].message));
          });
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };

  const addTeam = (event: any) => {
    event.preventDefault();
    setNewTeam("");
    const teamData = {
      name: newTeam,
    };
    postTeam(teamData)
      .then(() => {
        teamRequest();
        successPost(teamData.name);
        selectedTeam.push(teamData.name);
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };
  const addSubteam = (event: any) => {
    event.preventDefault();
    setNewSubteam("");
    const subteamData = {
      name: newSubteam,
    };
    postSubteam(subteamData)
      .then(() => {
        subteamRequest();
        successPost(subteamData.name);
        selectedSubteam.push(subteamData.name);
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };
  const addDesignation = (event: any) => {
    event.preventDefault();
    SetnewDesignation("");
    const designationData = {
      name: newDesignation,
    };
    postDesignation(designationData)
      .then(() => {
        designRequest();
        successPost(designationData.name);
        selectedDesignation.push(designationData.name);
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };
  const addEmployeeFormSubmission = (event: any) => {
    event.preventDefault();
    const grossSalary = parseFloat(employeeForm.salary);
    const grossVendorCost = parseFloat(employeeForm.vendorCost);
    if (grossVendorCost > grossSalary) {
      const errorMessage =
        "Gross Vendor Cost cannot be greater than Gross Salary";
      const toast: IToast = {
        isValid: false,
        visibility: true,
        message: errorMessage,
      };
      store.dispatch(showToast(toast));
      return;
    }
    if (EmployeeFormValidation().isValid) {
      const employeeData = {
        fullName: employeeForm.fullName,
        employeeId: employeeForm.employeeId,
        ahaEmail: employeeForm.ahaEmail,
        impelsysEmail: employeeForm.impelsysEmail,
        phone: employeeForm.phone,
        reportingManager: employeeForm.reportingManager,
        salary: parseFloat(employeeForm.salary),
        vendorCost: parseFloat(employeeForm.vendorCost),
        vendorCostExpiryDate: employeeForm.vendorCostExpiryDate,
        salaryeffectivefromDate: employeeForm.impelsysjoinDate,
        impelsysjoinDate: employeeForm.impelsysjoinDate,
        ahajoinDate: employeeForm.ahajoinDate,
        employeeType: employeeForm.employeeType,
        contractName: employeeForm.contractName,
        isBillable: employeeForm.isBillable,
        teamDetails: selectedTeam,
        subteam: selectedSubteam,
        designationDetails: selectedDesignation,
        role: selectedRole,
        primarySkills: selectedPrimary,
        secondarySkills: selectedSecondary,
        workCategorization: employeeForm.workCategorization,
      };
      employeePost(employeeData)
        .then((postResponse) => {
          success(employeeData.employeeId);
          history.push("/employee-management");
          updateSalaryHistoryTable();
        })
        .catch((error: any) => {
          store.dispatch(showToast(error.details[0].message));
        });
    }
  };
  const editEmployeeFormSubmission = (event: any) => {
    event.preventDefault();
    const grossSalary = parseFloat(employeeForm.salary);
    const grossVendorCost = parseFloat(employeeForm.vendorCost);
    if (grossVendorCost > grossSalary) {
      const errorMessage =
        "Gross Vendor Cost cannot be greater than Gross Salary";
      const toast: IToast = {
        isValid: false,
        visibility: true,
        message: errorMessage,
      };
      store.dispatch(showToast(toast));
      return;
    }
    if (EmployeeFormValidation().isValid) {
      const employeeData = {
        fullName: employeeForm.fullName,
        employeeId: employeeForm.employeeId,
        ahaEmail: employeeForm.ahaEmail,
        impelsysEmail: employeeForm.impelsysEmail,
        phone: employeeForm.phone,
        reportingManager: employeeForm.reportingManager,
        salary: parseFloat(employeeForm.salary),
        vendorCost: parseFloat(employeeForm.vendorCost),
        vendorCostExpiryDate: employeeForm.vendorCostExpiryDate,
        salaryeffectivefromDate: employeeForm.impelsysjoinDate,
        impelsysjoinDate: employeeForm.impelsysjoinDate,
        ahajoinDate: employeeForm.ahajoinDate,
        employeeType: employeeForm.employeeType,
        contractName: employeeForm.contractName,
        isBillable: employeeForm.isBillable,
        teamDetails: selectedTeam,
        subteam: selectedSubteam,
        designationDetails: selectedDesignation,
        role: selectedRole,
        primarySkills: selectedPrimary,
        secondarySkills: selectedSecondary,
        workCategorization: employeeForm.workCategorization,
        password: employeeForm.password,
      };
      editEmployees(employeeData.employeeId, employeeData)
        .then((patchResponse: any) => {
          success(employeeData.employeeId);
          backToProfile();
        })
        .catch((error: any) => {
          store.dispatch(showToast(error.details[0].message));
        });
    }
  };

  const addRole = (event: any) => {
    event.preventDefault();
    setNewRole("");
    const roleData = {
      name: newRole,
    };
    postRole(roleData)
      .then(() => {
        roleRequest();
        successPost(roleData.name);
        selectedRole.push(roleData.name);
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };

  const addPrimarySkill = (event: any) => {
    event.preventDefault();
    setNewSkill("");
    const skillData = {
      name: newSkill,
    };
    postSkill(skillData)
      .then(() => {
        skillRequest();
        successPost(skillData.name);
        selectedPrimary.push(skillData.name);
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };

  const addSecondarySkill = (event: any) => {
    event.preventDefault();
    setNewSkill("");
    const skillData = {
      name: newSkill,
    };
    postSkill(skillData)
      .then(() => {
        skillRequest();
        successPost(skillData.name);
        selectedSecondary.push(skillData.name);
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };
  useEffect(() => {
    if (employeeForm.vendorCost === "") {
      employeeForm.vendorCostExpiryDate = null;
    }
  }, [employeeForm.vendorCost]);
  return (
    <form
      autoComplete="off"
      onSubmit={
        employeeForm.isEdit
          ? editEmployeeFormSubmission
          : addEmployeeFormSubmission
      }
    >
      {employeeForm.isEdit ? (
        <div className="form-group row ">
          <label htmlFor="employeeId" className="col-sm-4 col-form-label">
            Employee ID
          </label>
          <div className="col-sm-8">
            <input
              value={employeeForm.employeeId}
              name="employeeId"
              type="number"
              className="form-control"
              id="employeeId"
            />
          </div>
        </div>
      ) : (
        <div className="form-group row required">
          <label htmlFor="employeeId" className="col-sm-4 col-form-label">
            Employee ID
          </label>
          <div className="col-sm-8">
            <input
              onChange={onChangeForm}
              value={employeeForm.employeeId}
              name="employeeId"
              type="number"
              className="form-control"
              id="employeeId"
              required
            />
          </div>
        </div>
      )}
      <div className="form-group row required">
        <label htmlFor="fullName" className="col-sm-4 col-form-label">
          Full Name
        </label>
        <div className="col-sm-8">
          <input
            onChange={onChangeForm}
            value={employeeForm.fullName}
            name="fullName"
            type="text"
            className="form-control"
            id="fullName"
            required
          />
        </div>
      </div>
      <div className="form-group row required">
        <label htmlFor="ahaEmail" className="col-sm-4 col-form-label">
          A H A Email
        </label>
        <div className="col-sm-8">
          <input
            onChange={onChangeForm}
            value={employeeForm.ahaEmail}
            type="email"
            name="ahaEmail"
            className="form-control"
            id="ahaEmail"
            required
          />
          {employeeForm.ahaEmail &&
            !employeeForm.ahaEmail?.endsWith("heart.org") && (
              <p className="validateError">Enter Valid AHA email</p>
            )}
        </div>
      </div>
      <div className="form-group row required">
        <label htmlFor="impelsysEmail" className="col-sm-4 col-form-label">
          Impelsys Email ID
        </label>
        <div className="col-sm-8">
          <input
            onChange={onChangeForm}
            value={employeeForm.impelsysEmail}
            type="email"
            name="impelsysEmail"
            className="form-control"
            id="impelsysEmail"
            required
          />
          {employeeForm.impelsysEmail &&
            !employeeForm.impelsysEmail?.endsWith("impelsys.com") && (
              <p className="validateError">Enter Valid Impelsys email</p>
            )}
        </div>
      </div>
      <div className="form-group row required">
        <label htmlFor="tel" className="col-sm-4 col-form-label">
          Phone
        </label>
        <div className="col-sm-8">
          <input
            onChange={onChangeForm}
            min={10}
            value={employeeForm.phone}
            type="number"
            name="phone"
            className="form-control"
            id="tel"
            required
          />
        </div>
      </div>
      <div className="form-group row required">
        <label htmlFor="workCategorization" className="col-sm-4 col-form-label">
          Work Categorization
        </label>
        <div className="col-sm-8">
          <select
            onChange={onChangeForm}
            value={employeeForm.workCategorization}
            name="workCategorization"
            className="form-control"
            id="workCategorization"
          >
            <option hidden>Select</option>
            <option>PROJECT</option>
            <option>LCM</option>
            <option>SUPPORT</option>
          </select>
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="teamDetails" className="col-sm-4 col-form-label">
          Team
        </label>
        <div className="col-sm-8">
          <MultiSelectDropdown
            id="teamDetails"
            add="Add new team"
            post={newTeam}
            items={teamName}
            check={addTeam}
            selectedValue={selectedTeam}
            change={(e: any) => setNewTeam(e.target.value)}
            callParentOnSelect={(value: any) => {
              setSelectedTeam(value);
            }}
          />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="subteam" className="col-sm-4 col-form-label">
          Sub-team
        </label>
        <div className="col-sm-8">
          <MultiSelectDropdown
            id="subteam"
            add="Add new subteam"
            post={newSubteam}
            items={subteamName}
            check={addSubteam}
            selectedValue={selectedSubteam}
            change={(e: any) => setNewSubteam(e.target.value)}
            callParentOnSelect={(value: any) => {
              setSelectedSubteam(value);
            }}
          />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="designationDetails" className="col-sm-4 col-form-label">
          Designation
        </label>
        <div className="col-sm-8">
          <MultiSelectDropdown
            id="designationDetails"
            add="Add new Designation"
            post={newDesignation}
            items={designationName}
            check={addDesignation}
            selectedValue={selectedDesignation}
            change={(e: any) => SetnewDesignation(e.target.value)}
            callParentOnSelect={(value: any) => {
              setSelectedDesignation(value);
            }}
          />
        </div>
      </div>
      <div className="form-group row required">
        <label htmlFor="impelsysjoinDate" className="col-sm-4 col-form-label">
          Joining Date Impelsys
        </label>
        <div className="col-sm-8">
          <input
            onChange={onChangeForm}
            value={employeeForm.impelsysjoinDate.slice(0, 10)}
            type="date"
            name="impelsysjoinDate"
            className="form-control"
            id="impelsysjoinDate"
            required
          />
        </div>
      </div>
      <div className="form-group row required">
        <label htmlFor="ahajoinDate" className="col-sm-4 col-form-label">
          Joining Date Heart Bangalore
        </label>
        <div className="col-sm-8">
          <input
            onChange={onChangeForm}
            value={employeeForm.ahajoinDate.slice(0, 10)}
            type="date"
            name="ahajoinDate"
            className="form-control"
            id="ahajoinDate"
            required
          />
        </div>
      </div>
      <div className="form-group row required">
        <label htmlFor="role" className="col-sm-4 col-form-label">
          Project Role
        </label>
        <div className="col-sm-8">
          <MultiSelectDropdown
            id="role"
            add="Add new role"
            post={newRole}
            items={roleName}
            check={addRole}
            selectedValue={selectedRole}
            change={(e: any) => setNewRole(e.target.value)}
            callParentOnSelect={(value: any) => {
              setSelectedRole(value);
            }}
          />
        </div>
      </div>
      <div className="form-group row required">
        <label htmlFor="primarySkills" className="col-sm-4 col-form-label">
          Primary Skills
        </label>
        <div className="col-sm-8">
          <MultiSelectDropdown
            id="primarySkills"
            add="Add new skill"
            post={newSkill}
            check={addPrimarySkill}
            items={skillName}
            selectedValue={selectedPrimary}
            change={(e: any) => setNewSkill(e.target.value)}
            callParentOnSelect={(value: any) => {
              setSelectedPrimary(value);
            }}
          />
        </div>
      </div>
      <div className="form-group row ">
        <label htmlFor="secondarySkills" className="col-sm-4 col-form-label">
          Secondary Skills
        </label>
        <div className="col-sm-8">
          <MultiSelectDropdown
            id="secondarySkills"
            add="Add new skill"
            post={newSkill}
            check={addSecondarySkill}
            items={skillName}
            selectedValue={selectedSecondary}
            change={(e: any) => setNewSkill(e.target.value)}
            callParentOnSelect={(value: any) => {
              setSelectedSecondary(value);
            }}
          />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="reportingManager" className="col-sm-4 col-form-label">
          Reporting Manager
        </label>
        <div className="col-sm-8">
          <select
            onChange={onChangeForm}
            value={employeeForm.reportingManager}
            name="reportingManager"
            className="form-control"
            id="reportingManager"
            defaultValue=""
          >
            <option value=""> Select Manager </option>
            {manager.map((obj: any, i: number) => {
              return <option value={obj.full_name}>{obj.full_name}</option>;
            })}
          </select>
        </div>
      </div>
      <div className="form-group row required">
        <label htmlFor="employeeType" className="col-sm-4 col-form-label">
          Employee Type
        </label>
        <div className="col-sm-8">
          <select
            onChange={onChangeForm}
            value={employeeForm.employeeType}
            name="employeeType"
            className="form-control"
            id="employeeType"
          >
            <option hidden>Select</option>
            <option>Permanent</option>
            <option>Contract</option>
          </select>
        </div>
      </div>
      <div
        className={
          state || employeeForm.contractName !== "" ? "d-block" : "d-none"
        }
      >
        <div className="form-group row required">
          <label htmlFor="contractName" className="col-sm-4 col-form-label">
            Contractor Name
          </label>
          <div className="col-sm-8">
            <input
              onChange={onChangeForm}
              value={employeeForm.contractName}
              name="contractName"
              type="text"
              className="form-control"
              id="contractName"
            />
          </div>
        </div>
      </div>
      <div className="form-group row required">
        <label htmlFor="salary" className="col-sm-4 col-form-label">
          Annual Gross Salary (₹)
        </label>
        <div className="col-sm-8">
          <input
            onChange={onChangeForm}
            value={employeeForm.salary}
            name="salary"
            type="number"
            className="form-control"
            id="salary"
            required
            disabled={employeeForm.isEdit}
          />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="vendorCost" className="col-sm-4 col-form-label">
          Annual Vendor Cost (₹)
        </label>
        <div className="col-sm-8">
          <input
            onChange={onChangeForm}
            value={employeeForm.vendorCost}
            type="number"
            name="vendorCost"
            className="form-control"
            id="vendorCost"
          />
        </div>
      </div>
      {employeeForm.vendorCost ? (
        <div className="form-group row required">
          <label
            htmlFor="vendorCostExpiryDate"
            className="col-sm-4 col-form-label"
          >
            Vendor Cost Expiry Date
          </label>
          <div className="col-sm-8">
            <input
              onChange={onChangeForm}
              min={new Date().toISOString().split("T")[0]}
              value={employeeForm.vendorCostExpiryDate}
              type="date"
              name="vendorCostExpiryDate"
              className="form-control"
              id="vendorCostExpiryDate"
            />
          </div>
        </div>
      ) : null}
      <div className="form-group row required">
        <label htmlFor="isBillable" className="col-sm-4 col-form-label">
          Billable
        </label>
        <div className="col-sm-8">
          <select
            onChange={onChangeForm}
            value={employeeForm.isBillable}
            name="isBillable"
            className="form-control"
            id="isBillable"
          >
            <option hidden>Select</option>
            <option label="True">true</option>
            <option label="False">false</option>
          </select>
        </div>
      </div>
      <div className="line" />
      <div className="d-flex justify-content-end py-5">
        <button
          type="button"
          aria-label="cancel add new employee form"
          onClick={() => window.location.reload()}
          className="btn btn-round btn-secondary mr-4 btnwidth"
        >
          Cancel
        </button>
        <button
          type="submit"
          aria-label="submit add new employee form"
          className="btn btn-round btn-primary btnwidth"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default EmployeeManagementForms;
