import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { parseJwt } from "../utils";

interface ProtectedRouteProps extends RouteProps {
  component: React.ComponentType<any>;
  requiredRole: string[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  requiredRole,
  ...rest
}) => {
  const userToken = localStorage.userAccessToken;
  const tokenPayload = parseJwt(userToken).parsedJWT;
  const { userRole } = tokenPayload;

  return (
    <Route
      {...rest}
      render={(props) =>
        requiredRole.indexOf(userRole) !== -1 ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default ProtectedRoute;
