import styled from "styled-components";

const HeaderWrapper = styled.header`
  .impelsys-logo {
    margin-left: 10px;
    border-left: 2px solid #e3e3e3;
    padding-left: 30px;
  }

  .logostyle {
    width: 200px;
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
    display: none;
  }

  .dropdown-menu.show {
    display: block;
  }

  .dropdown-toggle:hover + .dropdown-menu {
    display: block;
  }

  .dropdown-menu p {
    margin: 0;
    padding: 0.5rem 1rem;
  }
  .dropdown-item-text {
    color: #c10e21;
  }
`;

export default HeaderWrapper;
