import React, { useEffect, useState } from "react";
import { showToast, successToast } from "components/Toast/toast.slice";
import { Redirect, useHistory } from "react-router-dom";
import projectView from "api/getProjectDetailsApi";
import store from "app/store";
import getProjectMemberList from "api/getProjectMembersAPI";
import getOrgClientSettings from "api/getOrgClientSettingsAPI";
import getInvioceDefaultData from "api/getInvoiceDefaultDataApi";
import { getEmployees } from "api/getEmployees";
import setInvoiceData from "api/setInvoiceDefaultDataApi";
import CONSTANTS from "common/constants";
import sendInvoiceData from "api/sendInvoiceDataAPI";
import getEmployeeSalary from "api/getEmployeeSalaryAPI";
import updateIndividualInvoice from "api/updateIndividualInvoiceApi";
import getIndividualInvoice from "api/getIndividualInvoiceApi";
import getMonthlyProjectInvoice from "api/getMonthlyProjectInvoiceApi";
import regenerateInvoice from "api/regenerateInvoiceAPI";
import getInvioceList from "api/getInvoiceListAPI";
import * as XLSX from "xlsx";
import Navigator from "../../components/Navigator";
import { ProjectManagementWrapper } from "../ProjectManagement/styled";
import { ShopCPRMenu } from "./ShopCPRMenu";

const moment = require("moment-business-days");

const EDCInvoice = (props: any) => {
  if (!localStorage.userAccessToken) return <Redirect to="/" />;
  const selectedMonth =
    `${props.match.params.month}`.length === 1
      ? `${0}${props.match.params.month}`
      : `${props.match.params.month}`;
  const selectedYear = props.match.params.year;
  const { type } = props.match.params;
  const [loading, setLoading] = useState(false);
  const [hourlyRate, setHourlyRate] = useState("");
  const [workingHours, setWorkingHours] = useState("");
  const [hourly, setHourly] = useState("");
  const [working, setWorking] = useState("");
  const [individualData, setIndividualData] = useState<any>([]);
  const [checkData, setCheckData] = useState(false);
  const [tempData, setTempData] = useState<any>([]);
  const [projectInvoiceData, setProjectInvoiceData] = useState<any>();
  const [teamMembers, setTeamMembers] = useState<any>([]);
  const [clientConfiguration, setClientConfiguration] = useState<any>([]);
  const [projectProfile, setProjectProfile] = useState<any>([]);
  const [totalEmployeeCost, setTotalEmployeeCost] = useState(0);
  const { projectId } = props.match.params;
  const [tenantConfig, setTenantConfig] = useState<any>([]);
  const [clientCurrency, setClientCurrency] = useState("");
  const [oldClientCurrency, setOldClientCurrency] = useState("");
  const [fxValue, setFxValue] = useState("");
  const [oldFXValue, setOldFXValue] = useState("");
  const [seatCost, setSeatCost] = useState("");
  const [oldSeatCost, setOldSeatCost] = useState("");
  const [startDay, setStartDay] = useState("01");
  const [endDay, setEndDay] = useState("31");
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [styleHandle, setStyleHandle] = useState(false);
  const [employeeName, setEmployeeName] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [indexNo, setIndexNo] = useState("");
  const [isError, setIsError] = useState(false);
  const date = new Date();
  const AdditionalCost = "Additional Cost";
  const currentMonth = `${selectedYear}-${selectedMonth}`;
  const [month, setMonth] = useState(currentMonth);
  const [miscellaneousCost, setMiscellaneousCost] = useState<number>(0.0);
  const [travelCost, setTravelCost] = useState<number>(0.0);
  const [additionalCost, setAdditionalCost] = useState<number>(0.0);
  const [formState, setFormState] = useState(true);
  const [addEmployeeName, setAddEmployeeName] = useState("");
  const [employee, setEmployee] = useState([]);
  const [addUtilization, setAddUtilization] = useState<any>("");
  const [addHourlyRate, setAddHourlyRate] = useState<any>("");
  const [monthlyVendorCost, setMonthlyVendorCost] = useState("");
  const [MonthlySalary, setMonthlySalary] = useState("");
  const [edit, setEdit] = useState(false);
  const [generate, setGenerate] = useState(false);
  const [regenerate, setRegenerate] = useState(true);
  const [id, setId] = useState("");
  const [empId, setEmpId] = useState("");
  const [empName, setEmpName] = useState("");
  const firstDay = new Date(
    date.getFullYear(),
    parseInt(month.slice(5, 7), 10) - 1,
    1
  ).getDate();
  const lastDay = new Date(
    date.getFullYear(),
    parseInt(month.slice(5, 7), 10),
    0
  ).getDate();
  const [workingDays, setWorkingDays] = useState("");
  const [oldWorkingDays, setOldWorkingDays] = useState("");
  const updateDates = () => {
    setStartDay(
      String(firstDay).length === 1 ? 0 + String(firstDay) : String(firstDay)
    );
    setEndDay(
      String(lastDay).length === 1 ? 0 + String(lastDay) : String(lastDay)
    );
    setEndDate(
      `${parseInt(month.slice(0, 4), 10)}-${month.slice(5, 7)}-${endDay}`
    );
    setStartDate(
      `${parseInt(month.slice(0, 4), 10)}-${month.slice(5, 7)}-${startDay}`
    );
    setWorkingDays(moment(endDate, "YYYY-MM-DD").businessDaysIntoMonth());
    if (
      month !== currentMonth &&
      individualData[0].projectWorkingDays !== workingDays
    ) {
      clientConfiguration.clientWorkingDays = workingDays;
      updateUtilizationAndInvoiceSettings();
    }
  };
  const projectProfileRequest = () => {
    projectView(projectId)
      .then((projectResponse) => {
        setProjectProfile(projectResponse.data.projectDetails);
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };

  const TeamMembersRequest = () => {
    getProjectMemberList(projectId)
      .then((teamResponse) => {
        setTeamMembers(teamResponse.data.teamMembersDetails);
        setClientConfiguration(teamResponse.data.clientConfiguration);
        clientConfiguration.clientWorkingDays = workingDays;
        setLoading(true);
      })
      .catch((error: any) => {
        error?.details?.length > 0 &&
          store.dispatch(showToast(error.details[0].message));
      });
  };
  const getInvoiceDefaultValues = () => {
    if (type === "new") {
      getInvioceDefaultData(projectId, month.slice(5, 7), month.slice(0, 4))
        .then((invoiceResponse) => {
          setTotalEmployeeCost(
            invoiceResponse.data.apiResult.totalEmployeeListCost
          );
          setIndividualData(invoiceResponse.data.apiResult.employeeListObj);
          setTenantConfig(invoiceResponse.data.tenant);
        })
        .catch((error: any) => {
          store.dispatch(showToast(error.details[0].message));
        });
    } else {
      getIndividualInvoice(projectId, month.slice(5, 7), month.slice(0, 4))
        .then((invoiceResponse) => {
          setTotalEmployeeCost(
            invoiceResponse.data.apiResult.totalEmployeeListCost
          );
          setIndividualData(invoiceResponse.data.apiResult.employeeListObj);
        })
        .catch((error: any) => {
          store.dispatch(showToast(error.details[0].message));
        });
      getMonthlyProjectInvoice(projectId, month.slice(5, 7), month.slice(0, 4))
        .then((invoiceResponse) => {
          setProjectInvoiceData(invoiceResponse.data.record[0]);
          setMiscellaneousCost(parseFloat(invoiceResponse.data.decryptmiscost));
          setTravelCost(parseFloat(invoiceResponse.data.decrypttravelcost));
          setAdditionalCost(parseFloat(invoiceResponse.data.decryptaddcost));
          const orgSetting = invoiceResponse.data.record[0].settings;
          setWorkingDays(orgSetting.workingDays);
          setClientCurrency(orgSetting.clientCurrency);
          setFxValue(orgSetting.fxValue);
          setSeatCost(orgSetting.seatCost);
        })
        .catch((error: any) => {
          store.dispatch(showToast(error.details[0].message));
        });
    }
  };

  useEffect(() => {
    if (checkData) {
      const newData =
        individualData &&
        individualData.map((val: any) => {
          return {
            ...val,
            projectName: projectProfile?.projectName,
          };
        });

      setIndividualData(newData);
      setCheckData(false);
    }
  }, [checkData]);

  const generateIndividualInvoices = () => {
    for (let i = 0; i < individualData.length; i += 1) {
      updateIndividualInvoice(projectId, month.slice(5, 7), month.slice(0, 4), {
        ...individualData[i],
        startDate,
        endDate,
      });
    }
  };
  const RegenerateIndividualInvoices = () => {
    for (let i = 0; i < tempData.length; i += 1) {
      updateIndividualInvoice(projectId, month.slice(5, 7), month.slice(0, 4), {
        ...tempData[i],
        startDate,
        endDate,
      });
    }
  };

  const getClientConfiguration = () => {
    getOrgClientSettings().then((resp) => {
      const orgSetting = resp.data.result.settings;
      setClientCurrency(orgSetting.clientCurrency);
      setFxValue(orgSetting.fxValue);
      setSeatCost(orgSetting.seatCost);
      setOldClientCurrency(orgSetting.clientCurrency);
      setOldFXValue(orgSetting.fxValue);
      setOldWorkingDays(orgSetting.workingDays);
      setOldSeatCost(orgSetting.seatCost);
    });
  };

  const getInvoiceClientConfiguration = () => {
    if (type === "new") {
      getOrgClientSettings().then((resp) => {
        const orgSetting = resp.data.result.settings;
        orgSetting.workingDays = workingDays;
        setClientCurrency(orgSetting.clientCurrency);
        setFxValue(orgSetting.fxValue);
        setSeatCost(orgSetting.seatCost);
      });
    } else {
      getMonthlyProjectInvoice(projectId, month.slice(5, 7), month.slice(0, 4))
        .then((invoiceResponse) => {
          const orgSetting = invoiceResponse.data.record[0].settings;
          setWorkingDays(orgSetting.workingDays);
          setClientCurrency(orgSetting.clientCurrency);
          setFxValue(orgSetting.fxValue);
          setSeatCost(orgSetting.seatCost);
          setTenantConfig({ settings: orgSetting });
        })
        .catch((error: any) => {
          store.dispatch(showToast(error.details[0].message));
        });
    }
  };

  const calendarHandler = (event: any) => {
    setMonth(event.target.value);
  };
  const history = useHistory();
  const submitInvoiceData = () => {
    const invoiceData = {
      month: month.slice(5, 7),
      year: month.slice(0, 4),
      projectId,
      orgId: projectProfile.orgId,
      totalEmployeeCost,
      settings: {
        workingDays,
        clientCurrency,
        fxValue,
        seatCost,
        workingHours: tenantConfig.settings.workingHours,
        currency: tenantConfig.settings.currency,
        adminCharge: tenantConfig.settings.adminCharge,
        managementCharge: tenantConfig.settings.managementCharge,
      },
      miscellaneousCost,
      travelCost,
      additionalCost,
      otherCost: 0,
    };
    generateIndividualInvoices();
    setInvoiceData(invoiceData)
      .then((invoiceResponse: any) => {
        history.push(`/invoice/${projectId}`);
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };

  const remainingEmployees = employee.filter((list1: any) =>
    individualData.every((list2: any) => list2.employeeId !== list1.employee_id)
  );
  const employeeRequest = () => {
    getEmployees().then((Response) => {
      setEmployee(Response.data.employees);
    });
  };
  const validateInvoiceGeneration = () => {
    getInvioceList(projectId).then((resp) => {
      let generatedMonth = true;
      for (let i = 0; i < resp.data.invoiceListObj.length; i += 1) {
        if (
          `${
            CONSTANTS.MONTHS[parseInt(month.slice(5, 7), 10) - 1]
          } ${month.slice(0, 4)}` ===
          `${
            CONSTANTS.MONTHS[parseInt(resp.data.invoiceListObj[i].month, 10)]
          } ${resp.data.invoiceListObj[i].year}`
        ) {
          generatedMonth = false;
        }
      }
      if (generatedMonth) {
        submitInvoiceData();
      } else {
        const result: any = {};
        result.message = `Invalid input, Invoice already exists for ${
          CONSTANTS.MONTHS[parseInt(month.slice(5, 7), 10) - 1]
        } ${month.slice(0, 4)}!`;
        store.dispatch(showToast(result));
      }
    });
  };
  const updateUtilizationAndInvoiceSettings = () => {
    if (hourlyRate) {
      individualData[indexNo].hourlyRate = hourlyRate;
    }
    setIndividualData(individualData);

    setCheckData(true);
    if (workingHours) {
      individualData[indexNo].workingHours = workingHours;
    }
    setIndividualData(individualData);

    if (individualData[0]?.projectWorkingDays !== workingDays) {
      for (let i = 0; i < individualData.length; i += 1) {
        individualData[i].projectWorkingDays = workingDays;
      }
      clientConfiguration.clientWorkingDays = workingDays;
    }
    clientConfiguration.clientWorkingDays = workingDays;
    const invoiceData = {
      orgId: projectProfile.orgId,
      projectId,
      individualData,
      tenantConfig,
      invoiceClient: {
        settings: {
          workingDays,
          clientCurrency,
          fxValue,
          seatCost,
        },
      },
    };
    setHourlyRate("");
    sendInvoiceData(invoiceData)
      .then((invoiceResponse: any) => {
        setTotalEmployeeCost(
          invoiceResponse.data.apiResult.totalEmployeeListCost
        );
        setIndividualData(invoiceResponse.data.apiResult.employeeListObj);

        setCheckData(true);

        setOldClientCurrency(
          invoiceResponse.data.client.settings.clientCurrency
        );
        setOldWorkingDays(workingDays);
        setOldFXValue(invoiceResponse.data.client.settings.fxValue);
        setOldSeatCost(invoiceResponse.data.client.settings.seatCost);
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };
  const updateUtilizationAndInvoiceSettingsRegenerate = (tempData: any) => {
    if (tempData[0]?.projectWorkingDays !== workingDays) {
      for (let i = 0; i < tempData.length; i += 1) {
        tempData[i].projectWorkingDays = workingDays;
      }
      clientConfiguration.clientWorkingDays = workingDays;
    }
    clientConfiguration.clientWorkingDays = workingDays;
    const invoiceData = {
      orgId: projectProfile.orgId,
      projectId,
      individualData: tempData,
      tenantConfig,
      invoiceClient: {
        settings: {
          workingDays,
          clientCurrency,
          fxValue,
          seatCost,
        },
      },
    };
    setHourlyRate("");
    setWorkingHours("");
    setRegenerate(false);

    sendInvoiceData(invoiceData)
      .then((invoiceResponse: any) => {
        setTempData(invoiceResponse.data.apiResult.employeeListObj);
        setOldClientCurrency(
          invoiceResponse.data.client.settings.clientCurrency
        );
        setOldWorkingDays(workingDays);
        setOldFXValue(invoiceResponse.data.client.settings.fxValue);
        setOldSeatCost(invoiceResponse.data.client.settings.seatCost);
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };

  const onReset = () => {
    setEmployeeName("");
    setEdit(false);
    setFormState(true);
    setAddEmployeeName("");
    setEmployeeId("");
    setMonthlySalary("");
    setAddUtilization("");
    setAddHourlyRate("");
    setMonthlyVendorCost("");
  };
  const addNewEmployee = () => {
    let newType;
    type !== "new" ? (newType = "edit") : (newType = "view");

    if (monthlyVendorCost) {
      const today = new Date();
      today.setDate(today.getDate() + 31);
      const currentDate = today.toISOString().substr(0, 10);
      const employeeData = {
        employeeId,
        employeeName,
        salary: MonthlySalary,
        orgId: projectProfile.orgId,
        projectId,
        vendorCost: monthlyVendorCost,
        vendorCostExpiryDate: currentDate,
        grossSalary:
          parseInt(MonthlySalary, 10) + parseInt(monthlyVendorCost, 10),
        hourlyRate: addHourlyRate,
        utilization: 0,
        workingHours,
        newType,
      };
      individualData[individualData.length] = employeeData;
      setIndividualData(individualData);
      setCheckData(true);
      tempData[tempData.length] = employeeData;
      setTempData(tempData);
      setLoading(true);
      updateUtilizationAndInvoiceSettings();
      updateUtilizationAndInvoiceSettingsRegenerate(tempData);
    } else {
      const employeeData = {
        employeeId,
        employeeName,
        orgId: projectProfile.orgId,
        projectId,
        salary: MonthlySalary,
        grossSalary: MonthlySalary,
        vendorCost: null,
        vendorCostExpiryDate: null,
        utilization: 0,
        hourlyRate: addHourlyRate,
        workingHours: Number(workingDays) * 9,
        newType,
      };
      individualData[individualData.length] = employeeData;

      setIndividualData(individualData);
      setCheckData(true);
      tempData[tempData.length] = employeeData;
      setTempData(tempData);
      updateUtilizationAndInvoiceSettings();
      updateUtilizationAndInvoiceSettingsRegenerate(tempData);
      setLoading(true);
      onReset();
    }
    setGenerate(false);
    setRegenerate(false);
  };

  const hourlyRateValidation = () => {
    if (edit === true) {
      if (parseInt(addHourlyRate, 10) < 0) {
        store.dispatch(
          showToast({ message: "Hourly rate cannot be Zero or Negative" })
        );
      }
    }
  };
  const monthlyVendorCostValidation = () => {
    if (edit === true) {
      if (parseInt(monthlyVendorCost, 10) <= 0) {
        store.dispatch(
          showToast({
            message: "monthlyVendorCost cannot be less than zero or negative",
          })
        );
      } else if (parseInt(monthlyVendorCost, 10) > 999999) {
        store.dispatch(
          showToast({
            message: "monthlyVendorCost cannot be greater than 999999",
          })
        );
      }
    }
  };
  const monthlySalaryValidation = () => {
    if (edit === true) {
      if (parseInt(MonthlySalary, 10) <= 0) {
        store.dispatch(
          showToast({
            message: "monthlySalary cannot be less than zero or negative",
          })
        );
      } else if (parseInt(MonthlySalary, 10) > 9999999) {
        store.dispatch(
          showToast({
            message: "monthlySalary cannot be greater than 9999999",
          })
        );
      }
    }
  };

  useEffect(() => {
    hourlyRateValidation();
    getInvoiceDefaultValues();
    monthlySalaryValidation();
    monthlyVendorCostValidation();
  }, [addUtilization, MonthlySalary, monthlyVendorCost]);

  useEffect(() => {
    updateDates();
  }, [month, endDate, endDay, lastDay, individualData]);

  useEffect(() => {
    projectProfileRequest();
    TeamMembersRequest();
    getInvoiceDefaultValues();
    getClientConfiguration();
    getInvoiceClientConfiguration();
    employeeRequest();
    if (CONSTANTS.WINDOW.AUI.Search) {
      CONSTANTS.WINDOW.AUI.Search.init();
    }
  }, []);
  const updateHourlyRate = (event: any) => {
    event.preventDefault();
    individualData[indexNo].hourlyRate = hourlyRate;
    setIndividualData(individualData);
    const newData = [individualData[indexNo]];
    setTempData(newData);
    setTempData(individualData[indexNo]);
    if (type === "new") {
      updateUtilizationAndInvoiceSettings();
    } else {
      updateUtilizationAndInvoiceSettingsRegenerate(newData);
    }
  };
  const updateWorkingHours = (event: any) => {
    event.preventDefault();
    individualData[indexNo].workingHours = workingHours;
    setIndividualData(individualData);
    const newData = [individualData[indexNo]];
    setTempData(newData);
    setRegenerate(false);
    if (type === "new") {
      updateUtilizationAndInvoiceSettings();
    } else {
      updateUtilizationAndInvoiceSettingsRegenerate(newData);
    }
  };
  useEffect(() => {
    if (edit === true) {
      getEmployeeSalary(employeeId)
        .then((Response) => {
          setMonthlySalary(Response.data.MonthlySalary);
        })
        .catch((error: any) => {
          store.dispatch(showToast(error.details[0].message));
        });
    }
  }, [employeeId]);
  const deleteTheEmployeeFromInvoice = (event: any) => {
    event.preventDefault();
    individualData.splice(indexNo, 1);
    setIndividualData(individualData);
    setCheckData(true);
    updateUtilizationAndInvoiceSettings();
    if (individualData.length < 1) {
      setGenerate(true);
      store.dispatch(
        showToast({
          message:
            "Invalid invoice! Please add at least one employee to generate the invoice.",
        })
      );
    }
  };
  const regenerateInvoiceData = () => {
    const invoiceData = {
      month: month.slice(5, 7),
      year: month.slice(0, 4),
      projectId,
      orgId: projectProfile.orgId,
      totalEmployeeCost,
      settings: {
        workingDays,
        clientCurrency,
        fxValue,
        seatCost,
        workingHours: tenantConfig.settings.workingHours,
        currency: tenantConfig.settings.currency,
        adminCharge: tenantConfig.settings.adminCharge,
        managementCharge: tenantConfig.settings.managementCharge,
      },
      miscellaneousCost,
      travelCost,
      additionalCost,
      otherCost: 0,
    };
    RegenerateIndividualInvoices();
    regenerateInvoice(projectInvoiceData.id, invoiceData)
      .then((invoiceResponse) => {
        history.push(`/invoice/${projectId}`);
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };

  const generateAndDownloadExcel = (data: any[]) => {
    const columnsToInclude = [
      "employeeId",
      "employeeName",
      "projectName",
      "projectId",
      "salary",
      "salaryPerMonth",
      "utilization",
      "vendorCost",
      "costPerEmployee",
    ];

    const filteredData = data.map((item) => {
      const filteredItem: { [key: string]: any } = {};
      columnsToInclude.forEach((column) => {
        filteredItem[column] = item[column];
        if (column === "salary") {
          filteredItem[column] = item.salary * 12;
        } else {
          filteredItem[column] = item[column];
        }
      });
      return filteredItem;
    });

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(filteredData);
    XLSX.utils.book_append_sheet(wb, ws, "Employee Data");
    const fileName = "employee_data.xlsx";
    XLSX.writeFile(wb, fileName);
  };
  const handleHourlyRate = (event: any) => {
    setHourlyRate(event.target.value);
    if (event.target.value < 0.0) {
      setIsError(true);
      const result: any = {};
      result.message = "Hourly Rate cannot be less than 0 ";
      store.dispatch(showToast(result));
    } else {
      setIsError(false);
    }
  };
  const handleWorkingHours = (event: any) => {
    setWorkingHours(event.target.value);
    if (event.target.value < 0.0) {
      setIsError(true);
      const result: any = {};
      result.message = "Hourly Rate cannot be less than 0 ";
      store.dispatch(showToast(result));
    } else {
      setIsError(false);
    }
  };

  const renderEmployee = (
    employeeIndividual: any,
    index: any,
    invoiceResponse: any
  ) => {
    const dbDate = new Date(employeeIndividual.vendorCostExpiryDate);
    dbDate.setHours(0, 0, 0, 0);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    return (
      <tr key={index}>
        <td data-title="ID">
          <div className="aui-td">{employeeIndividual.employeeId}</div>
        </td>
        <td data-title="Name">
          <div className="aui-td">{employeeIndividual.employeeName}</div>
        </td>
        <td data-title="Salary">
          <div className="aui-td">
            {`${
              (clientCurrency === "USD" ? "$ " : "₹ ") +
              parseFloat(employeeIndividual.salary)?.toFixed(2)
            }`}
          </div>
        </td>
        <td data-title="Dates">
          <div className="aui-td justify-content-center">
            <button
              type="button"
              className="btn font-red font-500 w-auto button-transistion p-0"
              aria-label={`${firstDay} ${
                CONSTANTS.MONTHS[parseInt(month.slice(5, 7), 10) - 1]
              } - ${lastDay} ${
                CONSTANTS.MONTHS[parseInt(month.slice(5, 7), 10) - 1]
              } (${employeeIndividual.projectWorkingDays})`}
            >
              {`${firstDay} ${
                CONSTANTS.MONTHS[parseInt(month.slice(5, 7), 10) - 1]
              } - ${lastDay} ${
                CONSTANTS.MONTHS[parseInt(month.slice(5, 7), 10) - 1]
              }`}
              {` (${employeeIndividual.projectWorkingDays})`}
            </button>
          </div>
        </td>
        <td data-title="Hourly Rate">
          <div className="aui-td">
            {type === "new" || employeeIndividual.newtype === "edit" ? (
              <button
                type="button"
                className="btn btn-href font-red font-500 w-auto button-transistion p-0"
                data-toggle="modal"
                onClick={(event: any) => {
                  setId(employeeIndividual.id);
                  setEmpId(employeeIndividual.employeeId);
                  setEmpName(employeeIndividual.employeeName);
                  setHourly(employeeIndividual.hourlyRate);
                  setIndexNo(index);
                }}
                data-target={`#hourlyRateModal-${index}`}
                aria-haspopup="true"
                aria-expanded="false"
              >
                {parseFloat(employeeIndividual.hourlyRate)?.toFixed(2)}
              </button>
            ) : (
              parseFloat(employeeIndividual.hourlyRate)?.toFixed(2)
            )}
          </div>
        </td>
        <div
          className="modal fade show aui-modal"
          id={`hourlyRateModal-${index}`}
          tabIndex={-1}
          aria-modal="true"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered modal-sm">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="h4" id={`editHourlyRate-${index}`}>
                  {empName} ({empId})
                </h4>
              </div>
              <form autoComplete="off">
                <div className="modal-body p-0">
                  <div className="form-group row row-pt-3">
                    <label
                      className="col-6 col-lg-6 col-form-label"
                      htmlFor={`currentHourlyRate-${index}`}
                    >
                      Current Hourly Rate ($)
                    </label>
                    <div className="col-6">
                      <input
                        type="text"
                        id={`currentHourlyRate-${index}`}
                        className="form-control input-style"
                        value={parseFloat(hourly)}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group row  row-pt-3 required">
                    <label
                      className="col-6 col-lg-6 col-form-label"
                      htmlFor={`hourlyRate-${index}`}
                    >
                      New Hourly Rate ($)
                    </label>
                    <div className="col-6">
                      <input
                        type="number"
                        id={`hourlyRate-${index}`}
                        className="form-control"
                        value={hourlyRate}
                        onChange={handleHourlyRate}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer mt-4">
                  <div className="w-100">
                    <div className="d-flex flex-row-reverse">
                      <button
                        type="button"
                        className="btn btn-primary btn-round"
                        onClick={updateHourlyRate}
                        data-dismiss="modal"
                      >
                        Submit
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary btn-round mr-4"
                        data-dismiss="modal"
                        onClick={() => setHourlyRate("")}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <td data-title="Working Hours">
          <div className="aui-td">
            {type === "new" || employeeIndividual.newtype === "edit" ? (
              <button
                type="button"
                className="btn btn-href font-red font-500 w-auto button-transistion p-0"
                data-toggle="modal"
                onClick={(event: any) => {
                  setId(employeeIndividual.id);
                  setEmpId(employeeIndividual.employeeId);
                  setEmpName(employeeIndividual.employeeName);
                  setWorking(employeeIndividual.workingHours);
                  setIndexNo(index);
                }}
                data-target={`#workingHoursModal-${index}`}
                aria-haspopup="true"
                aria-expanded="false"
              >
                {parseFloat(employeeIndividual.workingHours)?.toFixed(2)}
              </button>
            ) : (
              parseFloat(employeeIndividual.workingHours)?.toFixed(2)
            )}
          </div>
        </td>
        <div
          className="modal fade show aui-modal"
          id={`workingHoursModal-${index}`}
          tabIndex={-1}
          aria-modal="true"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered modal-sm">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="h4" id={`editworkingHours-${index}`}>
                  {empName} ({empId})
                </h4>
              </div>
              <form autoComplete="off">
                <div className="modal-body p-0">
                  <div className="form-group row row-pt-3">
                    <label
                      className="col-6 col-lg-6 col-form-label"
                      htmlFor={`currentworkingHours-${index}`}
                    >
                      Current Working Hours ($)
                    </label>
                    <div className="col-6">
                      <input
                        type="text"
                        id={`currentworkingHours-${index}`}
                        className="form-control input-style"
                        value={parseFloat(working)}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group row  row-pt-3 required">
                    <label
                      className="col-6 col-lg-6 col-form-label"
                      htmlFor={`workingHours-${index}`}
                    >
                      New Working Hours ($)
                    </label>
                    <div className="col-6">
                      <input
                        type="number"
                        id={`workingHours-${index}`}
                        className="form-control"
                        value={workingHours}
                        onChange={handleWorkingHours}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer mt-4">
                  <div className="w-100">
                    <div className="d-flex flex-row-reverse">
                      <button
                        type="button"
                        className="btn btn-primary btn-round"
                        onClick={updateWorkingHours}
                        data-dismiss="modal"
                      >
                        Submit
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary btn-round mr-4"
                        data-dismiss="modal"
                        onClick={() => setWorkingHours("")}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <td data-title="Cost Per Employee ">
          <div
            className={
              styleHandle ? "aui-td alert_click" : "aui-td alert_noclick"
            }
          >
            <div className="alert_highlight spacebelow">
              <div>
                {`${
                  (clientCurrency === "USD" ? "$ " : "₹ ") +
                  parseInt(employeeIndividual.costPerEmployee, 10)?.toFixed(2)
                }`}
              </div>
            </div>
          </div>
        </td>
        <td data-title="Action">
          <div className="aui-td justify-content-center">
            {type === "new" ? (
              <>
                <button
                  type="button"
                  className="btn btn-href p-2"
                  data-toggle="modal"
                  onClick={(event: any) => {
                    setIndexNo(index);
                    setEmployeeName(employeeIndividual.employeeName);
                    setEmployeeId(employeeIndividual.employeeId);
                  }}
                  data-target={`#deleteFromInvoice-${index}`}
                  aria-haspopup="true"
                  aria-label="removeteammember"
                  aria-expanded="false"
                >
                  <i className="aha-icon-trash" />
                </button>
                <button
                  type="button"
                  className="btn btn-href p-2"
                  onClick={() => generateAndDownloadExcel(individualData)}
                  aria-label="downloadExcel"
                >
                  <i className="aha-icon-download" />
                </button>
              </>
            ) : (
              <>
                <button
                  type="button"
                  className="btn btn-href p-2"
                  data-toggle="modal"
                  onClick={(event: any) => {
                    setIndexNo(index);
                    setEmployeeName(employeeIndividual.employeeName);
                    setEmployeeId(employeeIndividual.employeeId);
                  }}
                  data-target={`#deleteFromInvoice-${index}`}
                  aria-haspopup="true"
                  aria-label="removeteammember"
                  aria-expanded="false"
                >
                  <i className="aha-icon-trash " />
                </button>
                <button
                  type="button"
                  className="btn btn-href p-2"
                  onClick={() => generateAndDownloadExcel(individualData)}
                  aria-label="downloadExcel"
                >
                  <i className="aha-icon-download" />
                </button>
              </>
            )}
            {/* Modal for delete employee from Invoice start */}
            <div
              className="modal fade show aui-modal"
              id={`deleteFromInvoice-${index}`}
              tabIndex={-1}
              aria-modal="true"
              role="dialog"
            >
              <div className="modal-dialog modal-dialog-centered modal-sm">
                <div className="modal-content">
                  <div className="modal-body p-0">
                    <div className="text-center">
                      <p>
                        Do you want to remove{" "}
                        <strong>{employeeIndividual.employeeName}</strong>?
                      </p>
                      <div className="mt-4">
                        <button
                          type="button"
                          className="btn btn-secondary btn-round btn-sm mx-2"
                          data-dismiss="modal"
                          aria-label="Cancel"
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          onClick={(event: any) => {
                            deleteTheEmployeeFromInvoice(event);
                            setRegenerate(false);
                          }}
                          data-dismiss="modal"
                          className="btn btn-primary btn-round btn-sm mx-2"
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    );
  };

  return (
    <div className="d-flex flex-column">
      <Navigator isActive="project-list" />
      <div className="d-flex flex-column flex-grow-1">
        <ProjectManagementWrapper className="container-fluid">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb aha-breadcrumb pl-0">
              <li className="breadcrumb-item">
                <a href="/project-list">Project List</a>
              </li>
              <li className="breadcrumb-item">
                <a href={`/project/${projectId}`}>
                  {projectProfile.projectName}
                </a>
              </li>
              <li className="breadcrumb-item">
                <a href={`/invoice/${projectId}`}>Invoice</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <a>
                  {`${
                    CONSTANTS.MONTHS[parseInt(month.slice(5, 7), 10) - 1]
                  } ${month.slice(0, 4)}`}
                </a>
              </li>
            </ol>
          </nav>
          <h1 className="h3 font-400 proj-mgmt-title">Project Management</h1>
          <div className="d-lg-flex">
            <ShopCPRMenu projectId={projectId} />
            {loading ? (
              <div className="proj-mgt-table flex-grow-1">
                <div className="proj-heading justify-content-between position-relative align-items-center">
                  <div className="position-relative">
                    <h2 className="h3 font-400 mb-0">
                      {projectProfile.projectName}
                    </h2>

                    {type === "new" ? (
                      <div className="d-flex">
                        <p className="my-2 pt-2">New Invoice</p>
                        <div className="calendar">
                          <input
                            className="m-2 ml-4 bordernone form-control"
                            type="month"
                            id="invoiceMonth"
                            aria-label={`invoice Month ${
                              CONSTANTS.MONTHS[
                                parseInt(month.slice(5, 7), 10) - 1
                              ]
                            } ${month.slice(0, 4)}`}
                            value={month}
                            tabIndex={0}
                            min="2020-01"
                            max="2100-01"
                            onChange={calendarHandler}
                            defaultValue={currentMonth}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="d-flex">
                        <p className="my-2 pt-2">Invoice</p>
                        <div className="calendar">
                          <input
                            className="m-2 ml-4 bordernone form-control"
                            type="month"
                            id="invoiceMonth"
                            aria-label={`invoice Month ${
                              CONSTANTS.MONTHS[
                                parseInt(month.slice(5, 7), 10) - 1
                              ]
                            } ${month.slice(0, 4)}`}
                            defaultValue={currentMonth}
                            disabled
                          />
                        </div>
                      </div>
                    )}

                    <div className="position-absolute shoplogo">
                      {projectProfile.logoUrl ? (
                        <div className="position-absolute shoplogo">
                          <img src={projectProfile.logoUrl} alt="Logo" />
                        </div>
                      ) : (
                        <div />
                      )}
                    </div>
                  </div>
                </div>
                <div className="invoicedate_table_EDC">
                  <table className="aui-responsive-status-table ">
                    <thead>
                      <tr>
                        <th scope="col">
                          <div className="aui-th">ID</div>
                        </th>
                        <th scope="col">
                          <div className="aui-th">Name</div>
                        </th>
                        <th scope="col">
                          <div className="aui-th">Monthly Salary(₹)</div>
                        </th>
                        <th scope="col">
                          <div className="aui-th">Date</div>
                        </th>
                        <th scope="col">
                          <div className="aui-th">Hourly Rate ($)</div>
                        </th>
                        <th scope="col">
                          <div className="aui-th">Working Hours</div>
                        </th>
                        <th scope="col">
                          <div className="aui-th">Cost Per Employee</div>
                        </th>
                        <th scope="col">
                          <div className="aui-th">Action</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody> {individualData.map(renderEmployee)} </tbody>
                  </table>
                  {individualData.length <= 29 ? (
                    <div className="d-flex justify-content-between tec mb-4">
                      <p className="m-0 p-3">
                        <button
                          type="button"
                          className="btn btn-text settingsfocus"
                          data-toggle="modal"
                          data-backdrop="static"
                          data-keyboard="false"
                          onClick={() => {
                            setEdit(true);
                          }}
                          data-target="#addEmployee"
                          aria-label="addEmployee"
                        >
                          <img src="/images/addplus.png" alt="plusIcon" />
                        </button>
                      </p>
                    </div>
                  ) : null}

                  <div className="d-flex justify-content-between tec mb-4">
                    <p className="m-0 p-3">Total Employee Cost</p>
                    <p className="m-0 p-3">{`${
                      clientCurrency === "USD" ? "$ " : "₹ "
                    } ${totalEmployeeCost?.toFixed(2)}`}</p>
                  </div>
                  <div className="d-flex justify-content-end py-5">
                    <button
                      type="button"
                      aria-label="Reset Invoice"
                      className="btn btn-round btn-secondary mr-4"
                      onClick={(event: any) => {
                        getInvoiceDefaultValues();
                        getClientConfiguration();
                        setGenerate(false);
                        setRegenerate(true);
                      }}
                    >
                      Reset
                    </button>
                    <button
                      type="button"
                      aria-label="cancel invoice"
                      className="btn btn-round btn-secondary mr-4"
                      onClick={() => {
                        history.push(`/invoice/${projectId}`);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      aria-label="generate invoice"
                      className="btn btn-round btn-primary"
                      onClick={
                        type === "new"
                          ? validateInvoiceGeneration
                          : regenerateInvoiceData
                      }
                      disabled={type === "new" ? generate : regenerate}
                    >
                      {type === "new" ? "Generate" : "Regenerate"}
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="aui-block-loader proj-mgt-table"
                role="alert"
                aria-live="assertive"
                aria-label="Page is Loading"
              />
            )}
          </div>

          <div
            className="modal fade show aui-org-modal aui-new-org aui-modal"
            id="addEmployee"
            tabIndex={-1}
            aria-labelledby="addEmployee"
            aria-modal="true"
            role="dialog"
          >
            <div className="modal-dialog modal-md">
              <div className="modal-content">
                <div className="modal-header d-flex">
                  <div>
                    <h4 className="h4 m-0" id="addMember">
                      Add Employee
                    </h4>
                  </div>
                </div>
                <form autoComplete="off">
                  <div className="modal-body p-0">
                    <div className="form-group row required">
                      <label className="col-md-5 col-lg-4 col-form-label">
                        Employee
                      </label>
                      <div className="col-md-7 col-lg-8">
                        <div
                          id="aui-search-dropdown"
                          className="aui-search-dropdown dropdown-style"
                        >
                          <div className="aui-input-div position-relative">
                            <input
                              id="aui-select-active-employee"
                              className="w-100 text-truncate form-control"
                              type="text"
                              value={addEmployeeName}
                              onChange={(event: any) =>
                                setAddEmployeeName(event.target.value)
                              }
                              placeholder="Select Employee"
                              aria-label="Select Employee"
                            />
                            <i className="aha-icon-arrow-down pr-3 position-absolute aui-downicon" />
                          </div>
                          <ul id="aui-select-ul" className="aui-search-ui">
                            {remainingEmployees.map((obj: any) => {
                              return (
                                <li
                                  id={`aui-search-li-team+${obj.employee_id}`}
                                  tabIndex={0}
                                  onClickCapture={() => {
                                    setEmployeeId(obj.employee_id);
                                    setAddEmployeeName(
                                      `${obj.full_name}(${obj.employee_id}) `
                                    );
                                    setEmployeeName(`${obj.full_name}`);
                                  }}
                                >
                                  <span className="aui-search-li">
                                    {obj.full_name}({obj.employee_id})
                                  </span>
                                </li>
                              );
                            })}
                            <li className="aui-noresult-li">
                              <span className="aui-no-result-li">
                                No Results Found
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row required">
                      <label
                        htmlFor="monthlySalary"
                        className="col-md-5 col-lg-4 col-form-label"
                      >
                        Monthly Salary(₹)
                      </label>
                      <div className="col-md-7 col-lg-8">
                        <input
                          required
                          className="form-control"
                          type="number"
                          value={parseInt(MonthlySalary, 10)}
                          onChange={(event: any) => {
                            setMonthlySalary(event.target.value);
                            monthlySalaryValidation();
                          }}
                          id="monthlySalary"
                          aria-label="monthlySalary"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="monthlyVendorCost"
                        className="col-md-5 col-lg-4 col-form-label"
                      >
                        Monthly Vendor Cost(₹)
                      </label>
                      <div className="col-md-7 col-lg-8">
                        <input
                          className="form-control"
                          type="number"
                          id="monthlyVendorCost"
                          value={monthlyVendorCost}
                          onChange={(event: any) => {
                            setMonthlyVendorCost(event.target.value);
                            monthlyVendorCostValidation();
                          }}
                          aria-label="monthlyVendorCost"
                        />
                      </div>
                    </div>
                    <div className="form-group row required">
                      <label
                        htmlFor="hourly-rate"
                        className="col-md-5 col-lg-4 col-form-label"
                      >
                        Hourly Rate
                      </label>
                      <div className="col-md-7 col-lg-8">
                        <input
                          required
                          className="form-control"
                          type="number"
                          id="hourly-rate"
                          value={parseFloat(addHourlyRate)}
                          onChange={(event: any) => {
                            setAddHourlyRate(event.target.value);
                            hourlyRateValidation();
                          }}
                          aria-label="hourly-rate"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <div className="w-100">
                      <div className="d-flex flex-row-reverse">
                        <button
                          type="button"
                          className="btn btn-primary btn-round"
                          data-dismiss="modal"
                          disabled={
                            parseInt(monthlyVendorCost, 10)
                              ? employeeName &&
                                MonthlySalary &&
                                addHourlyRate &&
                                parseInt(addHourlyRate, 10) <= 9999999 &&
                                parseInt(addHourlyRate, 10) >= 0 &&
                                parseInt(MonthlySalary, 10) <= 9999999 &&
                                parseInt(MonthlySalary, 10) > 0 &&
                                parseInt(monthlyVendorCost, 10) <= 999999 &&
                                parseInt(monthlyVendorCost, 10) > 0
                                ? false
                                : formState
                              : employeeName &&
                                MonthlySalary &&
                                addHourlyRate &&
                                parseInt(addHourlyRate, 10) <= 9999999 &&
                                parseInt(addHourlyRate, 10) >= 0 &&
                                parseInt(MonthlySalary, 10) <= 9999999 &&
                                parseInt(MonthlySalary, 10) > 0
                              ? false
                              : formState
                          }
                          onClick={addNewEmployee}
                        >
                          Submit
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary btn-round mr-4"
                          data-dismiss="modal"
                          onClick={onReset}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </ProjectManagementWrapper>
      </div>
    </div>
  );
};

export default EDCInvoice;
