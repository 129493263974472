/* eslint-disable react/destructuring-assignment */
import addSalaryHistory from "api/employeeSalaryHistoryApi";
import updateSalary from "api/updateSalaryApi";
import updateSalaryTillDate from "api/updateSalaryHistoryApi";
import store from "app/store";
import { showToast, successToast } from "components/Toast/toast.slice";
import { number } from "prop-types";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

interface Props {
  employee: any;
  page: any;
  backToTable: any;
}

const DeactivatedEmployeeTable = (props: Props) => {
  const history = useHistory();
  const { backToTable } = props;
  const { page } = props;
  const countValue = page.totalCount % page.defaultPagination.pageSize;
  const { pageNumber } = page;
  const [selectedEmployeeId, setSelectedEmployeeId] = useState();
  const [employeeSalary, setemployeeSalary] = useState({
    employeeId: number,
    salary: "",
    salaryeffectivefromDate: "",
  });
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setSelectedEmployeeId(selectedEmployeeId);
  }, [selectedEmployeeId]);

  const employeeUpdate = {
    employeeId: selectedEmployeeId,
    salary: parseFloat(employeeSalary.salary),
    salaryeffectivefromDate: employeeSalary.salaryeffectivefromDate,
  };

  const success = (employeeId: any) => {
    const successmessage = `The Salary for Employee ID ${employeeId} is updated successfully`;
    const isValid = false;
    const result = {
      isValid,
      visibility: !isValid,
      message: successmessage,
    };
    store.dispatch(successToast(result));
    return result;
  };
  const renderEmployee = (employee: any, index: any) => {
    const onChangeForm = (event: any) => {
      event.persist();
      setemployeeSalary({
        ...employeeSalary,
        [event.target.name]: event.target.value,
      });
    };

    return (
      <tr className="aui-table-status-red" key={index}>
        <td data-title="ID">
          <div className="aui-td">
            <strong>{employee.employeeId}</strong>
          </div>
        </td>
        <td data-title="Name">
          <div className="aui-td">
            <div>
              <button
                className="btn btn-href btn-username font-red"
                onClick={() => {
                  let backToPage = pageNumber;
                  if (page.pagination.isLast) {
                    backToPage = countValue === 1 ? pageNumber - 1 : pageNumber;
                    if (backToPage === 0) {
                      backToPage = 1;
                    }
                  }
                  history.push(
                    {
                      pathname: `/deactivatedList/${employee.employeeId}`,
                    },
                    {
                      state: {
                        backToPage,
                      },
                    }
                  );
                }}
                type="button"
              >
                {employee.fullName}
              </button>
              <p className="mb-0">{employee.impelsysEmail}</p>
              <p>{employee.ahaEmail}</p>
            </div>
          </div>
        </td>
        <td data-title="Role">
          <div className="aui-td">{employee.role.join(", ")}</div>
        </td>
        <td data-title="Gross pay">
          <div className="aui-td d-flex positive-relative">
            {employee.salary}
            <div className="dropdown-menu btn-style">
              <ul className="navbar-nav flex-column px-2">
                <li className="d-flex nav-item dropdown px-lg-2 flex-column" />
              </ul>
            </div>
          </div>
        </td>
        <td data-title="Team">
          <div className="aui-td font-bold">
            {employee.team.join(", ") ? employee.team.join(", ") : "NA"}
          </div>
        </td>
        <td data-title="Work Categorization">
          <div className="aui-td font-bold">
            {employee.workCategorization ? employee.workCategorization : "NA"}
          </div>
        </td>
        <td data-title="Reason">
          <div className="aui-td">
            <button
              type="button"
              className="btn font-500 w-auto button-transition"
            >
              {employee.reason === "INVOLUNTARY" ? "Involuntary" : "Voluntary"}
            </button>
          </div>
        </td>
      </tr>
    );
  };
  return (
    <div className="deactivate-employeelist-table">
      <table className="aui-responsive-status-table">
        <thead>
          <tr>
            <th scope="col">
              <div className="aui-th">ID</div>
            </th>
            <th scope="col">
              <div className="aui-th">Name & Email</div>
            </th>
            <th scope="col">
              <div className="aui-th">Role</div>
            </th>
            <th scope="col">
              <div className="aui-th">Total Employee Cost</div>
            </th>
            <th scope="col">
              <div className="aui-th">Team</div>
            </th>
            <th scope="col">
              <div className="aui-th">Work Categorization</div>
            </th>
            <th scope="col">
              <div className="aui-th justify-content-center">Reason</div>
            </th>
          </tr>
        </thead>
        {loading ? null : (
          <tbody>
            {props.employee.length > 0 ? (
              props.employee.map(renderEmployee)
            ) : (
              <tr className="aui-status-table-emptystate">
                <td colSpan={7}>
                  <div className="aui-td">No data to display</div>
                </td>
              </tr>
            )}
          </tbody>
        )}
      </table>
    </div>
  );
};
export default DeactivatedEmployeeTable;
