/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

export const ProjectManagementWrapper = styled.div`
  .proj-mgmt-title {
    border-bottom: 2px solid #c10e21;
    padding: 20px 0;
    margin-bottom: 40px;
  }

  .dropdown-toggle::after {
    display: none;
  }
  .btn-style .form-control {
    height: 30px;
  }
  .proj-heading {
    background: #c10e21 0% 0% no-repeat padding-box;
    margin-bottom: 36px;
    color: #fff;
    padding: 19px 22px;
  }
  .nav-sidebar-active {
    background-color: #c10e21;
    color: #fff;
  }
  .proj-mgt-table {
    width: 100%;
    margin-left: 0;
    @media only screen and (min-width: 992px) {
      width: 70%;
      margin-left: 30px;
    }
  }

  .ul-list li a {
    font-size: 18px;
    border-bottom: 1px solid #e3e3e3;
  }
  .aui-responsive-table tbody tr td {
    vertical-align: middle;
  }
  .proj-bottom {
    padding-bottom: 40px;
  }
  .line {
    border-bottom: 1px solid #e3e3e3;
    @media only screen and (min-width: 768px) {
      padding: 30px 0;
    }
  }
  .projectlist-table {
    thead {
      @media only screen and (min-width: 992px) {
        th:nth-child(1) {
          width: 16%;
        }
        th:nth-child(2) {
          width: 12%;
        }
        th:nth-child(3) {
          width: 14%;
        }
        th:nth-child(4) {
          width: 17%;
        }
        th:nth-child(5) {
          width: 14%;
        }
        th:nth-child(6) {
          width: 14%;
        }
      }
      @media only screen and (min-width: 1200px) {
        th:nth-child(1) {
          width: 14%;
        }
        th:nth-child(2) {
          width: 10%;
        }
      }
    }
  }
  .aui-responsive-status-table tbody tr .aui-td {
    font-size: 16px !important;
  }
  .invoicedate_table_EDC {
    thead {
      @media only screen and (min-width: 992px) {
        th:nth-child(1) {
          width: 8%;
        }
        th:nth-child(2) {
          width: 14%;
        }
        th:nth-child(3) {
          width: 12%;
        }
        th:nth-child(4) {
          width: 15%;
        }
        th:nth-child(5) {
          width: 11%;
        }
        th:nth-child(6) {
          width: 11%;
        }
        th:nth-child(7) {
          width: 10%;
        }
      }
      @media only screen and (min-width: 1200px) {
        th:nth-child(1) {
          width: 8%;
        }
        th:nth-child(2) {
          width: 15%;
        }
        th:nth-child(3) {
          width: 15%;
        }
        th:nth-child(4) {
          width: 15%;
        }
        th:nth-child(5) {
          width: 10%;
        }
        th:nth-child(6) {
          width: 11%;
        }
        th:nth-child(7) {
          width: 15%;
        }
      }
    }
  }
  .invoicedate_table {
    thead {
      @media only screen and (min-width: 992px) {
        th:nth-child(1) {
          width: 8%;
        }
        th:nth-child(2) {
          width: 14%;
        }
        th:nth-child(3) {
          width: 12%;
        }
        th:nth-child(4) {
          width: 15%;
        }
        th:nth-child(5) {
          width: 11%;
        }
        th:nth-child(6) {
          width: 15%;
        }
        th:nth-child(7) {
          width: 10%;
        }
      }
      @media only screen and (min-width: 1200px) {
        th:nth-child(1) {
          width: 8%;
        }
        th:nth-child(2) {
          width: 15%;
        }
        th:nth-child(3) {
          width: 15%;
        }
        th:nth-child(4) {
          width: 15%;
        }
        th:nth-child(5) {
          width: 10%;
        }
        th:nth-child(6) {
          width: 18%;
        }
        th:nth-child(7) {
          width: 15%;
        }
      }
    }
  }
  .downloadbtn {
    height: 45px;
    width: 180px;
    margin-bottom: 10px;
    margin-top: -20px;
    display: flex;
    justify-content: right;
    align-items: center;
  }
  .txtcenter {
    text-align: left;
    @media only screen and (min-width: 992px) {
      text-align: center;
    }
  }
  .btnwidth {
    width: auto;
    @media only screen and (min-width: 768px) {
      width: 210px;
    }
  }
  .aha-icon-trash:before {
    font-size: x-large;
  }
  .foldstructure {
    position: absolute;
    width: 56px;
    height: 82px;
    top: 0;
    right: -2px;
    overflow: hidden;
    z-index: 1;
  }
  .foldstructure:before {
    content: "";
    position: absolute;
    top: -18px;
    right: 14px;
    border-width: 0px 27px 88px 0px;
    border-style: solid;
    border-color: rgb(255, 255, 255) rgb(255, 255, 255) rgba(109, 4, 15, 1)
      rgba(109, 4, 15, 1);
    background: rgba(109, 4, 15, 1);
    display: block;
    width: 0px;
    transform: rotate(-24deg) skewY(-35deg);
  }
  .foldstructure:after {
    content: "";
    position: absolute;
    top: -30px;
    right: -11px;
    width: 46px;
    height: 79px;
    background-color: rgb(255, 255, 255);
    transform: rotate(-45deg) skewY(-11deg);
  }
  .settings {
    color: #000;
    cursor: pointer;
    z-index: 1;
    right: -5px;
    top: -5px;
    font-size: 20px;
  }
  .settings img {
    border: 1px solid #c10e21;
    border-radius: 50%;
    padding: 5px;
  }
  .content_img {
    position: relative;
  }

  /* Child Text Container */
  .content_img span {
    position: absolute;
    top: 33px;
    right: 0;
    background-color: #fff;
    color: #000;
    margin-bottom: 5px;
    font-size: 12px;
    visibility: hidden;
    -webkit-transition: visibility 0s, opacity 0.5s linear;
    transition: visibility 0s, opacity 0.5s linear;
  }

  /* Hover on Parent Container */
  .content_img:hover {
    cursor: pointer;
  }

  .content_img:hover span {
    width: 95px;
    padding: 5px;
    visibility: visible;
  }
  ul.dropdown-menu.p-lg-0.show {
    left: -40px !important;
    top: 3px !important;
  }
  ul.dropdown-menu li {
    border-bottom: 1px solid #e3e3e3;
  }
  .aui-responsive-table.aui-table-cols tr {
    border-bottom: none;
  }
  a.dropdown-item.settings-item:active {
    color: #000;
    background-color: #fff;
  }
  .shoplogo {
    background-color: #fff;
    border-radius: 50%;
    right: 34px;
    top: -6px;
  }
  .shoplogo img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    padding: 8px;
    @media only screen and (min-width: 1200px) {
      width: 85px;
      height: 85px;
    }
  }
  .teammember-table {
    thead {
      th:nth-child(1) {
        width: 15%;
      }
    }
  }
  .aui-responsive-table tbody tr .aui-td {
    font-size: 16px;
  }
  .invoice-table {
    thead {
      th:nth-child(1) {
        width: 5%;
      }
      th:nth-child(2) {
        width: 18%;
      }
      th:nth-child(3) {
        width: 12%;
      }
      th:nth-child(4) {
        width: 10%;
      }
      th:nth-child(5) {
        width: 15%;
      }
    }
  }
  .dropdown-item:hover,
  .dropdown-item:focus {
    background-color: #c10e21;
    color: #fff;
  }
  .aui-td button {
    font-size: 16px;
    text-align: left;
  }
  .form-control.input-style {
    border: none !important;
  }
  .form-control.input-style:focus {
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
  }
  .modal-footer {
    border-top: 1px solid #e2e2e2 !important;
    padding: 40px 0 0 !important;
  }
  .spaceeven {
    display: flex;
    justify-content: space-between;
    span {
      display: block;
    }
    span:first-child {
      width: 60%;
    }
    span:nth-child(2) {
      width: 40%;
    }
  }
  .tec {
    background-color: #f8f8f8;
    border: 1px solid #e3e3e3;
    p {
      display: block;
    }
    p:first-child {
      width: 80%;
    }
    p:nth-child(2) {
      width: 20%;
    }
  }
  .tpc {
    background-color: #ffffff;
    border: 1px solid #e3e3e3;
    p {
      display: block;
    }
    p:first-child {
      width: 80%;
    }
    p:nth-child(2) {
      width: 20%;
    }
  }
  .aui-search-dropdown.dropdown-style {
    max-width: 100%;
  }
  .aha-icon-arrow-down:before {
    font-size: 0.6rem !important;
    color: #696969 !important;
  }
  .calendar input {
    background-color: #c10e21 !important;
    border: none !important;
    width: 90%;
    color: #fff !important;
    ::-webkit-calendar-picker-indicator {
      filter: invert(1);
    }
  }
  .bordernone {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }
  .alert_click {
    font-size: 16px;
    font-weight: normal;
    justify-content: flex-end;
    text-align: left;
  }
  .alert_noclick {
    font-size: 16px;
    font-weight: normal;
    justify-content: flex-end;
    text-align: left;
  }
  .alert_click:hover .alerttooltip_ul_list {
    display: block;
    width: 300px;
    cursor: pointer;
    background-color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    @media only screen and (min-width: 1200px) {
      width: 350px;
    }
  }
  .alerttooltip_ul_list {
    display: none;
    position: absolute;
    z-index: 1000;
    box-shadow: 0px 3px 6px #00000029;
    left: 10%;
    @media only screen and (min-width: 768px) {
      left: -40%;
      top: 72%;
    }
    @media only screen and (min-width: 1200px) {
      left: -40%;
    }
  }
  .alerttooltip_ul_list,
  .alerttooltip_li_list {
    list-style-type: none;
    float: left;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  .alerttooltip_li_list {
    background: #ffffff;
    padding: 5px;
  }
  .alert_highlight:hover {
    color: #c10e21;
  }
  .spacebelow {
    display: flex;
    width: 100%;
    div {
      display: block;
    }
    div:nth-child(1) {
      flex-grow: 1;
    }
    div:nth-child(2) {
      width: 50px;
    }
  }
  .validemail {
    color: #c0242b;
    font-size: 0.8rem;
    padding-top: 5px;
    padding-left: 5px;
  }
`;
