import { combineReducers } from "@reduxjs/toolkit";
import loaderReducer from "../components/Loader/loader.slice";
import toastReducer from "../components/Toast/toast.slice";

const rootReducer = combineReducers({
  loader: loaderReducer,
  toast: toastReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
