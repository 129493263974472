import bcrypt from "bcryptjs";

const hashUserKey = async (userKey) => {
  return new Promise((resolve, reject) => {
    const saltRounds = 10;
    bcrypt.hash(userKey, saltRounds, (err, hashedUserKey) => {
      if (err) {
        reject(err);
      } else {
        resolve(hashedUserKey);
      }
    });
  });
};

const compareUserKeys = async (userKey, storedHashedUserKey) => {
  return new Promise((resolve, reject) => {
    bcrypt.compare(userKey, storedHashedUserKey, (err, result) => {
      if (err) {
        reject(err);
      } else {
        resolve(result);
      }
    });
  });
};

const hashAndCompareUserKeys = async (userKey, storedHashedUserKey) => {
  const result = await compareUserKeys(userKey, storedHashedUserKey);
  return result;
};

export { hashUserKey, hashAndCompareUserKeys };
