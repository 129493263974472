const config: any = {
  env: process.env.REACT_APP_MY_ENV || "dev",
  dev: {
    apiEnspoints: {
      accounts:
        "https://4cka224pqe.execute-api.us-east-1.amazonaws.com/dev/accounts",
    },
  },
  qa: {
    apiEnspoints: {
      accounts:
        "https://cri3cllq73.execute-api.us-east-1.amazonaws.com/qa/accounts",
    },
  },
  prod: {
    apiEnspoints: {
      accounts:
        "https://lr4uzkdaze.execute-api.us-east-1.amazonaws.com/prod/accounts",
    },
  },
  local: {
    apiEnspoints: {
      accounts: "http://localhost:3000/accounts",
    },
  },
};

export default config;
