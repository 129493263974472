/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from "react";
import editUtilization from "api/editUtilization.Api";
import { onSuccess } from "common/messages";
import deleteEmployeeFromProject from "api/deleteEmployeeFromProjectAPI";
import store from "app/store";
import { showToast, successToast } from "components/Toast/toast.slice";
import { parseJwt } from "utils";
import CONSTANTS from "common/constants";
import editHourlyRate from "api/updateHourlyRateApi";

interface Props {
  teamMembers: any;
  backToTable: any;
}
const TeamMemberTable = (props: Props) => {
  const { backToTable } = props;
  const [id, setId] = useState("");
  const [empId, setEmpId] = useState("");
  const [utilization, setUtilization] = useState("");
  const [hourlyRate, setHourlyRate] = useState("");
  const [empName, setEmpName] = useState("");
  const [util, setUtil] = useState<any>();
  const [hourly, setHourly] = useState<any>();
  const [userRoles, setUserRoles] = useState<any>();
  const userToken = localStorage.userAccessToken;
  const tokenPayload = parseJwt(userToken).parsedJWT;
  const { userRole } = tokenPayload;
  useEffect(() => {
    setUserRoles(userRole);
  });

  useEffect(() => {
    setId(id);
    setEmpId(empId);
  }, [id, empId]);
  const success = (employeeId: any) => {
    const successmessage = `The Utilization for Employee ID ${employeeId} is updated successfully`;
    const isValid = false;
    const result = {
      isValid,
      visibility: !isValid,
      message: successmessage,
    };
    store.dispatch(successToast(result));
    return result;
  };
  const successHourly = (employeeId: any) => {
    const successmessageHourly = `The Hourly Rate for Employee ID ${employeeId} is updated successfully`;
    const isValid = false;
    const result = {
      isValid,
      visibility: !isValid,
      message: successmessageHourly,
    };
    store.dispatch(successToast(result));
    return result;
  };
  const updateUtilization = (event: any) => {
    event.preventDefault();
    setUtilization("");
    const employeeUtilization = {
      utilization: parseFloat(utilization),
    };
    editUtilization(id, employeeUtilization)
      .then(() => {
        success(empId);
        backToTable();
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };
  const updateHourlyRate = (event: any) => {
    event.preventDefault();
    setHourlyRate("");
    const employeeHourlyRate = {
      hourlyRate: parseFloat(hourlyRate),
    };
    editHourlyRate(id, employeeHourlyRate)
      .then(() => {
        successHourly(empId);
        backToTable();
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };
  const deleteTheEmployeeFromProject = (event: any) => {
    event.preventDefault();
    deleteEmployeeFromProject(id)
      .then(() => {
        onSuccess(empId);
        backToTable();
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };

  const renderTeam = (teamMembers: any, index: any) => {
    return (
      <tr className="aui-table-status-red" key={index}>
        <td data-title="ID">
          <div className="aui-td">{teamMembers.employeeId}</div>
        </td>
        <td data-title="Name">
          <div className="aui-td w-auto">
            {teamMembers["employee.fullName"]}
          </div>
        </td>
        <td data-title="Project Team">
          <div className="aui-td">{teamMembers.projectTeam}</div>
        </td>
        <td data-title="Role">
          <div className="aui-td">{teamMembers.roleName}</div>
        </td>
        <td data-title="Utilization">
          <div className="aui-td w-auto txtcenter">
            {userRoles === CONSTANTS.ROLES.ADMIN ||
            userRoles === CONSTANTS.ROLES.SUPERADMIN ? (
              <button
                type="button"
                className="btn btn-href font-red font-500 w-auto button-transistion p-0"
                data-toggle="modal"
                onClick={(event: any) => {
                  setId(teamMembers.id);
                  setEmpId(teamMembers.employeeId);
                  setEmpName(teamMembers["employee.fullName"]);
                  setUtil(teamMembers.utilization);
                }}
                data-target={`#utilizationModal-${index}`}
                aria-haspopup="true"
                aria-expanded="false"
              >
                {`${Math.round(teamMembers.utilization)}%`}
              </button>
            ) : (
              <div className="aui-td">
                {`${Math.round(teamMembers.utilization)}%`}
              </div>
            )}
            <div
              className="modal fade show aui-modal"
              id={`utilizationModal-${index}`}
              tabIndex={-1}
              aria-modal="true"
              role="dialog"
            >
              <div className="modal-dialog modal-dialog-centered modal-sm">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="h4" id={`editUtilization-${index}`}>
                      {empName} ({empId})
                    </h4>
                  </div>
                  <form autoComplete="off">
                    <div className="modal-body p-0">
                      <div className="form-group row row-pt-3">
                        <label
                          className="col-6 col-lg-6 col-form-label"
                          htmlFor={`currentUtilization-${index}`}
                        >
                          Current Utilization (%)
                        </label>
                        <div className="col-6">
                          <input
                            type="text"
                            id={`currentUtilization-${index}`}
                            className="form-control input-style"
                            value={parseFloat(util)}
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group row  row-pt-3 required">
                        <label
                          className="col-6 col-lg-6 col-form-label"
                          htmlFor={`utilization-${index}`}
                        >
                          New Utilization (%)
                        </label>
                        <div className="col-6">
                          <input
                            type="number"
                            id={`utilization-${index}`}
                            className="form-control"
                            value={utilization}
                            onChange={(event: any) => {
                              setUtilization(event.target.value);
                            }}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer mt-4">
                      <div className="w-100">
                        <div className="d-flex flex-row-reverse">
                          <button
                            type="button"
                            className="btn btn-primary btn-round"
                            onClick={updateUtilization}
                            data-dismiss="modal"
                          >
                            Submit
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary btn-round mr-4"
                            data-dismiss="modal"
                            onClick={() => setUtilization("")}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </td>
        <td data-title="Hourly Rate">
          <div className="aui-td w-auto txtcenter">
            {userRoles === CONSTANTS.ROLES.ADMIN ||
            userRoles === CONSTANTS.ROLES.SUPERADMIN ? (
              <button
                type="button"
                className="btn btn-href font-red font-500 w-auto button-transistion p-0"
                data-toggle="modal"
                onClick={(event: any) => {
                  setId(teamMembers.id);
                  setEmpId(teamMembers.employeeId);
                  setEmpName(teamMembers["employee.fullName"]);
                  setHourly(teamMembers.hourlyRate);
                }}
                data-target={`#hourlyRateModal-${index}`}
                aria-haspopup="true"
                aria-expanded="false"
              >
                {`${Math.round(teamMembers.hourlyRate)}`}
              </button>
            ) : (
              <div className="aui-td">
                {`${Math.round(teamMembers.hourlyRate)}`}
              </div>
            )}
            <div
              className="modal fade show aui-modal"
              id={`hourlyRateModal-${index}`}
              tabIndex={-1}
              aria-modal="true"
              role="dialog"
            >
              <div className="modal-dialog modal-dialog-centered modal-sm">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="h4" id={`editHourlyRate-${index}`}>
                      {empName} ({empId})
                    </h4>
                  </div>
                  <form autoComplete="off">
                    <div className="modal-body p-0">
                      <div className="form-group row row-pt-3">
                        <label
                          className="col-6 col-lg-6 col-form-label"
                          htmlFor={`currentHourlyRate-${index}`}
                        >
                          Current Hourly Rate ($)
                        </label>
                        <div className="col-6">
                          <input
                            type="text"
                            id={`currentHourlyRate-${index}`}
                            className="form-control input-style"
                            value={parseFloat(hourly)}
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group row  row-pt-3 required">
                        <label
                          className="col-6 col-lg-6 col-form-label"
                          htmlFor={`hourlyRate-${index}`}
                        >
                          New Hourly Rate ($)
                        </label>
                        <div className="col-6">
                          <input
                            type="number"
                            id={`hourlyRate-${index}`}
                            className="form-control"
                            value={hourlyRate}
                            onChange={(event: any) => {
                              setHourlyRate(event.target.value);
                            }}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer mt-4">
                      <div className="w-100">
                        <div className="d-flex flex-row-reverse">
                          <button
                            type="button"
                            className="btn btn-primary btn-round"
                            onClick={updateHourlyRate}
                            data-dismiss="modal"
                          >
                            Submit
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary btn-round mr-4"
                            data-dismiss="modal"
                            onClick={() => setHourlyRate("")}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </td>
        {(userRoles === CONSTANTS.ROLES.ADMIN ||
          userRoles === CONSTANTS.ROLES.SUPERADMIN) && (
          <td data-title="Action" className="txtcenter">
            <button
              type="button"
              className="btn btn-href font-500 w-auto button-transistion p-0"
              data-toggle="modal"
              onClick={(event: any) => {
                setId(teamMembers.id);
                setEmpId(teamMembers.employeeId);
                setEmpName(teamMembers["employee.fullName"]);
              }}
              data-target={`#deleteFromProject-${index}`}
              aria-haspopup="true"
              aria-label="removeteammember"
              aria-expanded="false"
            >
              <i className="aha-icon-trash font-500" />
            </button>

            {/* Modal for delete employee from Project start */}
            <div
              className="modal fade show aui-modal"
              id={`deleteFromProject-${index}`}
              tabIndex={-1}
              aria-modal="true"
              role="dialog"
            >
              <div className="modal-dialog modal-dialog-centered modal-sm">
                <div className="modal-content">
                  <div className="modal-body p-0">
                    <div className="text-center">
                      <p>
                        Do you want to remove <strong>{empName}</strong>?
                      </p>
                      <div className="mt-4">
                        <button
                          type="button"
                          className="btn btn-secondary btn-round btn-sm mx-2"
                          data-dismiss="modal"
                          aria-label="Cancel"
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          onClick={deleteTheEmployeeFromProject}
                          data-dismiss="modal"
                          className="btn btn-primary btn-round btn-sm mx-2"
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        )}
      </tr>
    );
  };
  return (
    <div className="teammember-table">
      <table className="aui-responsive-table mb-5">
        <thead>
          <tr>
            <th scope="col">
              <div className="aui-th">ID</div>
            </th>
            <th scope="col">
              <div className="aui-th">Name</div>
            </th>
            <th scope="col">
              <div className="aui-th">Project Team</div>
            </th>
            <th scope="col">
              <div className="aui-th">Role</div>
            </th>
            <th scope="col">
              <div className="aui-th text-center">Utilization</div>
            </th>
            <th scope="col">
              <div className="aui-th text-center">Hourly Rate ($)</div>
            </th>
            {(userRoles === CONSTANTS.ROLES.ADMIN ||
              userRoles === CONSTANTS.ROLES.SUPERADMIN) && (
              <th scope="col">
                <div className="aui-th text-center">Action</div>
              </th>
            )}
          </tr>
        </thead>
        <tbody>{props.teamMembers.map(renderTeam)}</tbody>
      </table>
    </div>
  );
};

export default TeamMemberTable;
