import React, { useEffect, useState } from "react";
import TeamMemberTable from "components/TeamMembersTable";
import getTeamMembers from "api/teamMembersAPI";
import getEmployeeRole from "api/getEmployeeRoleAPI";
import projectView from "api/getProjectDetailsApi";
import store from "app/store";
import getProjectMembers from "api/ProjectEmployeeMembersAPI";
import Pagination, { IPagination } from "components/Pagination/index";
import { showToast, successToast } from "components/Toast/toast.slice";
import addMember from "api/assignProjectAPI";
import { getEmployees } from "api/getEmployees";
import getTotalUtilization from "api/getTotalUtilization.Api";
import { Redirect } from "react-router-dom";
import getProjectTeam from "api/projectTeamAPI";
import CONSTANTS from "common/constants";
import { parseJwt } from "utils";
import Navigator from "../../components/Navigator";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { ProjectManagementWrapper } from "../ProjectManagement/styled";
import { ShopCPRMenu } from "./ShopCPRMenu";
import MultiSelectDropdown from "../../components/Multiselect";
import { getRoles, postRole } from "../../api/getRoles";

const TeamMember = (props: any) => {
  if (!localStorage.userAccessToken) return <Redirect to="/" />;
  const [projectProfile, setProjectProfile] = useState<any>([]);
  const {
    match: {
      params: { projectId },
    },
  } = props;
  const [teamMembers, setTeamMembers] = useState<any>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalCount, setTotalCount] = useState();
  const [employeeName, setEmployeeName] = useState<any>([]);
  const [roleName, setRoleName] = useState<any>([]);
  const [employeeId, setEmployeeId] = useState("");
  const [roleId, setRoleId] = useState("");
  const [utilization, setUtilization] = useState("");
  const [projectMembers, setProjectMembers] = useState<any>([]);
  const [totalUtilization, setTotalUtilization] = useState("");
  const [edit, setEdit] = useState(false);
  const [formState, setFormState] = useState(true);
  const [employeeState, setEmployeeState] = useState(false);
  const [projectTeam, setProjectTeam] = useState("");
  const [projectTeamName, setProjectTeamName] = useState<any>([]);
  const [remainingEmployees, setRemainingEmployees] = useState<any>([]);
  const [utilizationCriteria, setUtilizationCriteria] = useState(true);
  const [role, setRole] = useState<any>([]);
  const [salaryType, setSalaryType] = useState<any>();
  const [hourlyrate, setHourlyRate] = useState<any>();
  const paginationSetLimit = 5;
  const defaultPagination: IPagination = {
    pageNumber: 1,
    pageSize: 15,
    totalPages: 5,
    isFirst: true,
    isLast: false,
    setPageNumber,
    paginationSetLimit,
    totalCount: 0,
  };
  const [employeeData, setEmployeeData] = useState("");
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<IPagination>(defaultPagination);
  const [selectedRole, setSelectedRole] = useState<string[]>([]);
  const [userRoles, setUserRoles] = useState<any>();
  const userToken = localStorage.userAccessToken;
  const tokenPayload = parseJwt(userToken).parsedJWT;
  const { userRole } = tokenPayload;
  useEffect(() => {
    setUserRoles(userRole);
  });
  const TeamMembersRequest = () => {
    getTeamMembers(projectId, pageNumber, defaultPagination.pageSize)
      .then((teamResponse) => {
        setTeamMembers(teamResponse.data.teamMembersDetails);
        setPagination({ ...teamResponse.data._pagination, setPageNumber });
        setTotalCount(teamResponse.data._pagination.totalCount);
        setLoading(true);
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };
  const projectProfileRequest = () => {
    projectView(projectId)
      .then((projectResponse) => {
        setProjectProfile(projectResponse.data.projectDetails);
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };

  const success = (empId: any) => {
    const successMessage = ` Employee ID ${empId} is added as a Team Member successfully`;
    const isValid = false;
    const result = {
      isValid,
      visibility: !isValid,
      message: successMessage,
    };
    if (!isValid) store.dispatch(successToast(result));
    return result;
  };
  const employeeRequest = () => {
    getEmployees().then((Response) => {
      setEmployeeName(Response.data.employees);
    });
  };
  const ProjectMembersRequest = () => {
    getProjectMembers(projectId).then((Response) => {
      setProjectMembers(Response.data.employees);
    });
  };
  const remainingEmployeesList = () => {
    const remaining = employeeName.filter((list1: any) =>
      projectMembers.every(
        (list2: any) => list2.employee_id !== list1.employee_id
      )
    );
    setRemainingEmployees(remaining);
  };
  const roleRequest = () => {
    getRoles()
      .then((Response) => {
        setRoleName(Response.data.roles);
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };
  const resetModal = () => {
    setEdit(false);
    setUtilization("");
    setProjectTeam("");
    setRoleId("");
    setSelectedRole([]);
    setEmployeeId("");
    setTotalUtilization("");
    setFormState(true);
    setUtilizationCriteria(true);
    setEmployeeState(false);
    setEmployeeData("");
    setRole("");
    setSalaryType("");
  };

  const addTeamMember = (event: any) => {
    event.preventDefault();
    const memberDetails = {
      employeeId,
      projectTeam,
      utilization: utilization === "" ? 0 : utilization,
      projectId,
      salaryType,
      hourlyRate: hourlyrate,
      selectedRole: JSON.parse(`["${selectedRole}"]`),
    };

    if (memberDetails.projectTeam === "") {
      store.dispatch(showToast({ message: "Please select the Project Team" }));
    } else if (memberDetails.selectedRole.length === 0) {
      store.dispatch(showToast({ message: "Please select the Role" }));
    } else if (memberDetails.employeeId === "") {
      store.dispatch(showToast({ message: "Please select the Employee" }));
    } else if (memberDetails.projectId === "") {
      store.dispatch(showToast({ message: "Please select the Project" }));
    } else {
      addMember(memberDetails)
        .then(() => {
          success(memberDetails.employeeId);
          TeamMembersRequest();
          ProjectMembersRequest();
          resetModal();
        })
        .catch((error: any) => {
          store.dispatch(showToast(error.details[0].message));
        });
    }
  };
  const memberHandler = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    addTeamMember(event);
    if (CONSTANTS.WINDOW.AUI.Search) {
      CONSTANTS.WINDOW.AUI.Search.init();
    }
  };
  const checkHourlyRatecondition = () => {
    if (edit === true) {
      if (parseInt(hourlyrate, 10) < 0) {
        store.dispatch(
          showToast({ message: "Hourly Rate cannot be Negative" })
        );
      } else if (hourlyrate >= 0) {
        store.dispatch(successToast({ message: "Hourly Rate is Valid!" }));
      } else if (
        !employeeId &&
        !roleId &&
        !projectTeam &&
        (utilization || hourlyrate)
      ) {
        store.dispatch(showToast({ message: "Please Select an Employee" }));
      }
    }
  };
  const checkcondition = () => {
    if (edit === true) {
      if (parseInt(utilization, 10) <= 0) {
        setUtilizationCriteria(false);
        store.dispatch(
          showToast({ message: "Utilization cannot be Zero or Negative" })
        );
      } else if (parseInt(utilization, 10) > 100) {
        setUtilizationCriteria(false);
        store.dispatch(
          showToast({ message: "Utilization cannot be more than 100" })
        );
      } else if (
        parseInt(utilization, 10) + parseInt(totalUtilization, 10) >
        100
      ) {
        setUtilizationCriteria(false);
        store.dispatch(
          showToast({ message: "Total Utilization cannot exceed 100 " })
        );
      } else if (
        parseInt(utilization, 10) + parseInt(totalUtilization, 10) <=
        100
      ) {
        setUtilizationCriteria(false);
        store.dispatch(
          successToast({ message: "Total Utilization is Valid!" })
        );
      } else if (!utilization) {
        setUtilizationCriteria(false);
        store.dispatch(showToast({ message: "Utilization cannot be NULL" }));
      } else if (!employeeId && !roleId && !projectTeam && utilization) {
        setUtilizationCriteria(false);
        store.dispatch(showToast({ message: "Please Select an Employee" }));
      } else {
        setUtilizationCriteria(true);
      }
    }
  };

  useEffect(() => {
    TeamMembersRequest();
    projectProfileRequest();
    employeeRequest();
    roleRequest();
    ProjectMembersRequest();
    remainingEmployeesList();
    checkcondition();
    if (CONSTANTS.WINDOW.AUI.Search) {
      CONSTANTS.WINDOW.AUI.Search.init();
    }
  }, [pageNumber, utilization]);

  useEffect(() => {
    if (edit === true) {
      getEmployeeRole(employeeId)
        .then((Response) => {
          setSelectedRole(Response.data.result.role);
        })
        .catch((error: any) => {
          store.dispatch(showToast(error.details[0].message));
        });
    }
  }, [employeeId]);

  useEffect(() => {
    getProjectTeam(projectId)
      .then((Response) => {
        setProjectTeamName(Response.data.project.project_teams);
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  }, [projectId]);

  const successPost = (eName: any) => {
    const successMessage = `${eName} is successfully added`;
    const isValid = false;
    const result = {
      isValid,
      visibility: !isValid,
      message: successMessage,
    };
    if (!isValid) store.dispatch(successToast(result));
    return result;
  };

  useEffect(() => {
    if (edit === true) {
      getTotalUtilization(employeeId)
        .then((employeeResponse) => {
          setTotalUtilization(employeeResponse.data.utilization);
        })
        .catch((error: any) => {
          store.dispatch(showToast(error.details[0].message));
        });
    }
  }, [employeeId]);
  return (
    <div className="d-flex flex-column">
      <Navigator isActive="project-list" />
      <div className="d-flex flex-column flex-grow-1">
        <ProjectManagementWrapper className="container-fluid">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb aha-breadcrumb pl-0">
              <li className="breadcrumb-item">
                <a href="/project-list">Project List</a>
              </li>
              <li
                className="breadcrumb-item"
                aria-label={`${projectProfile.projectName}`}
              >
                <a href={`/project/${projectId}`}>
                  {projectProfile.projectName}
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <a href={`/teammember/${projectId}`}>Team Members</a>
              </li>
            </ol>
          </nav>
          <h1 className="h3 font-400 proj-mgmt-title">
            {projectProfile.projectName}
          </h1>
          <div className="d-lg-flex">
            <ShopCPRMenu projectId={projectId} />
            {loading ? (
              <div className="proj-mgt-table flex-grow-1">
                <div className="proj-heading justify-content-between position-relative align-items-center">
                  <div className="foldstructure" />
                  <div className="position-relative">
                    <h2 className="h3 font-400 mb-0">
                      {projectProfile.projectName}
                    </h2>
                    {projectProfile.logoUrl ? (
                      <div className="position-absolute shoplogo">
                        <img src={projectProfile.logoUrl} alt="Logo" />
                      </div>
                    ) : (
                      <div />
                    )}
                  </div>
                  {
                    // eslint-disable-next-line no-nested-ternary
                    (userRoles === CONSTANTS.ROLES.ADMIN ||
                      userRoles === CONSTANTS.ROLES.SUPERADMIN) &&
                      teamMembers.length <= projectProfile.teamSize - 1 && (
                        <div>
                          <button
                            type="button"
                            className="btn btn-text settingsfocus content_img position-absolute settings ml-1 w-auto"
                            data-toggle="modal"
                            data-backdrop="static"
                            data-keyboard="false"
                            aria-label="addmemberbutton"
                            aria-haspopup="true"
                            aria-expanded="true"
                            onClick={() => {
                              setEdit(true);
                              remainingEmployeesList();
                            }}
                            data-target="#addmember"
                          >
                            <img
                              src="../images/plusicon.png"
                              alt="add member icon"
                            />
                            <span>Add Member</span>
                          </button>
                        </div>
                      )
                  }
                  <ul className="dropdown-menu p-lg-0">
                    <li>
                      <a
                        className="dropdown-item settings-item py-2"
                        data-toggle="modal"
                        data-backdrop="static"
                        data-keyboard="false"
                        onClickCapture={() => {
                          setEdit(true);
                        }}
                        data-target="#addmember"
                      >
                        Add Member
                      </a>
                    </li>
                  </ul>
                  <div className="d-md-flex py-2">
                    <span className="d-flex">
                      Team Size:{projectProfile.teamSize}
                    </span>
                    <span className="d-flex pl-md-4">
                      Start Date: {projectProfile.startDate}
                    </span>
                    <span className="pl-md-3">|</span>
                    <span className="pl-md-3">
                      End Date: {projectProfile.actualEndDate}
                    </span>
                  </div>
                </div>
                <TeamMemberTable
                  teamMembers={teamMembers}
                  backToTable={() => {
                    TeamMembersRequest();
                    ProjectMembersRequest();
                  }}
                />
              </div>
            ) : (
              <div
                className="aui-block-loader proj-mgt-table"
                role="alert"
                aria-live="assertive"
                aria-label="Page is Loading"
              />
            )}
          </div>
          {pagination && Number(pagination.totalPages) > 0 ? (
            <Pagination
              pageNumber={pagination.pageNumber}
              pageSize={pagination.pageSize}
              totalCount={pagination.totalCount}
              totalPages={pagination.totalPages}
              isFirst={pagination.isFirst}
              isLast={pagination.isLast}
              setPageNumber={pagination.setPageNumber}
              paginationSetLimit={5}
            />
          ) : null}
          {/* Modal for Add Member start */}
          <div
            className="modal fade show aui-modal"
            id="addmember"
            tabIndex={-1}
            aria-label="AddMember"
            aria-modal="true"
            role="dialog"
          >
            <div className="modal-dialog modal-md">
              <div className="modal-content">
                <div className="modal-header d-flex">
                  <h3 className="h4" id="removeuser">
                    Add Member
                  </h3>
                  {employeeState ? (
                    <div className="font-red">
                      Current Utilization: {totalUtilization}%
                    </div>
                  ) : null}
                </div>
                <form autoComplete="off">
                  <div className="modal-body p-0">
                    <div className="form-group row required">
                      <label
                        className="col-md-5 col-lg-4 col-form-label"
                        htmlFor="employeelist"
                      >
                        Employee
                      </label>
                      <div className="col-md-7 col-lg-8">
                        <select
                          id="employeelist"
                          className="form-control"
                          value={employeeData}
                          onChange={(event: any) => {
                            setEmployeeData(event.target.value);
                            setEmployeeId(event.target.value);
                            setEmployeeState(true);
                          }}
                        >
                          <option hidden> Select Employee </option>
                          {remainingEmployees.map((obj: any) => {
                            return (
                              <option
                                key={obj.employee_id}
                                id={`aui-search-li-team+${obj.employee_id}`}
                                tabIndex={0}
                                value={obj.employee_id}
                              >
                                {obj.full_name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="form-group row required">
                      <label
                        className="col-md-5 col-lg-4 col-form-label"
                        htmlFor="projectTeam"
                      >
                        Project Team
                      </label>
                      <div className="col-md-7 col-lg-8">
                        <select
                          id="projectTeam"
                          className="form-control"
                          value={projectTeam}
                          onChange={(event: any) =>
                            setProjectTeam(event.target.value)
                          }
                        >
                          <option hidden> Select Project Team </option>
                          {projectTeamName.map((obj: any) => {
                            return <option value={obj}>{obj}</option>;
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="form-group row required">
                      <label
                        className="col-md-5 col-lg-4 col-form-label"
                        htmlFor="roleName"
                      >
                        Role
                      </label>
                      <div className="col-md-7 col-lg-8">
                        <input
                          required
                          className="form-control"
                          type="text"
                          value={selectedRole}
                          onChange={(event: any) => {
                            setSelectedRole(event.target.value);
                          }}
                          id="role"
                          aria-label="role"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="checkbox"
                        className="col-sm-4 col-form-label"
                      >
                        Salary Type
                      </label>
                      <div className="col-sm-8 d-flex justify-content-around pt-2">
                        <div className="form-group form-radio">
                          <input
                            type="radio"
                            name="radio"
                            id="radio1"
                            value="option1"
                            onClick={() => setSalaryType("monthly")}
                          />
                          <label htmlFor="radio1">Monthly</label>
                        </div>
                        <div className="form-group form-radio">
                          <input
                            type="radio"
                            name="radio"
                            id="radio2"
                            value="option2"
                            onClick={() => setSalaryType("hourly")}
                          />
                          <label htmlFor="radio2">Hourly</label>
                        </div>
                      </div>
                    </div>
                    {salaryType === "monthly" ? (
                      <div className="form-group row required">
                        <label
                          className="col-md-5 col-lg-4 col-form-label"
                          htmlFor="utilization"
                        >
                          Utilization(%)
                        </label>
                        <div className="col-md-7 col-lg-8">
                          <input
                            id="utilization"
                            type="number"
                            className="form-control"
                            value={utilization}
                            onChange={(event: any) => {
                              setUtilization(event.target.value);
                              checkcondition();
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {salaryType === "hourly" ? (
                      <div className="form-group row required">
                        <label
                          className="col-md-5 col-lg-4 col-form-label"
                          htmlFor="utilization"
                        >
                          Hourly Rate($)
                        </label>
                        <div className="col-md-7 col-lg-8">
                          <input
                            id="hourly rate"
                            type="number"
                            className="form-control"
                            value={hourlyrate}
                            onChange={(event: any) => {
                              setHourlyRate(event.target.value);
                              checkHourlyRatecondition();
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="modal-footer mt-4">
                      <div className="w-100">
                        <div className="d-flex flex-row-reverse">
                          {salaryType === "monthly" ? (
                            <button
                              type="button"
                              className="btn btn-primary btn-round"
                              onClick={memberHandler}
                              data-dismiss="modal"
                              disabled={
                                employeeId &&
                                projectTeam &&
                                selectedRole &&
                                utilization &&
                                parseInt(utilization, 10) > 0 &&
                                parseInt(utilization, 10) <= 100 &&
                                parseInt(utilization, 10) +
                                  parseInt(totalUtilization, 10) <=
                                  100
                                  ? false
                                  : formState
                              }
                            >
                              Submit
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn btn-primary btn-round"
                              onClick={memberHandler}
                              data-dismiss="modal"
                              disabled={
                                employeeId &&
                                projectTeam &&
                                selectedRole &&
                                hourlyrate
                                  ? false
                                  : formState
                              }
                            >
                              Submit
                            </button>
                          )}
                          <button
                            type="button"
                            className="btn btn-secondary btn-round mr-4"
                            onClick={resetModal}
                            data-dismiss="modal"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          {/* Modal for Add Member start */}
        </ProjectManagementWrapper>
      </div>
    </div>
  );
};

export default TeamMember;
