export function getTokenPlayload(token: string) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
}

export async function sleep(time: number) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });
}

export function validJSON(JSONString: string) {
  try {
    JSON.parse(JSONString);
    return true;
  } catch (e) {
    return false;
  }
}

export const getCurrentQuarter = () => {
  const month = new Date().getMonth();
  if (month < 3) {
    return 1;
  }
  if (month < 6) {
    return 2;
  }
  if (month < 9) {
    return 3;
  }
  return 4;
};

export const getQuarterStartAndEndDate = (year: number, quarter: number) => {
  switch (quarter) {
    case 1:
      return `${year}-01-01/${year}-03-31`;
      break;
    case 2:
      return `${year}-04-01/${year}-06-30`;
      break;
    case 3:
      return `${year}-07-01/${year}-09-30`;
      break;
    case 4:
      return `${year}-10-01/${year}-12-31`;
      break;
    default:
      return null;
  }
};

export const getFIleSize = (fileSize: number) => {
  const fSExt = ["Bytes", "KB", "MB", "GB"];
  let i = 0;
  while (fileSize > 900) {
    fileSize /= 1000;
    i += 1;
  }
  return `${Math.round(fileSize * 100) / 100} ${fSExt[i]}`;
};

export const datetoLocaleDateString = (date: string) => {
  return new Date(`${date}`).toLocaleDateString();
};

export const datetoLocaleTimeString = (date: string) => {
  return new Date(`${date}`).toLocaleTimeString();
};

export const getLastEditMeasureDate = (quarter: number) => {
  switch (quarter) {
    case 1:
      return `Apr 30`;
      break;
    case 2:
      return `Jul 30`;
      break;
    case 3:
      return `Sep 30`;
      break;
    case 4:
      return `Jan 31`;
      break;
    default:
      return null;
  }
};

export const refreshTokenSetup = (res: any) => {
  let refreshTiming = (res.tokenObj.expires_in || 3600 - 5 * 60) * 1000;

  const refreshToken = async () => {
    const newAuthRes = await res.reloadAuthResponse();
    refreshTiming = (newAuthRes.expires_in || 3600 - 5 * 60) * 1000;
    localStorage.setItem("authToken", newAuthRes.id_token);

    setTimeout(refreshToken, refreshTiming);
  };

  setTimeout(refreshToken, refreshTiming);
};
