/* eslint-disable @typescript-eslint/no-unused-expressions */
import React from "react";

interface Props {
  data: any;
}
const ShopCPRForm = (props: any) => {
  const { data } = props;
  return (
    <table className="aui-responsive-table aui-table-cols mb-5">
      <tbody>
        <tr>
          <th scope="row" className="aui-row-th">
            Project Name
          </th>
          <td className="aui-row-td">{data.projectName}</td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            Project Code
          </th>
          <td className="aui-row-td">{data.projectCode}</td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            SOW
          </th>
          <td className="aui-row-td">{data.sow}</td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            Project Start Date
          </th>
          <td className="aui-row-td">{data.startDate}</td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            Planed End Date
          </th>
          <td className="aui-row-td">{data.plannedEndDate}</td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            Actual End Date
          </th>
          <td className="aui-row-td">{data.actualEndDate}</td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            Project Manager
          </th>
          <td className="aui-row-td">
            {data.meta && data.meta.projectManager}
          </td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            AHA Manager
          </th>
          <td className="aui-row-td">{data.meta && data.meta.ahaManager}</td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            AHA Manager Email ID
          </th>
          <td className="aui-row-td">
            {data.meta && data.meta.ahaManagerEmailId}
          </td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            Business Contact
          </th>
          <td className="aui-row-td">
            {data.meta && data.meta.businessContact}
          </td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            Description
          </th>
          <td className="aui-row-td">{data.description}</td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            Team Size
          </th>
          <td className="aui-row-td">{data.teamSize}</td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            Logo-Url
          </th>
          <td className="aui-row-td">{data.logoUrl ? data.logoUrl : "NA"}</td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            Service Type
          </th>
          <td className="aui-row-td">{data.serviceType}</td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            Tech Stack Description
          </th>
          <td className="aui-row-td">{data.techStackDescription}</td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            Product Owner
          </th>
          <td className="aui-row-td">{data.meta && data.meta.productOwner}</td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            Delivery Manager
          </th>
          <td className="aui-row-td">
            {data.meta && data.meta.deliveryManager}
          </td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            Application Architect
          </th>
          <td className="aui-row-td">
            {data.meta && data.meta.applicationArchitect
              ? data.meta.applicationArchitect
              : "NA"}
          </td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            Technical Manager
          </th>
          <td className="aui-row-td">
            {data.meta && data.meta.technicalManager
              ? data.meta.technicalManager
              : "NA"}
          </td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            Solution Architect
          </th>
          <td className="aui-row-td">
            {data.meta && data.meta.solutionArchitect
              ? data.meta.solutionArchitect
              : "NA"}
          </td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            Scrum Master
          </th>
          <td className="aui-row-td">
            {data.meta && data.meta.scrumMaster ? data.meta.scrumMaster : "NA"}
          </td>
        </tr>
      </tbody>
    </table>
  );
};
export default ShopCPRForm;
