import React from "react";
import { NavLink } from "react-router-dom";

interface Props {
  projectId: any;
}
export const ShopCPRMenu = (props: Props) => {
  const { projectId } = props;
  return (
    <div className="aui-sidenav">
      <nav className="navbar-expand-lg overflow-hidden">
        <button
          className="navbar-toggler float-right m-3"
          type="button"
          data-toggle="collapse"
          data-target="#sideNavbar"
          aria-controls="sideNavbar"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="aha-icon-hamburger-round" />
        </button>
        <div className="collapse navbar-collapse" id="sideNavbar">
          <ul className="w-100 p-0 ul-list">
            <li>
              <NavLink
                exact
                to={`/project/${projectId}`}
                className="nav-sidebar"
                activeClassName="nav-sidebar-active"
              >
                Project Details
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to={`/teammember/${projectId}`}
                className="nav-sidebar"
                activeClassName="nav-sidebar-active"
              >
                Team Members
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to={`/invoice/${projectId}`}
                className="nav-sidebar"
                activeClassName="nav-sidebar-active"
              >
                Invoice
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default ShopCPRMenu;
