/* eslint-disable no-debugger, no-console */
import React from "react";
import PropTypes from "prop-types";
import { Route, useHistory, RouteProps } from "react-router-dom";
import { UserManager } from "oidc-client";
import AdminLayout from "../pages/_layouts/admin";
import AuthLayout from "../pages/_layouts/auth";
import DefaultLayout from "../pages/_layouts/default";
import OidcSettings from "./OidcSettings";

const Global: any = global;
function onUserLoaded(user: any) {
  console.log(user);
}
function onUserUnloaded() {
  console.log("User unloaded");
}

const userManager = new UserManager(OidcSettings);
Global.userManager = userManager;
userManager.events.addUserLoaded(onUserLoaded);
userManager.events.addUserUnloaded(onUserUnloaded);

interface PrivateRouteProps extends RouteProps {
  // tslint:disable-next-line:no-any
  component: any;
  isSignedIn?: boolean;
  isPrivate?: boolean;
  hideHeader?: boolean;
  adminRoute?: boolean;
  hospitalRoute?: boolean;
  protectedRoute?: boolean;
}

export default function RouteWrapper({
  component: Component,
  isPrivate,
  adminRoute,
  protectedRoute,
  hospitalRoute,
  hideHeader,
  ...rest
}: PrivateRouteProps) {
  const history = useHistory();
  const checkRoleAndRedirect = async () => {
    console.log("user redirected to the proper route");
  };
  userManager
    .getUser()
    .then((user) => {
      if (user !== null && user !== undefined) {
        onUserLoaded(user);
        checkRoleAndRedirect();
      } else if (window.location.href.includes("#id_token")) {
        userManager
          .signinRedirectCallback()
          .then(() => {
            window.history.replaceState({}, "", "/");
          })
          .catch((err) => {
            console.error(err);
          });
      } else if (isPrivate) {
        history.push("/");
      }
    })
    .catch((error) => {
      console.error("error inside catch block :: ", error);
    });
  let Layout = DefaultLayout;
  if (hideHeader) {
    Layout = AuthLayout;
  } else if (adminRoute) {
    Layout = AdminLayout;
  }

  /**
   * If not included on both previous cases, redirect user to the desired route.
   */
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
  hideHeader: false,
  adminRoute: false,
  hospitalRoute: false,
  protectedRoute: false,
  isSignedIn: false,
};
