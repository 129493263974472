import React, { useState, useEffect } from "react";
import { Redirect, useLocation } from "react-router-dom";
import BillingHistory from "components/BillingHistory/BillingHistory";
import { parseJwt } from "utils";
import getTeamMembers from "api/teamMembersAPI";
import employeeSalary from "api/getEmployeeSalaryAPI";
import getProjects from "api/getProjectAPI";
import { getEmployees } from "api/getEmployees";
import RecentProjectList from "../../components/RecentProjectList";
import SummaryBar from "../../components/SummaryBar/index";
import { IPagination } from "../../components/Pagination/index";
import projectsInfo from "../../api/getProjectsInfo";
import { showToast } from "../../components/Toast/toast.slice";
import store from "../../app/store";
import getCompletedProjects from "../../api/getCompletedProjects";
import "./styled.scss";
import { Wrapper } from "../../styled";
import Header from "../../components/Header";
import Navigator from "../../components/Navigator";
import Footer from "../../components/Footer";
import DoughnutChart from "../../components/DoughnutChart";
import BarChart from "../../components/BarChart";

interface SalaryResponse {
  data: {
    MonthlySalary: number;
  };
}

type Project = {
  id: number;
  project_name: string;
  project_code: string;
};

type TeamMember = {
  utilization: string;
  id: number;
  employeeId: number;
  projectTeam: string;
  roleName: string[];
  employee: {
    fullName: string;
  };
};

type ProjectResult = {
  projectName: any;
  totalSalary: number;
  perHeadBilling: number;
  directCost: number;
};

const Dashboard = () => {
  const [directCost, setDirectCost] = useState<number>(0);
  const [perHeadBilling, setPerHeadBilling] = useState<number>(0);
  const [projectData, setProjectData] = useState<Project[]>([]);
  const [chartsData, setChartData] = useState<{
    category: string[];
    xData: string[];
    yData: number[][];
  }>({
    category: ["Per Head Billing($)", "Direct Cost($)"],
    xData: [],
    yData: [[], []],
  });
  const [chartedData, setChartedData] = useState<{
    legendData: string[];
    data: { name: string; value: number }[];
  }>({
    legendData: ["Billable", "Not-Billable"],
    data: [],
  });

  useEffect(() => {
    getProjects().then(async (projectsResponse) => {
      setProjectData(projectsResponse.data.projects);

      const projectPromises = projectsResponse.data.projects.map(
        async (project: Project) => {
          try {
            const teamMembersResponse = await getTeamMembers(project.id, 1, 15);
            const employeeIds = teamMembersResponse.data.teamMembersDetails.map(
              (member: TeamMember) => member.employeeId
            );

            const salaryResponses = await Promise.all<SalaryResponse>(
              employeeIds.map((employeeId: any) => employeeSalary(employeeId))
            );
            const totalSalary = salaryResponses.reduce(
              (total, salaryResponse) =>
                total + salaryResponse.data.MonthlySalary,
              0
            );

            let perHeadBillingForProject = 0;
            let directCostForProject = 0;

            if (teamMembersResponse.data._pagination.totalCount > 0) {
              perHeadBillingForProject =
                totalSalary / teamMembersResponse.data._pagination.totalCount;
              directCostForProject = totalSalary;
            }

            setChartData((prevData) => ({
              ...prevData,
              xData: [...prevData.xData, project.project_name],
              yData: [
                [...prevData.yData[0], perHeadBillingForProject],
                [...prevData.yData[1], directCostForProject],
              ],
            }));

            return {
              projectName: project.project_name,
              perHeadBilling: perHeadBillingForProject,
              directCost: directCostForProject,
            };
          } catch (error) {
            if (error instanceof Error) {
              return {
                projectName: project.project_name,
                perHeadBilling: 0,
                directCost: 0,
                totalSalary: 0,
                error: error.message,
              };
            }

            console.error("Unknown error type:", error);
            return {
              projectName: project.project_name,
              perHeadBilling: 0,
              directCost: 0,
              totalSalary: 0,
              error: "Unknown error",
            };
          }
        }
      );

      Promise.all(projectPromises as Promise<ProjectResult>[])
        .then((projectResults: ProjectResult[]) => {})
        .catch((error) => {
          if (error instanceof Error) {
            console.error(error.message);
          } else {
            console.error("unknown error type:", error);
          }
        });
    });

    getEmployees()
      .then((employeesResponse) => {
        const totalEmployees = employeesResponse.data.employees.length;
        const billableEmployees = employeesResponse.data.employees.filter(
          (employee: any) => employee.is_billable
        ).length;
        const billablePercentage = (billableEmployees / totalEmployees) * 100;
        const nonBillablePercentage = 100 - billablePercentage;

        const chartData = [
          { name: "Billable", value: billablePercentage },
          { name: "Non-Billable", value: nonBillablePercentage },
        ];
        setChartedData((prevData) => ({
          ...prevData,
          legendData: ["Billable", "Non-Billable"],
          data: chartData,
        }));
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const ForecastData = {
    category: ["Actual Revenue", "Revenue Variance"],
    xData: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    yData: [
      [39, 20, 58, 15, 11, 38, 28, 38, 19, 51],
      [26, 29, 45, 34, 49, 29, 40, 50, 39, 30],
    ],
  };

  if (!localStorage.userAccessToken) return <Redirect to="/" />;
  const location = useLocation();
  const [project, setProject] = useState<any>([]);
  const [completedProjects, setCompletedProjects] = useState("");
  const [inProgressProjects, setInProgressProjects] = useState("");
  const [pageNumber, setPageNumber] = useState<number>(
    location?.state?.state.backToPage ? location?.state?.state.backToPage : 1
  );
  const [totalCount, setTotalCount] = useState();
  const paginationSetLimit = 5;
  const defaultPagination: IPagination = {
    pageNumber: 1,
    pageSize: 6,
    totalCount: 7,
    totalPages: 5,
    isFirst: true,
    isLast: false,
    setPageNumber,
    paginationSetLimit,
  };
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<IPagination>(defaultPagination);
  useEffect(() => {
    projectsInfo(pageNumber, defaultPagination.pageSize)
      .then((employeeResponse) => {
        setProject(employeeResponse.data.project);
        setPagination({ ...employeeResponse.data._pagination, setPageNumber });
        setTotalCount(employeeResponse.data._pagination.totalCount);
        setLoading(true);
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  }, [pageNumber]);
  const page = {
    defaultPagination,
    pagination,
    pageNumber,
    totalCount,
  };
  useEffect(() => {
    getCompletedProjects()
      .then((employeeResponse) => {
        setCompletedProjects(employeeResponse.data.projectsCompleted);
        setInProgressProjects(employeeResponse.data.inProgress);
        setLoading(true);
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  }, []);
  return (
    <Wrapper className="d-flex flex-column">
      <Header />
      <Navigator />
      <main className="d-flex flex-column flex-grow-1" id="maincontent">
        <div className="container-fluid ">
          <div className="d-flex row">
            <div className="col">
              <h1 className="h3 font-400 dashboardtitle ">Dashboard</h1>
            </div>
          </div>
          <div className="d-flex row mt-3">
            <div className="d-flex mb-2 mb-lg-0  col-lg-3 col-md-6 col-sm-6 col-xs-12">
              <SummaryBar
                classProp="totalproject"
                title="Total Number of Projects"
                image="/images/graph.png"
                number={totalCount}
              />
            </div>
            <div className="d-flex  mb-2 mb-lg-0  col-lg-3 col-md-6 col-sm-6 col-xs-12">
              <SummaryBar
                classProp="activeproject"
                title="Active Projects"
                image="/images/increase.png"
                number={inProgressProjects}
              />
            </div>
            <div className="d-flex  mb-2 mb-lg-0  col-lg-3 col-md-6 col-sm-6 col-xs-12">
              <SummaryBar
                classProp="upcomingproject"
                title="Upcoming Projects"
                image="/images/process.png"
                number={0}
              />
            </div>
            <div className="d-flex  mb-2 mb-lg-0   col-lg-3 col-md-6 col-sm-6 col-xs-12">
              <SummaryBar
                classProp="completedprojects"
                title="Completed Projects"
                number={completedProjects}
                image="/images/completed.png"
              />
            </div>
          </div>

          <div className="row mt-4">
            <div className="container-fluid d-flex justify-content-center">
              <div className="d-flex mb-2 mb-lg-0 col-lg-9 col-md-6 col-sm-12">
                <div className="card-d">
                  <div className="card-d-header">Forecasting Report</div>
                  <div className="card-d-body">
                    <BarChart data={ForecastData} />
                  </div>
                </div>
              </div>
              <div className="d-flex mb-2 mb-lg-0 col-lg-3 col-md-6 col-sm-12">
                <RecentProjectList />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="container-fluid d-flex justify-content-center">
              <div className="col-3">
                <div className="card">
                  <div className="card-header">Invoices</div>
                  <div className="card-body">
                    <DoughnutChart
                      legendData={chartedData.legendData}
                      data={chartedData.data}
                    />
                  </div>
                </div>
              </div>
              <div className="col-9">
                <div className="card-d">
                  <div className="card-d-header">Employee Utilization</div>
                  <div className="card-d-body">
                    <BarChart data={chartsData} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <BillingHistory />
          </div>
        </div>
      </main>
      <Footer />
    </Wrapper>
  );
};

export default Dashboard;
