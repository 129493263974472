/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

export const EmployeeManagementWrapper = styled.div`
  .emp-mgmt-title {
    border-bottom: 2px solid #c10e21;
    padding: 20px 0;
    margin-bottom: 40px;
  }

  .dropdown-toggle::after {
    display: none;
  }
  .heading-gross {
    border-bottom: 2px solid #c10e21;
    border-radius: 0;
  }
  .btn-style .form-control {
    height: 30px;
  }
  .emp-heading {
    background: #c10e21 0% 0% no-repeat padding-box;
    margin-bottom: 36px;
    color: #fff;
    padding: 8px 21px;
  }
  .nav-sidebar-active {
    background-color: #c10e21;
    color: #fff;
  }
  .actionstyle {
    text-align: left;
    @media only screen and (min-width: 992px) {
      text-align: center;
    }
  }
  .active-menu {
    border-bottom: 3px solid #c10e21 !important;
    color: #c10e21 !important;
  }
  .total-emp {
    border-radius: 50%;
    background-color: #fff;
    color: #222328;
    padding: 0 15px;
    font-size: 24px;
    border: 1px solid #707070;
    line-height: 70px;
    min-width: 70px;
    display: inline-block;
    text-align: center;
    @media only screen and (min-width: 768px) {
      line-height: 86px;
      min-width: 88px;
      font-size: 30px;
    }
  }

  .employeelist-table {
    thead {
      @media only screen and (min-width: 992px) {
        th:nth-child(1) {
          width: 10%;
        }
        th:nth-child(2) {
          width: 16%;
        }
        th:nth-child(3) {
          width: 16%;
        }
        th:nth-child(4) {
          width: 16%;
        }
        th:nth-child(5) {
          width: 14%;
        }
        th:nth-child(6) {
          width: 14%;
        }
      }
      @media only screen and (min-width: 1200px) {
        th:nth-child(1) {
          width: 7%;
        }
        th:nth-child(2) {
          width: 16%;
        }
        th:nth-child(3) {
          width: 12%;
        }
        th:nth-child(4) {
          width: 17%;
        }
        th:nth-child(5) {
          width: 11%;
        }
        th:nth-child(6) {
          width: 18%;
        }
      }
    }
  }

  .employee-hr-table {
    thead {
      @media only screen and (min-width: 992px) {
        th:nth-child(1) {
          width: 10%;
        }
        th:nth-child(2) {
          width: 18%;
        }
        th:nth-child(3) {
          width: 16%;
        }
        th:nth-child(4) {
          width: 16%;
        }
        th:nth-child(5) {
          width: 24%;
        }
        th:nth-child(6) {
          width: 22%;
        }
      }
      @media only screen and (min-width: 1200px) {
        th:nth-child(1) {
          width: 7%;
        }
        th:nth-child(2) {
          width: 18%;
        }
        th:nth-child(3) {
          width: 12%;
        }
        th:nth-child(4) {
          width: 17%;
        }
        th:nth-child(5) {
          width: 24%;
        }
        th:nth-child(6) {
          width: 22%;
        }
      }
    }
  }

  .deactivate-employeelist-table {
    thead {
      @media only screen and (min-width: 992px) {
        th:nth-child(1) {
          width: 10%;
        }
        th:nth-child(2) {
          width: 16%;
        }
        th:nth-child(3) {
          width: 16%;
        }
        th:nth-child(4) {
          width: 16%;
        }
        th:nth-child(5) {
          width: 14%;
        }
        th:nth-child(6) {
          width: 14%;
        }
      }
      @media only screen and (min-width: 1200px) {
        th:nth-child(1) {
          width: 9%;
        }
        th:nth-child(2) {
          width: 11%;
        }
        th:nth-child(3) {
          width: 12%;
        }
        th:nth-child(4) {
          width: 12%;
        }
        th:nth-child(5) {
          width: 9%;
        }
        th:nth-child(6) {
          width: 16%;
        }
      }
    }
  }
  .btn.btn-link:hover {
    border-bottom-color: transparent;
  }
  .btn-username {
    font-size: 14px;
    font-weight: 600;
    padding: 0px;
  }
  .btn {
    transition: none;
  }
  .cst-select-fld.cst-multiselect-fld {
    border: 1px solid #777979;
  }
  .button-transition {
    padding-left: 0;
    justify-content: left;
    @media only screen and (min-width: 1200px) {
      padding: 0.5rem 2.5rem;
      justify-content: center;
    }
  }
  .emp-mgt-table {
    width: 100%;
    margin-left: 0;
    @media only screen and (min-width: 992px) {
      width: 70%;
      margin-left: 30px;
    }
  }
  .dropdown-item:hover,
  .dropdown-item:focus {
    background-color: #c10e21;
    color: #fff;
  }
  .ul-list li a {
    font-size: 18px;
    border-bottom: 1px solid #e3e3e3;
  }
  .searchicon{
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .aui-responsive-table tbody tr td {
    vertical-align: middle;
  }
  .pagination-bottom {
    padding-bottom: 109px;
  }
  .line {
    border-bottom: 1px solid #e3e3e3;
    @media only screen and (min-width: 768px) {
      padding: 30px 0;
    }
  }
  .btnwidth {
    width: 210px;
  }
  .btnstyle {
    padding-top: 100px;
  }
  .settings {
    color: #000;
    cursor: pointer;
    z-index: 1;
    right: 0;
    font-size: 20px;
  }
  ul.dropdown-menu.p-lg-0.show {
    left: -40px !important;
    top: 3px !important;
  }
  ul.dropdown-menu li {
    border-bottom: 1px solid #e3e3e3;
  }
  .aui-responsive-table.aui-table-cols tr {
    border-bottom: none;
  }
  a.dropdown-item.settings-item:active {
    color: #000;
    background-color: #fff;
  }
  .userstyle {
    right: 30px;
    top: 0;
  }
  .userstyle img {
    border-radius: 50%;
    height: 60px;
    width: 60px;
    @media only screen and (min-width: 768px) {
      height: 80px;
      width: 80px;
    }
  }
  .util {
    right: 100px;
    top: 16px;
    @media only screen and (min-width: 768px) {
      right: 118px;
      top: 30px;
    }
  }
  .util span {
    font-size: 12px;
    background-color: #fff;
    color: #000;
    border-radius: 50%;
    padding: 11px 5px;
    border: 1px solid #707070;
    @media only screen and (min-width: 768px) {
      font-size: 15px;
    }
  }
  .bordernone {
    border: none;
  }
  .font-grey {
    color: #a3a3a3 !important;
  }
  .foldstructure {
    position: absolute;
    width: 56px;
    height: 82px;
    top: 0;
    right: -2px;
    overflow: hidden;
    z-index: 1;
  }
  .foldstructure:before {
    content: "";
    position: absolute;
    top: -18px;
    right: 14px;
    border-width: 0px 27px 88px 0px;
    border-style: solid;
    border-color: rgb(255, 255, 255) rgb(255, 255, 255) rgba(109, 4, 15, 1)
      rgba(109, 4, 15, 1);
    background: rgba(109, 4, 15, 1);
    display: block;
    width: 0px;
    transform: rotate(-24deg) skewY(-35deg);
  }
  .foldstructure:after {
    content: "";
    position: absolute;
    top: -30px;
    right: -11px;
    width: 46px;
    height: 79px;
    background-color: rgb(255, 255, 255);
    transform: rotate(-45deg) skewY(-11deg);
  }
  .bggrey {
    background-color: #e3e3e3;
  }
  .bordernone {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }
  .sort-icon:before {
    font-size: 7px !important;
    padding-bottom: 8px !important;
  }
  .sort-style {
    display: block !important;
  }
  .form-control.input-style {
    border: none !important;
  }
  .form-control.input-style:focus {
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
  }
  #cellvaluelist {
    font-size: 20px;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  #cellvaluelist > li {
    list-style-type: none;
    text-align: left;
    border-bottom: 2px solid #f5f5f5;
  }
  #cellvaluelist > li:last-child {
    border: none;
  }
  #cellvaluelist > li a {
    text-decoration: none;
    display: block;
    width: 150px;
    -webkit-transition: font-size 0.3s ease, background-color 0.3s ease;
    -moz-transition: font-size 0.3s ease, background-color 0.3s ease;
    -o-transition: font-size 0.3s ease, background-color 0.3s ease;
    -ms-transition: font-size 0.3s ease, background-color 0.3s ease;
    transition: font-size 0.3s ease, background-color 0.3s ease;
  }
  .util_hover {
    font-size: 16px;
    font-weight: normal;
    text-align: left;
  }
  .util_hover:hover .utiltooltip_ul_list {
    display: block;
    width: 300px;
    height: 250px;
    @media only screen and (min-width: 1200px) {
      width: 300px;
    }
  }
  .utiltooltip_ul_list {
    display: none;
    position: absolute;
    z-index: 1000;
    left: 12%;
    @media only screen and (min-width: 768px) {
      left: -76%;
    }
    @media only screen and (min-width: 2000px) {
      left: -48%;
    }
    @media only screen and (max-width: 1080px) and (min-width: 1920px) {
      left: -30;
    @media only screen and (min-width: 1200px) {
      left: -31%;
    }
  }
  .utiltooltip_ul_list,
  .utiltooltip_li_list {
    list-style-type: none;
    float: left;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  .utiltooltip_li_list {
    background: #ffffff;
    border: 1px solid #e3e3e3;
    padding: 20px;
  }
  .utiltooltip_img {
    width: 50px;
    height: 50px;
    float: left;
    padding: 5px;
  }
  .utiltooltiplistspan {
    display: inline-block;
  }
  .headingstyle {
    border-bottom: 2px solid #c10e21;
    text-align: left;
    padding-bottom: 10px;
  }
  ul#cellvaluelist:hover {
    color: #c10e21 !important;
    text-decoration: underline;
  }
  .util_hover span:hover {
    color: #c10e21;
    text-decoration: underline;
    font-weight: bold;
  }
  .aui-search-dropdown.dropdown-style {
    max-width: 100%;
  }
  .aha-icon-arrow-down:before {
    font-size: 0.6rem !important;
    color: #696969 !important;
  }
  .aui-search-dropdown .aui-downicon:before {
    font-size: 1rem;
    padding-top: 6px;
  }
  .spaceeven {
    justify-content: space-between;
  }
  .aui-search-dropdown .aui-input-div.show i:before {
    transform: rotate(0deg) !important;
  }
  .teccenterstyle {
    text-align: left;
    @media only screen and (min-width: 992px) {
      justify-content: center;
    }
  }
  .txtleft {
    text-align: left;
  }
  .aui-accordion-tab1{
    zIndex: -1;
  }
  
  .aha-icon-cross{
    top: 10px;
    right: 0px;
}
.aha-icon-search{
    top: 10px;
    right: 0px;
}
.validateError{
  color: "red",
  fontSize: "0.8rem",
  paddingTop: "5px",
  paddingLeft: "5px",
}
`;
