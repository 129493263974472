const CryptoJS = require("crypto-js");

export default async function generateUserKeys(n) {
  const userKeys = await Promise.all(
    Array.from({ length: n }, generateUserKey)
  );
  return userKeys;
}

async function generateUserKey() {
  try {
    const userKey = CryptoJS.lib.WordArray.random(12).toString(
      CryptoJS.enc.Hex
    );
    return userKey;
  } catch (error) {
    return null;
  }
}
