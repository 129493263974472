import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";

interface BarChartProps {
  data: { category: string[]; xData: string[]; yData: number[][] };
}

const BarChart: React.FC<BarChartProps> = ({ data }) => {
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chartRef.current) {
      const chart = echarts.init(chartRef.current);
      const option = {
        tooltip: {
          trigger: "axis",
          show: true,
        },
        legend: {
          data: data.category,
          textStyle: {
            fontWeight: "bold",
          },
          top: "0",
          orient: "horizontal",
          itemGap: 30,
          itemWidth: 20,
          itemHeight: 15,
        },
        xAxis: {
          data: data.xData,
          axisLabel: {
            rotate: 45,
          },
        },
        yAxis: {},
        series: data.category.map((category, index) => ({
          name: category,
          type: "bar",
          data: data.yData[index].flat(),
        })),
      };

      chart.setOption(option);
    }
  }, [data]);

  return <div ref={chartRef} style={{ width: "100%", height: "400px" }} />;
};

export default BarChart;
