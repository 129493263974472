/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react";
import { showToast } from "components/Toast/toast.slice";
import projectsInfo from "api/getProjectsInfo";
import store from "app/store";
import { IPagination } from "components/Pagination";
import { Redirect } from "react-router-dom";
import getCompletedProjects from "api/getCompletedProjects";
import Navigator from "../../components/Navigator";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { ProjectManagementMenu } from "./ProjectManagementMenu";
import { ProjectManagementWrapper } from "./styled";
import ProjectManagementForms from "./ProjectManagementForm";

const AddNewProject = () => {
  if (!localStorage.userAccessToken) return <Redirect to="/" />;
  const [project, setProject] = useState<any>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalCount, setTotalCount] = useState();
  const [completedProjects, setCompletedProjects] = useState("");
  const [inProgressProjects, setInProgressProjects] = useState("");
  const paginationSetLimit = 5;
  const [projectForm, setprojectForm] = useState({
    projectName: "",
    projectCode: "",
    sow: "",
    projectStartDate: "",
    plannedEndDate: "",
    actualEndDate: "",
    projectManager: "",
    ahaManager: "",
    ahamanagerEmailid: "",
    businessContact: "",
    description: "",
    teamSize: "",
    serviceType: "",
    logoUrl: "",
    techstackDescription: "",
    productOwner: "",
    deliveryManager: "",
    applicationArchitect: "",
    technicalManager: "",
    solutionArchitect: "",
    scrumMaster: "",
  });
  const defaultPagination: IPagination = {
    pageNumber: 1,
    pageSize: 6,
    totalCount: 6,
    totalPages: 5,
    isFirst: true,
    isLast: false,
    setPageNumber,
    paginationSetLimit,
  };
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<IPagination>(defaultPagination);
  useEffect(() => {
    projectsInfo(pageNumber, defaultPagination.pageSize)
      .then((employeeResponse) => {
        setProject(employeeResponse.data.project);
        setPagination({ ...employeeResponse.data._pagination, setPageNumber });
        setTotalCount(employeeResponse.data._pagination.totalCount);
        setLoading(true);
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  }, [pageNumber]);
  useEffect(() => {
    getCompletedProjects()
      .then((employeeResponse) => {
        setCompletedProjects(employeeResponse.data.projectsCompleted);
        setInProgressProjects(employeeResponse.data.inProgress);
        setLoading(true);
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  }, []);
  return (
    <div className="d-flex flex-column">
      <Navigator isActive="project-list" />
      <div className="d-flex flex-column flex-grow-1 ">
        <ProjectManagementWrapper className="container-fluid">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb aha-breadcrumb pl-0">
              <li className="breadcrumb-item">
                <a href="/project-list">Project List</a>
              </li>
              <li className="breadcrumb-item active">
                <a href="/addnewproject">Add New Project</a>
              </li>
            </ol>
          </nav>
          <h1 className="h3 font-400 proj-mgmt-title d-flex">
            Project Management
          </h1>
          <div className="d-lg-flex">
            <ProjectManagementMenu />
            {loading ? (
              <div className="proj-mgt-table flex-grow-1">
                <div
                  className="proj-heading justify-content-between align-items-center"
                  aria-label="Add new project"
                >
                  <h2 className="h3 font-400 mb-0">Add new project</h2>
                  <div className="d-md-flex py-2">
                    <span className="d-flex">
                      Number of Projects: {totalCount}
                    </span>
                    <span className="pl-md-4">
                      Completed Projects: {completedProjects}
                    </span>
                    <span className="d-flex pl-md-4">
                      In Progress: {inProgressProjects}
                    </span>
                  </div>
                </div>
                <ProjectManagementForms project={projectForm} />
              </div>
            ) : (
              <table
                className="aui-responsive-table aui-table-cols proj-mgt-table aui-table-loader"
                role="alert"
                aria-live="assertive"
                aria-label="Form is Loading"
              />
            )}
          </div>
        </ProjectManagementWrapper>
      </div>
    </div>
  );
};

export default AddNewProject;
