import React, { useEffect, useState } from "react";
import CONSTANTS from "common/constants";
import { NavLink } from "react-router-dom";
import { parseJwt } from "utils";
import { use } from "../../../amplify/backend/function/invoiceaccounts/src/app";

export const EmployeeManagementMenu = () => {
  const [userRoles, setUserRoles] = useState<any>();
  const userToken = localStorage.userAccessToken;
  const tokenPayload = parseJwt(userToken).parsedJWT;
  const { userRole } = tokenPayload;
  useEffect(() => {
    setUserRoles(userRole);
  });
  return (
    <div className="aui-sidenav">
      <div className="navbar-expand-lg overflow-hidden">
        <button
          className="navbar-toggler float-right m-3"
          type="button"
          data-toggle="collapse"
          data-target="#sideNavbar"
          aria-controls="sideNavbar"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="aha-icon-hamburger-round" />
        </button>
        <div className="collapse navbar-collapse" id="sideNavbar">
          <ul className="w-100 p-0 ul-list" role="menu">
            <li role="menuitem">
              <NavLink
                exact
                to="/employee-management"
                className="nav-sidebar"
                activeClassName="nav-sidebar-active"
              >
                Employee List
              </NavLink>
            </li>
            {(userRoles === CONSTANTS.ROLES.ADMIN ||
              userRoles === CONSTANTS.ROLES.SUPERADMIN) && (
              <li role="menuitem">
                <NavLink
                  exact
                  to="/addnewemployee"
                  className="nav-sidebar"
                  activeClassName="nav-sidebar-active"
                >
                  Add New Employee
                </NavLink>
              </li>
            )}
            <li role="menuitem">
              <NavLink
                exact
                to="/deactivatedlist"
                className="nav-sidebar"
                activeClassName="nav-sidebar-active"
              >
                Deactivated List
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default EmployeeManagementMenu;
