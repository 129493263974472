import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { parseJwt } from "utils";
import CONSTANTS from "common/constants";
import NavigatorWrapper from "./styled";

const Navigator = (props: any) => {
  const [userRoles, setUserRoles] = useState<any>();
  const userToken = localStorage.userAccessToken;
  const tokenPayload = parseJwt(userToken).parsedJWT;
  const { userRole } = tokenPayload;
  useEffect(() => {
    setUserRoles(userRole);
  });

  const Props = props;
  return (
    <NavigatorWrapper className="aui-global-nav">
      <div className="aui-menu-tabs">
        <div className="container-fluid">
          <div className="navbar-expand-lg">
            <div className="d-flex flex-row-reverse p-2">
              <button
                type="button"
                className="navbar-toggler btn btn-round btn-secondary"
                data-toggle="collapse"
                data-target="#dashboardItems"
                aria-controls="navbarDashboard"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                Menu
              </button>
            </div>
            <div id="navbarDashboard">
              <ul
                id="dashboardItems"
                className="collapse navbar-collapse m-0 aui-global-menu mt-3 mt-lg-0"
                role="menu"
              >
                {(userRoles === CONSTANTS.ROLES.ADMIN ||
                  userRoles === CONSTANTS.ROLES.SUPERADMIN) && (
                  <li role="menuitem">
                    <NavLink
                      className="btn btn-text"
                      exact
                      activeClassName="active-menu"
                      to="/Dashboard"
                    >
                      Dashboard
                    </NavLink>
                  </li>
                )}
                {(userRoles === CONSTANTS.ROLES.ADMIN ||
                  userRoles === CONSTANTS.ROLES.SUPERADMIN ||
                  userRoles === CONSTANTS.ROLES.HR) && (
                  <li role="menuitem">
                    <NavLink
                      className={
                        Props.isActive === "employee-management"
                          ? "btn btn-text active-menu"
                          : "btn btn-text"
                      }
                      exact
                      activeClassName="active-menu"
                      to="/employee-management"
                    >
                      Employee Management
                    </NavLink>
                  </li>
                )}
                {(userRoles === CONSTANTS.ROLES.ADMIN ||
                  userRoles === CONSTANTS.ROLES.SUPERADMIN) && (
                  <li role="menuitem">
                    <NavLink
                      className={
                        Props.isActive === "project-list"
                          ? "btn btn-text active-menu"
                          : "btn btn-text"
                      }
                      exact
                      activeClassName="active-menu"
                      to="/project-list"
                    >
                      Project Management
                    </NavLink>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </NavigatorWrapper>
  );
};
Navigator.defaultProps = {
  isActive: "",
};
export default Navigator;
