import React, { useEffect, useState } from "react";
// import EmployeeManagement from "./EmployeeManagementPage";
import CONSTANTS from "common/constants";
import useCopyToClipboard from "customHooks/useCopyToClipboard";
import { parseJwt } from "utils";

interface Props {
  data: any;
}
const EmployeeManagementUserProfileForm = (props: Props) => {
  const { data } = props;
  const [value, copy] = useCopyToClipboard();
  const [isPasswordClicked, setisPasswordClicked] = useState(false);
  const [userRoles, setUserRoles] = useState<any>();
  const userToken = localStorage.userAccessToken;
  const tokenPayload = parseJwt(userToken).parsedJWT;
  const { userRole } = tokenPayload;
  useEffect(() => {
    setUserRoles(userRole);
  });
  const toggleshow = (event: any) => {
    if (event.currentTarget.innerHTML === "Show") {
      event.currentTarget.innerHTML = "Hide";
      event.currentTarget.nextSibling.classList.remove("d-none");
      event.currentTarget.setAttribute("aria-expanded", "true");
    } else {
      event.currentTarget.innerHTML = "Show";
      event.currentTarget.nextSibling.classList.add("d-none");
      event.currentTarget.setAttribute("aria-expanded", "false");
    }
  };
  const handleCodeCopied = (code: any) => {
    copy(code);
    setisPasswordClicked(true);
  };
  const handleCopy = (password: any) => {
    return handleCodeCopied(password);
  };
  const dbDate = new Date(data.vendorCostExpiryDate);
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  return (
    <table
      className="aui-responsive-table aui-table-cols mb-5"
      aria-label="Details of User"
    >
      <tbody>
        <tr>
          <th scope="row" className="aui-row-th">
            Employee ID
          </th>
          <td className="aui-row-td">{data.employeeId}</td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            Full Name
          </th>
          <td className="aui-row-td">{data.fullName}</td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            AHA Email
          </th>
          <td className="aui-row-td">{data.ahaEmail}</td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            Impelsys Email ID
          </th>
          <td className="aui-row-td">{data.impelsysEmail}</td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            Phone
          </th>
          <td className="aui-row-td">{data.phone}</td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            Team
          </th>
          <td className="aui-row-td">
            {data.teamDetails && data.teamDetails.join(", ")
              ? data.teamDetails && data.teamDetails.join(", ")
              : "NA"}
          </td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            Work Categorization
          </th>
          <td className="aui-row-td">{data.workCategorization}</td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            Sub-team
          </th>
          <td className="aui-row-td">
            {data.subteam && data.subteam.join(", ")
              ? data.subteam && data.subteam.join(", ")
              : "NA"}
          </td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            Designation
          </th>
          <td className="aui-row-td">
            {data.designationDetails && data.designationDetails.join(", ")
              ? data.designationDetails && data.designationDetails.join(", ")
              : "NA"}
          </td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            Joining Date Impelsys
          </th>
          <td className="aui-row-td">
            {data.impelsysjoinDate && data.impelsysjoinDate.slice(0, 10)}
          </td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            Joining Date Heart Bangalore
          </th>
          <td className="aui-row-td">
            {data.ahajoinDate && data.ahajoinDate.slice(0, 10)}
          </td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            Role
          </th>
          <td className="aui-row-td">{data.role && data.role.join(", ")}</td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            Primary Skills
          </th>
          <td className="aui-row-td">
            {data.primarySkills && data.primarySkills.join(", ")}
          </td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            Secondary Skills
          </th>
          <td className="aui-row-td">
            {data.secondarySkills && data.secondarySkills.join(", ")}
          </td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            Reporting Mananger
          </th>
          <td className="aui-row-td">{data.reportingManager}</td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            Employee Type
          </th>
          <td className="aui-row-td">{data.employeeType}</td>
        </tr>
        {data.employeeType === "Contract" ? (
          <tr>
            <th scope="row" className="aui-row-th">
              Contractor Name
            </th>
            <td className="aui-row-td">{data.contractName}</td>
          </tr>
        ) : null}
        {(userRoles === CONSTANTS.ROLES.ADMIN ||
          userRoles === CONSTANTS.ROLES.SUPERADMIN) && (
          <tr>
            <th scope="row" className="aui-row-th">
              Total Monthly Cost (₹)
            </th>
            <td className="aui-row-td">
              {dbDate.getTime() >= currentDate.getTime()
                ? (data.salary / 12).toFixed(2)
                : ((data.salary - data.vendorCost) / 12).toFixed(2)}
            </td>
          </tr>
        )}
        {data.vendorCost && dbDate.getTime() >= currentDate.getTime() ? (
          <tr>
            <th scope="row" className="aui-row-th">
              Vendor Cost Expiry Date
            </th>
            <td className="aui-row-td">
              {data.vendorCostExpiryDate &&
                data.vendorCostExpiryDate.slice(0, 10)}
            </td>
          </tr>
        ) : null}
        <tr>
          <th scope="row" className="aui-row-th">
            Billable
          </th>
          <td className="aui-row-td">{data.isBillable ? "Yes" : "No"}</td>
        </tr>
      </tbody>
    </table>
  );
};
export default EmployeeManagementUserProfileForm;
