/* eslint-disable jsx-a11y/control-has-associated-label */
import employeeActivate from "api/employeeActivate";
import store from "app/store";
import { useHistory } from "react-router-dom";
import CONSTANTS from "common/constants";
import { showToast } from "components/Toast/toast.slice";
import React, { useEffect, useState } from "react";
import { parseJwt } from "utils";
import EmployeeManagement from "./EmployeeManagementPage";

interface Props {
  data: any;
}
const DeactivatedUserProfileForm = (props: Props) => {
  const { data } = props;
  const [userRoles, setUserRoles] = useState<any>();
  const userToken = localStorage.userAccessToken;
  const tokenPayload = parseJwt(userToken).parsedJWT;
  const { userRole } = tokenPayload;
  useEffect(() => {
    setUserRoles(userRole);
  });
  const history = useHistory();
  const activateEmployee = (event: any) => {
    event.preventDefault();
    employeeActivate(data.employeeId)
      .then((employeeResponse) => {
        history.push("/employee-management");
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };
  return (
    <table
      className="aui-responsive-table aui-table-cols mb-5"
      aria-label="Details of User"
    >
      <tbody>
        <tr>
          <th scope="row" className="aui-row-th">
            Employee ID
          </th>
          <td className="aui-row-td">{data.employeeId}</td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            Full Name
          </th>
          <td className="aui-row-td">{data.fullName}</td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            AHA Email
          </th>
          <td className="aui-row-td">{data.ahaEmail}</td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            Impelsys Email ID
          </th>
          <td className="aui-row-td">{data.impelsysEmail}</td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            Phone
          </th>
          <td className="aui-row-td">{data.phone}</td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            Team
          </th>
          <td className="aui-row-td">
            {data.teamDetails && data.teamDetails.join(", ")
              ? data.teamDetails && data.teamDetails.join(", ")
              : "NA"}
          </td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            Work Categorization
          </th>
          <td className="aui-row-td">{data.workCategorization}</td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            Joining Date Impelsys
          </th>
          <td className="aui-row-td">
            {data.impelsysjoinDate && data.impelsysjoinDate.slice(0, 10)}
          </td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            Joining Date Heart Bangalore
          </th>
          <td className="aui-row-td">
            {data.ahajoinDate && data.ahajoinDate.slice(0, 10)}
          </td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            Role
          </th>
          <td className="aui-row-td">{data.role && data.role.join(", ")}</td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            Primary Skills
          </th>
          <td className="aui-row-td">
            {data.primarySkills && data.primarySkills.join(", ")}
          </td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            Secondary Skills
          </th>
          <td className="aui-row-td">
            {data.secondarySkills && data.secondarySkills.join(", ")}
          </td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            Reporting Mananger
          </th>
          <td className="aui-row-td">{data.reportingManager}</td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            Employee Type
          </th>
          <td className="aui-row-td">{data.employeeType}</td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            Contractor Name
          </th>
          <td className="aui-row-td">
            {data.contractName ? data.contractName : "NA"}
          </td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            Salary INR(Monthly Gross)
          </th>
          <td className="aui-row-td">{data.grossSalary}</td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            Salary Effective from Date
          </th>
          <td className="aui-row-td">
            {data.salaryeffectivefromDate &&
              data.salaryeffectivefromDate.slice(0, 10)}
          </td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            Billable
          </th>
          <td className="aui-row-td">{data.isBillable ? "Yes" : "No"}</td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            System Role
          </th>
          <td className="aui-row-td">{data.systemRole}</td>
        </tr>
        <tr>
          <th scope="row" className="aui-row-th">
            Access
          </th>
          <td className="aui-row-td">
            {data.access && data.access.join(", ")}
          </td>
        </tr>
        <tr>
          <th />
          <td className="aui-row-td">
            <div className="pt-4">
              {(userRoles === CONSTANTS.ROLES.ADMIN ||
                userRoles === CONSTANTS.ROLES.SUPERADMIN) && (
                <button
                  type="button"
                  aria-label="submit add new employee form"
                  className="btn btn-round btn-primary btnwidth"
                  data-toggle="modal"
                  data-target="#activateuser"
                >
                  Activate
                </button>
              )}
            </div>
          </td>
        </tr>
        <tr>
          <td className="bordernone">
            <div
              className="modal fade show aui-modal"
              id="activateuser"
              tabIndex={-1}
              aria-labelledby="activateuser"
              aria-modal="true"
              role="dialog"
            >
              <div className="modal-dialog modal-dialog-centered modal-sm">
                <div className="modal-content">
                  <div className="modal-body p-0">
                    <div className="text-center">
                      <p>Are you sure you want to activate this user?</p>
                      <div className="mt-4">
                        <button
                          type="submit"
                          data-dismiss="modal"
                          className="btn btn-primary btn-round btn-sm mx-2"
                          onClick={activateEmployee}
                        >
                          Yes
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary btn-round btn-sm mx-2"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          No
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
export default DeactivatedUserProfileForm;
