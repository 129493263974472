/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from "react";
import store from "app/store";
import { useHistory, Redirect } from "react-router-dom";
import { showToast, successToast } from "components/Toast/toast.slice";

import Navigator from "../../components/Navigator";
import { EmployeeManagementWrapper } from "../EmployeeManagement/styled";
import { SettingsMenu } from "./SettingsMenu";
import updateOrgSettings from "../../api/updateOrgSettingsAPI";
import getOrgSettings from "../../api/getOrgSettingsApi";

const Settings = () => {
  const history = useHistory();
  if (!localStorage.userAccessToken) return <Redirect to="/" />;
  const [loading, setLoading] = useState(false);
  const [workingHours, setWorkingHours] = useState(9);
  const [currency, setCurrency] = useState("INR");
  const [adminCharge, setAdminCharge] = useState(10);
  const [managementCharge, setManagementCharge] = useState(10);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    getOrgSettings().then((resp) => {
      const orgSetting = resp.data.result.settings;
      setWorkingHours(orgSetting.workingHours);
      setCurrency(orgSetting.currency);
      setAdminCharge(orgSetting.adminCharge);
      setManagementCharge(orgSetting.managementCharge);
      setIsError(false);
      setLoading(true);
    });
  }, []);

  const resetSetting = (event: any) => {
    setWorkingHours(9);
    setCurrency("INR");
    setAdminCharge(10);
    setManagementCharge(10);
    setIsError(false);
  };

  const cancelSetting = (event: any) => {
    setIsError(false);
    history.go(-1);
  };

  const handleWorkingHour = (event: any) => {
    setWorkingHours(event.target.value);
    if (event.target.value > 24) {
      setIsError(true);
      const result: any = {};
      result.message = "Please enter a valid working hour.";
      store.dispatch(showToast(result));
    } else {
      setIsError(false);
    }
  };

  const handleCurrency = (event: any) => {
    setCurrency(event.target.value);
  };

  const handleAdminCharge = (event: any) => {
    setAdminCharge(event.target.value);
    if (event.target.value > 100 || event.target.value < 0) {
      setIsError(true);
      const result: any = {};
      result.message = "Please enter valid number.";
      store.dispatch(showToast(result));
    } else {
      setIsError(false);
    }
  };

  const handleManagementCharge = (event: any) => {
    setManagementCharge(event.target.value);
    if (event.target.value > 100 || event.target.value < 0) {
      setIsError(true);
      const result: any = {};
      result.message = "Please enter valid number.";
      store.dispatch(showToast(result));
    } else {
      setIsError(false);
    }
  };
  const successPost = () => {
    const successMessage = `settings successfully added`;
    const isValid = false;
    const result = {
      isValid,
      visibility: !isValid,
      message: successMessage,
    };
    if (!isValid) store.dispatch(successToast(result));
    return result;
  };

  const submitOrgSettings = (event: any) => {
    event.preventDefault();
    const newSettings: any = {};
    newSettings.workingHours = workingHours;
    newSettings.currency = currency;
    newSettings.adminCharge = adminCharge;
    newSettings.managementCharge = managementCharge;

    updateOrgSettings(newSettings)
      .then((settingResponse) => {
        successPost();
        history.go(-1);
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };
  return (
    <div className="d-flex flex-column">
      <Navigator />
      <div className="d-flex flex-column flex-grow-1">
        <EmployeeManagementWrapper className="container-fluid">
          <h1 className="h3 font-400 emp-mgmt-title">Settings</h1>
          {loading ? (
            <div className="d-lg-flex">
              <SettingsMenu />
              <div className="emp-mgt-table flex-grow-1">
                <div className="emp-heading justify-content-between align-items-center d-md-flex">
                  <h2 className="h3 font-400 m-4">Invoice Configuration</h2>
                </div>
                <form autoComplete="off">
                  <div className="form-group row">
                    <label
                      htmlFor="workingHrs"
                      className="col-sm-4 col-form-label"
                    >
                      Working Hours
                    </label>
                    <div className="col-sm-6">
                      <input
                        type="number"
                        className="form-control"
                        value={workingHours}
                        onChange={handleWorkingHour}
                        id="workingHrs"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="currency"
                      className="col-sm-4 col-form-label"
                    >
                      Currency
                    </label>
                    <div className="col-sm-6">
                      <select
                        className="form-control"
                        id="currency"
                        onChange={handleCurrency}
                        defaultValue={currency}
                      >
                        <option value="INR">INR (&#x20B9;) </option>
                        <option value="USD">USD (&#36;)</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group row error">
                    <label
                      className="col-sm-4 col-form-label"
                      htmlFor="adminFee"
                    >
                      Administrative Fee(%)
                    </label>
                    <div className="col-sm-6">
                      <input
                        type="text"
                        className="form-control"
                        value={adminCharge}
                        onChange={handleAdminCharge}
                        id="adminFee"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group row error">
                    <label
                      className="col-sm-4 col-form-label"
                      htmlFor="managementFee"
                    >
                      Management Fee(%)
                    </label>
                    <div className="col-sm-6">
                      <input
                        type="text"
                        className="form-control"
                        value={managementCharge}
                        onChange={handleManagementCharge}
                        id="managementFee"
                        required
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-end pb-5 btnstyle">
                    <button
                      type="button"
                      aria-label="reset settings"
                      className="btn btn-round btn-secondary mr-4"
                      onClick={resetSetting}
                    >
                      Reset
                    </button>
                    <button
                      type="button"
                      aria-label="cancel settings"
                      className="btn btn-round btn-secondary mr-4"
                      onClick={cancelSetting}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      aria-label="save settings"
                      className="btn btn-round btn-primary"
                      onClick={submitOrgSettings}
                      disabled={isError}
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          ) : (
            <div
              className="aui-block-loader proj-mgt-table"
              role="alert"
              aria-live="assertive"
              aria-label="Page is Loading"
            />
          )}
        </EmployeeManagementWrapper>
      </div>
    </div>
  );
};

export default Settings;
