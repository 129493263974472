import React, { Key, useEffect, useRef, useState } from "react";
import * as XLSX from "xlsx";
import "./styled.scss";
import store from "../../app/store";
import { showToast } from "../Toast/toast.slice";
import getBillinghistory from "../../api/getBillingHistoryApi";
import getExistingProjectInvoiceMonths from "../../api/getExistingProjectInvoiceApi";

function getStatusColor(status: string) {
  if (status === "Paid") {
    return "status-paid";
  }
  if (status === "UnPaid") {
    return "status-unpaid";
  }
  if (status === "Draft") {
    return "status-draft";
  }
  return "";
}
function BillingHistory(props: any) {
  const [billName, setbillName] = useState<any>([]);
  const invoiceRef: any = useRef();
  const [projId, setProjId] = useState<any>([]);
  const [status, setStatus] = useState<any>([]);
  const [projectNameFilter, setProjectNameFilter] = useState("");
  const [selectedMonth, setSelectedMonth] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedStartMonth, setSelectedStartMonth] = useState(1);
  const [selectedEndMonth, setSelectedEndMonth] = useState(12);
  const [selectedYear, setSelectedYear] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [downloadClicked, setDownloadClicked] = useState(false);

  const roleRequest = () => {
    getBillinghistory()
      .then((Response) => {
        setbillName(Response.data.Bills);
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };

  const invoiceRequest = async () => {
    try {
      const response = await getExistingProjectInvoiceMonths(projId);
      if (response) {
        invoiceRef.current = response.data.invoiceListObj;
      }
    } catch (error: any) {
      store.dispatch(showToast(error.details[0].message));
    }
  };

  const handleDownload = () => {
    setDownloadClicked(true);

    const workbook = XLSX.utils.book_new();
    let filteredData = [...billName];

    if (projectNameFilter) {
      filteredData = filteredData.filter((item) =>
        item.project_name
          .toLowerCase()
          .includes(projectNameFilter.toLowerCase())
      );
    }

    if (selectedYear > 0 && selectedStartMonth >= 1 && selectedEndMonth <= 12) {
      filteredData = filteredData.filter((item) => {
        return (
          typeof item.month === "number" &&
          typeof item.year === "number" &&
          item.year === selectedYear &&
          item.month >= selectedStartMonth &&
          item.month <= selectedEndMonth
        );
      });
    }
    if (filteredData.length === 0) {
      setShowAlert(true);
      return;
    }

    const worksheet = XLSX.utils.json_to_sheet(filteredData);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Billing History");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = url;
    link.download = "billing_history.xlsx";
    link.click();
    URL.revokeObjectURL(url);

    setShowAlert(false);
  };

  useEffect(() => {
    if (projId) {
      invoiceRequest();
    }
  }, [projId]);

  useEffect(() => {
    roleRequest();
  }, []);

  return (
    <>
      {showAlert && (
        <div
          className="alert alert-danger alert-dismissible fade show"
          role="alert"
        >
          <strong>Sorry!</strong>No Data to Display.
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={() => setShowAlert(false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      )}
      <div className="App">
        <h3 className="Heading">Billing History</h3>
        <div className="Title">
          <div className="filter-container">
            <div>
              <label className="filter-label">Project Name:</label>
              <input
                type="text"
                value={projectNameFilter}
                onChange={(e) => setProjectNameFilter(e.target.value)}
                className="filter-input"
              />
            </div>
            <div>
              <label className="filter-label">Start Month:</label>
              <select
                value={selectedStartMonth}
                onChange={(e) =>
                  setSelectedStartMonth(parseInt(e.target.value, 10))
                }
                className="filter-select"
              >
                {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
                  <option key={month} value={month}>
                    {new Date(0, month - 1).toLocaleString("default", {
                      month: "short",
                    })}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="filter-label">End Month:</label>
              <select
                value={selectedEndMonth}
                onChange={(e) =>
                  setSelectedEndMonth(parseInt(e.target.value, 10))
                }
                className="filter-select"
              >
                {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
                  <option key={month} value={month}>
                    {new Date(0, month - 1).toLocaleString("default", {
                      month: "short",
                    })}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="filter-label">Year:</label>
              <select
                value={selectedYear}
                onChange={(e) => setSelectedYear(parseInt(e.target.value, 10))}
                className="filter-select"
              >
                <option value="">Year</option>
                {Array.from({ length: 21 }, (_, i) => {
                  const year = 2020 + i;
                  return (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <button
            type="button"
            className="btn btn-round btn-primary downloadbtn"
            onClick={handleDownload}
          >
            <i className="aha-icon-download" style={{ marginRight: "8px" }} />
            Download
          </button>
        </div>
        <table className="aui-responsive-status-table">
          <thead>
            <tr>
              <th scope="col">
                <div className="aui-th">Project Name</div>
              </th>

              <th scope="col">
                <div className="aui-th">Invoice ID</div>
              </th>

              <th scope="col">
                <div className="aui-th">Start Date</div>
              </th>

              <th scope="col">
                <div className="aui-th">End Date</div>
              </th>

              <th scope="col">
                <div className="aui-th">Amount ($)</div>
              </th>

              <th scope="col">
                <div className="aui-th">Status</div>
              </th>
            </tr>
          </thead>
          {billName != null ? (
            <tbody>
              {billName &&
                billName.map((item: any, index: any) => (
                  <tr className="aui-table-status-normal" key={item.id}>
                    <td data-title="Project Name">
                      <div className="aui-td">{item.project_name}</div>
                    </td>
                    <td data-title="Invoice ID">
                      <div className="aui-td">{item.id}</div>
                    </td>
                    <td data-title="Start Date">
                      <div className="aui-td">{item.start_date}</div>
                    </td>
                    <td data-title="End Date">
                      <div className="aui-td">{item.actual_end_date}</div>
                    </td>
                    <td data-title="total_cost">
                      <div className="aui-td">
                        ${parseFloat(item.total_cost).toFixed(2)}
                      </div>
                    </td>
                    <td data-title="Status">
                      <div className={`aui-td ${getStatusColor(item.status)}`}>
                        {item.status}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan={7} className="Data">
                  No Data
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
    </>
  );
}

export default BillingHistory;
