import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { parseJwt } from "utils";
import sendInvoiceStatus from "../../api/addStatusApi";
import sendInvoiceStatusPatch from "../../api/updateStatusApi";
import CONSTANTS from "../../common/constants";
import userRoles from "../../../amplify/backend/function/invoiceaccounts/src/models/userRoles";
import RenderInvoiceWrapper from "./styled";

interface Props {
  projectInvoice: any;
  projectProfile: any;
  projectId: any;
}
const InvoiceTable = (props: Props) => {
  const [userRoles, setUserRoles] = useState<any>();
  const userToken = localStorage.userAccessToken;
  const tokenPayload = parseJwt(userToken).parsedJWT;
  const { userRole } = tokenPayload;
  useEffect(() => {
    setUserRoles(userRole);
  });
  const { projectInvoice } = props;
  const { projectId } = props;
  const { projectProfile } = props;
  const history = useHistory();
  const [selectedOptions, setSelectedOptions] = useState<{
    [key: string]: string;
  }>({});
  useEffect(() => {
    const storedOptions = localStorage.getItem("selectedOptions");
    if (storedOptions) {
      setSelectedOptions(JSON.parse(storedOptions));
    }
  }, []);
  useEffect(() => {
    localStorage.setItem("selectedOptions", JSON.stringify(selectedOptions));
  }, [selectedOptions]);

  const handleOptionChange = async (
    event: React.ChangeEvent<HTMLSelectElement>,
    index: number
  ) => {
    const selectedOption = event.target.value;
    setSelectedOptions((prevOptions: any) => ({
      ...prevOptions,
      [index]: selectedOption,
    }));

    try {
      const invoiceData = {
        status: selectedOption,
      };
      const { month } = projectInvoice[index];
      const { year } = projectInvoice[index];
      await sendInvoiceStatusPatch(projectId, invoiceData, month, year);
      window.location.reload();
    } catch (error) {
      console.error("Error sending invoice:", error);
    }
  };
  const renderInvoice = (projectInvoice: any, index: number) => {
    const selectedOption = selectedOptions[index] || projectInvoice.status;
    return (
      <tr key={index}>
        <td data-title="SI">
          <div className="aui-td">{index + 1}</div>
        </td>
        <td data-title="ProjectName">
          <div className="aui-td">{projectProfile.projectName}</div>
        </td>
        <td data-title="Date">
          {userRoles === CONSTANTS.ROLES.ADMIN ||
          userRoles === CONSTANTS.ROLES.SUPERADMIN ? (
            <button
              type="button"
              className="btn aui-td w-auto txtcenter font-red bordernone"
              aria-label="newinvoicebutton"
              onClick={() => {
                projectProfile.projectName === "EDC"
                  ? history.push(
                      `/edcinvoice/${projectId}/${
                        parseInt(projectInvoice.month, 10) + 1
                      }/${projectInvoice.year}/view`
                    )
                  : history.push(
                      `/invoicedate/${projectId}/${
                        parseInt(projectInvoice.month, 10) + 1
                      }/${projectInvoice.year}/view`
                    );
              }}
            >
              {CONSTANTS.MONTHS[projectInvoice.month]} {projectInvoice.year}
            </button>
          ) : (
            <div className="aui-td">
              {" "}
              {CONSTANTS.MONTHS[projectInvoice.month]} {projectInvoice.year}
            </div>
          )}
        </td>
        <td data-title="Invoice">
          <div className="aui-td w-auto">
            $ {projectInvoice.decryptedTotalCost}
          </div>
        </td>
        <td data-title="Invoice">
          <div className="aui-td w-auto text-center">
            {userRoles === CONSTANTS.ROLES.ADMIN ||
            userRoles === CONSTANTS.ROLES.SUPERADMIN ? (
              <div>
                Current Status : <b>{projectInvoice.status}</b>
                <select
                  className="select-update-button"
                  onChange={(event) => handleOptionChange(event, index)}
                >
                  <option value="select to update"> Select to Update </option>
                  <option value="UnPaid">UnPaid</option>
                  <option value="Paid">Paid</option>
                  <option value="Draft">Draft</option>
                </select>
              </div>
            ) : (
              <div className="aui-td">{selectedOption}</div>
            )}
          </div>
        </td>
      </tr>
    );
  };

  return (
    <RenderInvoiceWrapper>
      <div className="invoice-table">
        <table className="aui-responsive-table mb-5">
          <thead>
            <tr>
              <th scope="col">
                <div className="aui-th">SI</div>
              </th>
              <th scope="col">
                <div className="aui-th">Project Name</div>
              </th>
              <th scope="col">
                <div className="aui-th text-center"> Date</div>
              </th>
              <th scope="col">
                <div className="aui-th">Invoice</div>
              </th>
              <th scope="col">
                <div className="aui-th text-center">Status</div>
              </th>
            </tr>
          </thead>
          <tbody>{projectInvoice.map(renderInvoice)}</tbody>
        </table>
      </div>
    </RenderInvoiceWrapper>
  );
};

export default InvoiceTable;
