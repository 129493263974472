import store from "../app/store";
import { validJSON } from "../common/utils";
import { showToast } from "../components/Toast/toast.slice";
import config from "../config";

const employeelist = async (
  pageNumber: number,
  pageSize: number,
  sortBy: string,
  filterBy: string,
  workCategorizationFilterBy: string,
  searchBy: any
): Promise<any> => {
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `${localStorage.userAccessToken}`);
  const userkey: any = sessionStorage.getItem("userKey");
  myHeaders.append("userkey", userkey);

  const url =
    `${config[config.env].apiEnspoints.accounts}/employee?pageNumber=` +
    `${pageNumber}` +
    "&pageSize=" +
    `${pageSize}` +
    "&sortBy=" +
    `${sortBy}` +
    "&filterBy=" +
    `${filterBy}` +
    "&workCategorizationFilterBy=" +
    `${workCategorizationFilterBy}` +
    "&searchBy=" +
    `${searchBy}`;
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        if (validJSON(result)) {
          const employeeresponse = JSON.parse(result);
          if (employeeresponse.statusCode === 200 && employeeresponse.success) {
            resolve(employeeresponse);
          } else {
            throw employeeresponse.error;
          }
        } else {
          const errorResponse = {
            error: "Error",
            message: "Invalid Response JSON Format",
            code: "Invalid JSON",
          };
          throw errorResponse;
        }
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
        reject(error);
      });
  });
};
export default employeelist;
