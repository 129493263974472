/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import store from "app/store";
import { showToast, successToast } from "components/Toast/toast.slice";
import getTotalUtilization from "api/getTotalUtilization.Api";
import employeeProfile from "../../api/employeeProfileApi";
import Navigator from "../../components/Navigator";
import DeactivatedUserProfileForm from "./DeactivatedUserProfileForm";
import { EmployeeManagementWrapper } from "./styled";
import { EmployeeManagementMenu } from "./EmployeeManagementMenu";

const DeactivatedUserForm = (props: any) => {
  if (!localStorage.userAccessToken) return <Redirect to="/" />;
  const [userprofile, setUserProfile] = useState<any>([]);
  const [totalUtilization, setTotalUtilization] = useState();
  const { employeeId } = props.match.params;
  const [loading, setLoading] = useState(false);
  const employeeProfileRequest = () => {
    employeeProfile(employeeId)
      .then((employeeResponse) => {
        setUserProfile(employeeResponse.data.employeeDetails);
        setLoading(true);
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };
  const totalUtilizationRequest = () => {
    getTotalUtilization(employeeId)
      .then((employeeResponse) => {
        setTotalUtilization(employeeResponse.data.utilization);
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };
  useEffect(() => {
    employeeProfileRequest();
    totalUtilizationRequest();
  }, []);
  return (
    <div className="d-flex flex-column">
      <Navigator isActive="employee-management" />
      <div className="d-flex flex-column flex-grow-1">
        <EmployeeManagementWrapper className="container-fluid">
          <div aria-label="breadcrumb">
            <ol className="breadcrumb aha-breadcrumb pl-0">
              <li className="breadcrumb-item">
                <a href="/deactivatedlist">Deactivated List</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <a href={`/userprofile/${userprofile.employeeId}`}>
                  {userprofile.fullName}
                </a>
              </li>
            </ol>
          </div>
          <h1 className="h3 font-400 emp-mgmt-title d-flex">
            Employee Management
          </h1>
          <div className="d-lg-flex">
            <EmployeeManagementMenu />
            {loading ? (
              <div className="emp-mgt-table flex-grow-1">
                <div className="emp-heading position-relative justify-content-between align-items-center">
                  <div className="foldstructure" />
                  <div className="d-flex justify-content-between">
                    <div className="contentdiv">
                      <h2 className="h3 font-400 mb-0 pt-2">
                        {userprofile.fullName}
                      </h2>
                      <div className="d-flex flex-wrap pt-3 pt-md-0">
                        <div className="d-flex pt-md-2 pr-md-4">
                          <span className="font-bold pr-1">Role :</span>
                          <span>
                            {userprofile.role && userprofile.role.join(", ")}
                          </span>
                        </div>
                        <div className="pt-md-2">
                          <span className="font-bold pr-1">Email :</span>
                          <span>{userprofile.impelsysEmail}</span>
                          <span className="px-4">|</span>
                          <span className="d-inline-flex">
                            {userprofile.ahaEmail}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="imagediv">
                      <div className="userstyle position-relative">
                        <img src="../../images/user.svg" alt="User" />
                      </div>
                    </div>
                  </div>
                </div>
                <DeactivatedUserProfileForm data={userprofile} />
              </div>
            ) : (
              <table
                className="aui-responsive-table aui-table-cols emp-mgt-table aui-table-loader"
                role="alert"
                aria-live="assertive"
                aria-label="Page is Loading"
              />
            )}
          </div>
        </EmployeeManagementWrapper>
      </div>
    </div>
  );
};

export default DeactivatedUserForm;
