/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react";
import Pagination, { IPagination } from "components/Pagination/index";
import projectsInfo from "api/getProjectsInfo";
import { showToast } from "components/Toast/toast.slice";
import store from "app/store";
import ProjectTable from "components/ProjectTable";
import { Redirect, useLocation } from "react-router-dom";
import getCompletedProjects from "api/getCompletedProjects";
import Navigator from "../../components/Navigator";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { ProjectManagementWrapper } from "./styled";
import { ProjectManagementMenu } from "./ProjectManagementMenu";

const ProjectManagement = () => {
  if (!localStorage.userAccessToken) return <Redirect to="/" />;
  const location = useLocation();
  const [project, setProject] = useState<any>([]);
  const [completedProjects, setCompletedProjects] = useState("");
  const [inProgressProjects, setInProgressProjects] = useState("");
  const [pageNumber, setPageNumber] = useState<number>(
    location?.state?.state.backToPage ? location?.state?.state.backToPage : 1
  );
  const [totalCount, setTotalCount] = useState();
  const paginationSetLimit = 5;
  const defaultPagination: IPagination = {
    pageNumber: 1,
    pageSize: 6,
    totalCount: 6,
    totalPages: 5,
    isFirst: true,
    isLast: false,
    setPageNumber,
    paginationSetLimit,
  };
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<IPagination>(defaultPagination);
  useEffect(() => {
    projectsInfo(pageNumber, defaultPagination.pageSize)
      .then((employeeResponse) => {
        setProject(employeeResponse.data.project);
        setPagination({ ...employeeResponse.data._pagination, setPageNumber });
        setTotalCount(employeeResponse.data._pagination.totalCount);
        setLoading(true);
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  }, [pageNumber]);
  const page = {
    defaultPagination,
    pagination,
    pageNumber,
    totalCount,
  };
  useEffect(() => {
    getCompletedProjects()
      .then((employeeResponse) => {
        setCompletedProjects(employeeResponse.data.projectsCompleted);
        setInProgressProjects(employeeResponse.data.inProgress);
        setLoading(true);
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  }, []);
  return (
    <div className="d-flex flex-column">
      <Navigator />
      <div className="d-flex flex-column flex-grow-1 ">
        <ProjectManagementWrapper className="container-fluid">
          <h1 className="h3 font-400 proj-mgmt-title">Project Management</h1>
          <div className="d-lg-flex">
            <ProjectManagementMenu />
            {loading ? (
              <div className="proj-mgt-table proj-bottom flex-grow-1">
                <div className="proj-heading justify-content-between align-items-center">
                  <h2 className="h3 font-400 mb-0">Project List</h2>
                  <div className="d-md-flex py-2">
                    <span className="d-flex">
                      Number of Projects: {totalCount}
                    </span>
                    <span className="pl-md-4">
                      Completed Projects: {completedProjects}
                    </span>
                    <span className="d-flex pl-md-4">
                      In Progress: {inProgressProjects}
                    </span>
                  </div>
                </div>
                <ProjectTable page={page} project={project} />
              </div>
            ) : (
              <div
                className="aui-block-loader proj-mgt-table"
                role="alert"
                aria-live="assertive"
                aria-label="Page is Loading"
              />
            )}
          </div>
          {pagination && Number(pagination.totalPages) > 0 ? (
            <Pagination
              pageNumber={pagination.pageNumber}
              pageSize={pagination.pageSize}
              totalCount={pagination.totalCount}
              totalPages={pagination.totalPages}
              isFirst={pagination.isFirst}
              isLast={pagination.isLast}
              setPageNumber={pagination.setPageNumber}
              paginationSetLimit={5}
            />
          ) : null}
        </ProjectManagementWrapper>
      </div>
    </div>
  );
};

export default ProjectManagement;
