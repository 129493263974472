import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import { parse, format } from "date-fns";
import { showToast, successToast } from "components/Toast/toast.slice";
import employeePost from "api/addEmployeeApi";
import { Redirect, useHistory } from "react-router-dom";
import updateSalary from "api/updateSalaryApi";
import addSalaryHistory from "api/employeeSalaryHistoryApi";
import updateSalaryTillDate from "api/updateSalaryHistoryApi";
import store from "app/store";
import Navigator from "../Navigator";
import { EmployeeManagementWrapper } from "../../pages/EmployeeManagement/styled";
import { EmployeeManagementMenu } from "../../pages/EmployeeManagement/EmployeeManagementMenu";

const UploadCSV = () => {
  const [uploadfile, SetUploadfile] = useState([]);
  const [jsonData, setJsonData] = useState([]);
  const history = useHistory();
  const updateSalaryHistoryTable = (employeeForm: any) => {
    const employeeUpdate = {
      employeeId: employeeForm.employeeId,
      salary: parseFloat(employeeForm.salary),
      salaryeffectivefromDate: employeeForm.impelsysjoinDate,
    };
    updateSalary(employeeUpdate)
      .then((patchResponse: any) => {
        addSalaryHistory(employeeUpdate)
          .then((postResponse: any) => {
            if (employeeForm.isEdit) {
              updateSalaryTillDate(employeeUpdate)
                .then((putResponse: any) => {})
                .catch((error: any) => {
                  store.dispatch(showToast(error));
                });
            }
          })
          .catch((error: any) => {
            store.dispatch(showToast(error.details[0].message));
          });
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };
  const PostUploadData = (data: any): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      employeePost(data)
        .then((postResponse) => {
          updateSalaryHistoryTable(data);
          if (postResponse.success) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((error: any) => {
          store.dispatch(showToast(error.details[0].message));
        });
    });
  };
  const updatedObject = (obj: any) => {
    const {
      EMP_ID,
      NAME,
      AHA_EMAIL_ID,
      IMPELSYS_EMAIL_ID,
      PHONE,
      WORK_CATEGORIZATION,
      PROJECT,
      SUB_TEAM,
      DESIGNATION,
      DOJ_IMPELSYS,
      DOJ_HB,
      PROJECT_ROLE,
      PRIMARY_SKILL,
      REPORTING_MANAGER,
      EMP_TYPE,
      CONTRACT_NAME,
      ANNUAL_GROSS,
      VENDOR_COST,
      VENDOR_COST_EXPIRY_DATE,
      IS_BILLABLE,
    } = obj;
    return {
      employeeId: parseInt(EMP_ID, 10),
      fullName: NAME,
      ahaEmail: AHA_EMAIL_ID,
      impelsysEmail: IMPELSYS_EMAIL_ID,
      phone: String(PHONE),
      workCategorization: WORK_CATEGORIZATION,
      teamDetails: JSON.parse(`["${PROJECT}"]`),
      subteam: JSON.parse(`["${SUB_TEAM}"]`),
      designationDetails: JSON.parse(`["${DESIGNATION}"]`),
      salaryeffectivefromDate: DOJ_IMPELSYS,
      impelsysjoinDate: DOJ_IMPELSYS,
      ahajoinDate: DOJ_HB,
      role: JSON.parse(`["${PROJECT_ROLE}"]`),
      primarySkills: JSON.parse(`["${PRIMARY_SKILL}"]`),
      reportingManager: "null" ? "" : String(REPORTING_MANAGER),
      employeeType: EMP_TYPE,
      contractName: EMP_TYPE === "Contract" ? String(CONTRACT_NAME) : "",
      salary: parseFloat(ANNUAL_GROSS),
      vendorCost: parseFloat(VENDOR_COST),
      vendorCostExpiryDate: VENDOR_COST_EXPIRY_DATE,
      isBillable: IS_BILLABLE,
      secondarySkills: [],
    };
  };
  const callUpload = () => {
    const mappedData = jsonData.map(updatedObject);
    const newData = mappedData.map((data) => PostUploadData(data));
    Promise.all(newData)
      .then((results) => {
        const allTrue = results.every((result) => result === true);
        if (allTrue) {
          const successMessage = ` Successfully uploaded all data`;
          const isValid = false;
          const result = {
            isValid,
            visibility: !isValid,
            message: successMessage,
          };
          store.dispatch(successToast(result));
          history.push("/employee-management");
        }
      })
      .catch((error) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };

  const handleFileUpload = (event: any) => {
    const file = event.target.files[0];

    SetUploadfile(file.name);
    Papa.parse(file, {
      header: true,
      dynamicTyping: true,
      complete: (result: any) => {
        setJsonData(result.data.slice(0, -1));
      },
      error: (error: any) => {
        console.error("Error parsing CSV:", error);
      },
    });
  };
  return (
    <div className="d-flex flex-column">
      <Navigator />
      <div className="d-flex flex-column flex-grow-1">
        <EmployeeManagementWrapper className="container-fluid">
          <h1 className="h3 font-400 emp-mgmt-title">Employee Management</h1>
          <div className="d-lg-flex">
            <EmployeeManagementMenu />
            <div className="w-100">
              <div className="aui-drag-section">
                <div className="aui-drag-outline">
                  <div className="aui-drag-area d-flex align-items-center justify-content-center">
                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <i className="aha-icon-cloud-upload aui-drag-upload-icon" />
                      <div className="aui-btn-files">
                        <input
                          id="uploadfile"
                          type="file"
                          name="uploadfile"
                          accept=".csv"
                          aria-label="Browse Files"
                          onChange={handleFileUpload}
                        />
                        <label
                          htmlFor="uploadfile"
                          className="btn btn-round btn-primary m-0"
                          aria-hidden="true"
                        >
                          Browse Files
                        </label>
                      </div>
                      <br />
                      {uploadfile}
                    </div>
                  </div>
                  <button
                    className="btn btn-round btn-primary d-inline-block mt-4"
                    type="button"
                    onClick={callUpload}
                  >
                    Upload
                  </button>
                </div>
              </div>
            </div>
          </div>
        </EmployeeManagementWrapper>
      </div>
    </div>
  );
};
export default UploadCSV;
