import React, { ReactNode } from "react";
import PropTypes from "prop-types";
import Toast from "../../../components/Toast";

interface Props {
  children: ReactNode;
}
export default function AuthLayout({ children }: Props) {
  return (
    <>
      <Toast />
      <div>{children}</div>
    </>
  );
}
AuthLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
