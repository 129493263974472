/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from "react";
import { showToast } from "components/Toast/toast.slice";
import { Redirect, useHistory } from "react-router-dom";
import projectView from "api/getProjectDetailsApi";
import CONSTANTS from "common/constants";
import InvoiceListTable from "components/InvoiceListTable";
import getInvoiceList from "api/getInvoiceListAPI";
import store from "app/store";
import { parseJwt } from "utils";
import Navigator from "../../components/Navigator";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { ProjectManagementWrapper } from "../ProjectManagement/styled";
import { ShopCPRMenu } from "./ShopCPRMenu";

const InvoicePage = (props: any) => {
  if (!localStorage.userAccessToken) return <Redirect to="/" />;
  const [loading, setLoading] = useState(false);
  const [projectProfile, setProjectProfile] = useState<any>([]);
  const [projectInvoiceList, setProjectInvoiceList] = useState<any>([]);
  const [userRoles, setUserRoles] = useState<any>();
  const userToken = localStorage.userAccessToken;
  const tokenPayload = parseJwt(userToken).parsedJWT;
  const { userRole } = tokenPayload;
  useEffect(() => {
    setUserRoles(userRole);
  });
  const { projectId } = props.match.params;
  const clientCurrency = "USD";
  const history = useHistory();
  const projectProfileRequest = () => {
    projectView(projectId)
      .then((projectResponse) => {
        setProjectProfile(projectResponse.data.projectDetails);
        setLoading(true);
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };
  const generatedInvoiceListRequest = () => {
    getInvoiceList(projectId)
      .then((invoiceResponse) => {
        setProjectInvoiceList(invoiceResponse.data.invoiceListObj);
        setLoading(true);
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };
  const date = new Date();
  const thisMonth =
    `${date.getMonth() + 1}`.length === 1
      ? `${0}${date.getMonth() + 1}`
      : `${date.getMonth() + 1}`;
  const thisYear = `${date.getFullYear()}`;
  useEffect(() => {
    projectProfileRequest();
    generatedInvoiceListRequest();
  }, []);
  return (
    <div className="d-flex flex-column">
      <Navigator isActive="project-list" />
      <div className="d-flex flex-column flex-grow-1">
        <ProjectManagementWrapper className="container-fluid">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb aha-breadcrumb pl-0">
              <li className="breadcrumb-item">
                <a href="/project-list">Project List</a>
              </li>
              <li className="breadcrumb-item">
                <a href={`/project/${projectId}`}>
                  {projectProfile.projectName}
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <a href={`/invoice/${projectId}`}>Invoice</a>
              </li>
            </ol>
          </nav>
          <h1 className="h3 font-400 proj-mgmt-title">Project Management</h1>
          <div className="d-lg-flex">
            <ShopCPRMenu projectId={projectId} />
            {loading ? (
              <div className="proj-mgt-table flex-grow-1">
                <div className="proj-heading justify-content-between position-relative align-items-center">
                  <div className="foldstructure" />
                  <div className="position-relative">
                    <h2 className="h3 font-400 mb-0">
                      {projectProfile.projectName}
                    </h2>
                    <p className="my-2 pt-2">Invoice</p>
                    <div className="position-absolute shoplogo">
                      {projectProfile.logoUrl ? (
                        <div className="position-absolute shoplogo">
                          <img src={projectProfile.logoUrl} alt="Logo" />
                        </div>
                      ) : (
                        <div />
                      )}
                    </div>
                  </div>
                  {(userRoles === CONSTANTS.ROLES.ADMIN ||
                    userRoles === CONSTANTS.ROLES.SUPERADMIN) && (
                    <button
                      type="button"
                      className="btn btn-text settingsfocus content_img position-absolute settings ml-1 w-auto"
                      data-toggle="modal"
                      data-backdrop="static"
                      data-keyboard="false"
                      aria-label="newinvoicebutton"
                      data-target="#newinvoice"
                      onClick={() => {
                        projectProfile.projectName === "EDC"
                          ? history.push(
                              `/edcinvoice/${projectId}/${thisMonth}/${thisYear}/new`
                            )
                          : history.push(
                              `/invoicedate/${projectId}/${thisMonth}/${thisYear}/new`
                            );
                      }}
                    >
                      <img
                        src="../images/plusicon.png"
                        alt="new invoice icon"
                      />
                      <span>New Invoice</span>
                    </button>
                  )}
                </div>
                <InvoiceListTable
                  projectInvoice={projectInvoiceList}
                  projectProfile={projectProfile}
                  projectId={projectId}
                />
              </div>
            ) : (
              <div
                className="aui-block-loader proj-mgt-table"
                role="alert"
                aria-live="assertive"
                aria-label="Page is Loading"
              />
            )}
          </div>
        </ProjectManagementWrapper>
      </div>
    </div>
  );
};

export default InvoicePage;
