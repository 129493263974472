/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import editProject from "api/editProjectAPI";
import { showToast, successToast } from "../../components/Toast/toast.slice";
import projectPost from "../../api/addProjectApi";
import { getEmployees } from "../../api/getEmployees";
import store from "../../app/store";

interface Props {
  project: any;
  backToProfile?: any;
}
const ProjectManagementForms = (props: Props) => {
  const { project } = props;
  const { backToProfile } = props;
  const projId = project.id;
  const [projectForm, setprojectForm] = useState({
    projectName: project.projectName,
    projectCode: project.projectCode,
    sow: project.sow,
    projectStartDate: project.startDate,
    plannedEndDate: project.plannedEndDate,
    actualEndDate: project.actualEndDate,
    projectManager: project.meta && project.meta.projectManager,
    ahaManager: project.meta && project.meta.ahaManager,
    ahamanagerEmailid: project.meta && project.meta.ahaManagerEmailId,
    businessContact: project.meta && project.meta.businessContact,
    description: project.description,
    teamSize: project.teamSize,
    logoUrl: project.logoUrl,
    serviceType: project.serviceType,
    techstackDescription: project.techStackDescription,
    productOwner: project.meta && project.meta.productOwner,
    deliveryManager: project.meta && project.meta.deliveryManager,
    applicationArchitect: project.meta && project.meta.applicationArchitect,
    technicalManager: project.meta && project.meta.technicalManager,
    solutionArchitect: project.meta && project.meta.solutionArchitect,
    scrumMaster: project.meta && project.meta.scrumMaster,
    isEdit: project.isEdit,
  });
  const addProjectFormValidation = () => {
    const nameformat = /^[A-Za-z ]+$/;
    let errormessage = "";
    let isValid = true;

    if (!projectForm.projectName.match(nameformat)) {
      errormessage = "Enter Valid Project Name";
      isValid = false;
    }
    if (!projectForm.projectCode.match(nameformat)) {
      errormessage = "Enter Valid Project Code";
      isValid = false;
    }
    if (!projectForm.projectManager.match(nameformat)) {
      errormessage = "Project Manager should contain only characters ";
      isValid = false;
    }
    if (!projectForm.ahaManager.match(nameformat)) {
      errormessage = "AHA Manager should contain only characters ";
      isValid = false;
    }
    if (!projectForm.projectManager) {
      errormessage = "Project Manager is not allowed to be empty";
      isValid = false;
    }

    // if (!projectForm.scrumMaster) {
    //   errormessage = "Scrum Master is not allowed to be empty";
    //   isValid = false;
    // }
    if (!projectForm.deliveryManager) {
      errormessage = "Delivery Manager is not allowed to be empty";
      isValid = false;
    }
    if (!projectForm.productOwner) {
      errormessage = "Product Owner is not allowed to be empty";
      isValid = false;
    }
    if (projectForm.plannedEndDate < projectForm.projectStartDate) {
      errormessage = "Invalid, provide valid date for Planned End Date";
      isValid = false;
    }
    if (projectForm.actualEndDate < projectForm.projectStartDate) {
      errormessage = "Invalid, provide valid date for Actual End Date";
      isValid = false;
    }
    const result = {
      isValid,
      visibility: !isValid,
      message: errormessage,
    };
    if (!isValid) store.dispatch(showToast(result));
    return result;
  };
  const success = (projectName: any) => {
    const successmessage = project.isEdit
      ? `${projectName} Project is successfully updated`
      : `${projectName} Project is successfully added`;
    const isValid = false;
    const result = {
      isValid,
      visibility: !isValid,
      message: successmessage,
    };
    if (!isValid) store.dispatch(successToast(result));
    return result;
  };
  const onChangeForm = (event: any) => {
    event.persist();
    setprojectForm({
      ...projectForm,
      [event.target.name]: event.target.value,
    });
  };
  const [manager, setmanager] = useState<any>([]);
  const employeeRequest = () => {
    getEmployees()
      .then((Response) => {
        setmanager(Response.data.employees);
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };
  useEffect(() => {
    employeeRequest();
  }, []);
  const history = useHistory();
  const addProjectFormSubmission = (event: any) => {
    event.preventDefault();
    if (addProjectFormValidation().isValid) {
      const employeeData = {
        projectName: projectForm.projectName,
        projectCode: projectForm.projectCode,
        meta: {
          projectManager: projectForm.projectManager,
          ahaManager: projectForm.ahaManager,
          ahaManagerEmailId: projectForm.ahamanagerEmailid,
          businessContact: projectForm.businessContact,
          productOwner: projectForm.productOwner,
          deliveryManager: projectForm.deliveryManager,
          applicationArchitect: projectForm.applicationArchitect,
          technicalManager: projectForm.technicalManager,
          solutionArchitect: projectForm.solutionArchitect,
          scrumMaster: projectForm.scrumMaster,
        },
        sow: projectForm.sow,
        startDate: projectForm.projectStartDate,
        plannedEndDate: projectForm.plannedEndDate,
        actualEndDate: projectForm.actualEndDate,
        description: projectForm.description,
        teamSize: projectForm.teamSize,
        logoUrl: projectForm.logoUrl,
        serviceType: projectForm.serviceType,
        techStackDescription: projectForm.techstackDescription,
      };
      projectPost(employeeData)
        .then((postResponse) => {
          success(employeeData.projectName);
          history.push("/project-list");
        })
        .catch((error: any) => {
          store.dispatch(showToast(error.details[0].message));
        });
    }
  };
  const editProjectFormSubmission = (event: any) => {
    event.preventDefault();
    if (addProjectFormValidation().isValid) {
      const projectData = {
        projectName: projectForm.projectName,
        projectCode: projectForm.projectCode,
        meta: {
          projectManager: projectForm.projectManager,
          ahaManager: projectForm.ahaManager,
          ahaManagerEmailId: projectForm.ahamanagerEmailid,
          businessContact: projectForm.businessContact,
          productOwner: projectForm.productOwner,
          deliveryManager: projectForm.deliveryManager,
          applicationArchitect: projectForm.applicationArchitect,
          technicalManager: projectForm.technicalManager,
          solutionArchitect: projectForm.solutionArchitect,
          scrumMaster: projectForm.scrumMaster,
        },
        sow: projectForm.sow,
        startDate: projectForm.projectStartDate,
        plannedEndDate: projectForm.plannedEndDate,
        actualEndDate: projectForm.actualEndDate,
        description: projectForm.description,
        teamSize: projectForm.teamSize,
        logoUrl: projectForm.logoUrl,
        serviceType: projectForm.serviceType,
        techStackDescription: projectForm.techstackDescription,
      };
      editProject(projId, projectData)
        .then((patchResponse: any) => {
          success(projectData.projectName);
          backToProfile();
        })
        .catch((error: any) => {
          store.dispatch(showToast(error.details[0].message));
        });
    }
  };

  return (
    <form
      autoComplete="off"
      onSubmit={
        projectForm.isEdit
          ? editProjectFormSubmission
          : addProjectFormSubmission
      }
    >
      <div className="form-group row required">
        <label htmlFor="projectName" className="col-sm-4 col-form-label">
          Project Name
        </label>
        <div className="col-sm-8">
          <input
            onChange={onChangeForm}
            value={projectForm.projectName}
            name="projectName"
            type="text"
            className="form-control"
            id="projectName"
            required
          />
        </div>
      </div>
      <div className="form-group row required">
        <label htmlFor="projectCode" className="col-sm-4 col-form-label">
          Project Code
        </label>
        <div className="col-sm-8">
          <input
            onChange={onChangeForm}
            value={projectForm.projectCode}
            name="projectCode"
            type="text"
            className="form-control"
            id="projectCode"
            required
          />
        </div>
      </div>
      <div className="form-group row required">
        <label htmlFor="sow" className="col-sm-4 col-form-label">
          SOW
        </label>
        <div className="col-sm-8">
          <input
            onChange={onChangeForm}
            value={projectForm.sow}
            name="sow"
            type="text"
            className="form-control"
            id="sow"
            required
          />
        </div>
      </div>
      <div className="form-group row required">
        <label htmlFor="projectStartDate" className="col-sm-4 col-form-label">
          Project Start Date
        </label>
        <div className="col-sm-8">
          <input
            onChange={onChangeForm}
            value={projectForm.projectStartDate}
            type="date"
            name="projectStartDate"
            className="form-control"
            id="projectStartDate"
            required
          />
        </div>
      </div>
      <div className="form-group row required">
        <label htmlFor="plannedEndDate" className="col-sm-4 col-form-label">
          Planned End Date
        </label>
        <div className="col-sm-8">
          <input
            onChange={onChangeForm}
            value={projectForm.plannedEndDate}
            type="date"
            name="plannedEndDate"
            className="form-control"
            id="plannedEndDate"
            required
          />
        </div>
      </div>
      <div className="form-group row required">
        <label htmlFor="actualEndDate" className="col-sm-4 col-form-label">
          Actual End Date
        </label>
        <div className="col-sm-8">
          <input
            onChange={onChangeForm}
            value={projectForm.actualEndDate}
            type="date"
            name="actualEndDate"
            className="form-control"
            id="actualEndDate"
            required
          />
        </div>
      </div>
      <div className="form-group row required">
        <label htmlFor="projectManager" className="col-sm-4 col-form-label">
          Project Manager
        </label>
        <div className="col-sm-8">
          <select
            onChange={onChangeForm}
            value={projectForm.projectManager}
            name="projectManager"
            className="form-control"
            id="projectManager"
          >
            <option hidden> Select Project Manager </option>
            {manager.map((obj: any, i: number) => {
              return <option value={obj.full_name}>{obj.full_name}</option>;
            })}
          </select>
        </div>
      </div>
      <div className="form-group row required">
        <label htmlFor="ahaManager" className="col-sm-4 col-form-label">
          AHA Manager
        </label>
        <div className="col-sm-8">
          <input
            onChange={onChangeForm}
            value={projectForm.ahaManager}
            type="text"
            name="ahaManager"
            className="form-control"
            id="ahaManager"
            required
          />
        </div>
      </div>
      <div className="form-group row required">
        <label htmlFor="ahamanagerEmailid" className="col-sm-4 col-form-label">
          AHA Manager Email ID
        </label>
        <div className="col-sm-8">
          <input
            onChange={onChangeForm}
            value={projectForm.ahamanagerEmailid}
            name="ahamanagerEmailid"
            type="text"
            className="form-control"
            id="ahamanagerEmailid"
            required
          />
          {projectForm.ahamanagerEmailid &&
            !projectForm.ahamanagerEmailid?.endsWith("heart.org") && (
              <p className="validemail">Enter Valid AHA email</p>
            )}
        </div>
      </div>
      <div className="form-group row required">
        <label htmlFor="businessContact" className="col-sm-4 col-form-label">
          Business Contact
        </label>
        <div className="col-sm-8">
          <input
            onChange={onChangeForm}
            value={projectForm.businessContact}
            type="text"
            name="businessContact"
            className="form-control"
            id="businessContact"
            required
          />
          {projectForm.businessContact &&
            !projectForm.businessContact?.endsWith("heart.org") && (
              <p className="validemail">Enter Valid AHA email</p>
            )}
        </div>
      </div>
      <div className="form-group row required">
        <label htmlFor="description" className="col-sm-4 col-form-label">
          Description
        </label>
        <div className="col-sm-8">
          <textarea
            onChange={onChangeForm}
            value={projectForm.description}
            name="description"
            className="form-control"
            id="description"
          />
        </div>
      </div>
      <div className="form-group row required">
        <label htmlFor="teamSize" className="col-sm-4 col-form-label">
          Team Size
        </label>
        <div className="col-sm-8">
          <input
            onChange={onChangeForm}
            value={projectForm.teamSize}
            name="teamSize"
            type="number"
            className="form-control"
            id="teamSize"
          />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="logoUrl" className="col-sm-4 col-form-label">
          Logo-Url
        </label>
        <div className="col-sm-8">
          <input
            onChange={onChangeForm}
            value={projectForm.logoUrl}
            name="logoUrl"
            type="text"
            className="form-control"
            id="logoUrl"
          />
        </div>
      </div>
      <div className="form-group row required">
        <label htmlFor="serviceType" className="col-sm-4 col-form-label">
          Service Type
        </label>
        <div className="col-sm-8">
          <input
            onChange={onChangeForm}
            value={projectForm.serviceType}
            name="serviceType"
            type="text"
            className="form-control"
            id="serviceType"
          />
        </div>
      </div>
      <div className="form-group row required">
        <label
          htmlFor="techstackDescription"
          className="col-sm-4 col-form-label"
        >
          Tech Stack Description
        </label>
        <div className="col-sm-8">
          <textarea
            onChange={onChangeForm}
            value={projectForm.techstackDescription}
            name="techstackDescription"
            className="form-control"
            id="techstackDescription"
          />
        </div>
      </div>
      <div className="form-group row required">
        <label htmlFor="productOwner" className="col-sm-4 col-form-label">
          Product Owner
        </label>
        <div className="col-sm-8">
          <select
            onChange={onChangeForm}
            value={projectForm.productOwner}
            name="productOwner"
            className="form-control"
            id="productOwner"
          >
            <option hidden> Select Product Owner </option>
            {manager.map((obj: any, i: number) => {
              return <option value={obj.full_name}>{obj.full_name}</option>;
            })}
          </select>
        </div>
      </div>
      <div className="form-group row required">
        <label htmlFor="deliveryManager" className="col-sm-4 col-form-label">
          Delivery Manager
        </label>
        <div className="col-sm-8">
          <select
            onChange={onChangeForm}
            value={projectForm.deliveryManager}
            name="deliveryManager"
            className="form-control"
            id="deliveryManager"
          >
            <option hidden> Select Delivery Manager </option>
            {manager.map((obj: any, i: number) => {
              return <option value={obj.full_name}>{obj.full_name}</option>;
            })}
          </select>
        </div>
      </div>
      <div className="form-group row">
        <label
          htmlFor="applicationArchitect"
          className="col-sm-4 col-form-label"
        >
          Application Architect
        </label>
        <div className="col-sm-8">
          <select
            onChange={onChangeForm}
            value={projectForm.applicationArchitect}
            name="applicationArchitect"
            className="form-control"
            id="applicationArchitect"
          >
            <option hidden> Select Application Architect </option>
            {manager.map((obj: any, i: number) => {
              return <option value={obj.full_name}>{obj.full_name}</option>;
            })}
          </select>
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="technicalManager" className="col-sm-4 col-form-label">
          Technical Manager
        </label>
        <div className="col-sm-8">
          <select
            onChange={onChangeForm}
            value={projectForm.technicalManager}
            name="technicalManager"
            className="form-control"
            id="technicalManager"
          >
            <option hidden> Select Technical Manager </option>
            {manager.map((obj: any, i: number) => {
              return <option value={obj.full_name}>{obj.full_name}</option>;
            })}
          </select>
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="solutionArchitect" className="col-sm-4 col-form-label">
          Solution Architect
        </label>
        <div className="col-sm-8">
          <select
            onChange={onChangeForm}
            value={projectForm.solutionArchitect}
            name="solutionArchitect"
            className="form-control"
            id="solutionArchitect"
          >
            <option hidden> Select Solution Architect </option>
            {manager.map((obj: any, i: number) => {
              return <option value={obj.full_name}>{obj.full_name}</option>;
            })}
          </select>
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="scrumMaster" className="col-sm-4 col-form-label">
          Scrum Master
        </label>
        <div className="col-sm-8">
          <select
            onChange={onChangeForm}
            value={projectForm.scrumMaster}
            name="scrumMaster"
            className="form-control"
            id="scrumMaster"
          >
            <option hidden> Select Scrum Master </option>
            {manager.map((obj: any, i: number) => {
              return <option value={obj.full_name}>{obj.full_name}</option>;
            })}
          </select>
        </div>
      </div>
      <div className="line" />
      <div className="d-flex justify-content-end py-5">
        <button
          type="button"
          className="btn btn-round btn-secondary mr-4 btnwidth"
          onClick={backToProfile}
        >
          Cancel
        </button>
        <button type="submit" className="btn btn-round btn-primary btnwidth">
          {projectForm.isEdit ? "Update Project" : "Create Project"}
        </button>
      </div>
    </form>
  );
};
export default ProjectManagementForms;
