const CONSTANTS = {
  PENDING: "PENDING",
  REGISTERED: "APPROVED",
  INTERNATIONAL: "INTERNATIONAL",
  DOMESTIC: "DOMESTIC",
  LOCAL_STORE_KEY: "#%$##%$#",
  ALL: "ALL",
  WINDOW: window as any,
  KEY_ENTER: 13,
  KEY_SPACEBAR: 32,
  DAY: "01",
  MONTHS: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  ROLES: {
    SUPERADMIN: "SUPERADMIN",
    ADMIN: "ADMIN",
    VIEWER: "VIEWER",
    MANAGER: "MANAGER",
    HR: "HR",
  },
  WORKCATEGORIZATIONS: { PROJECT: "PROJECT", LCM: "LCM", SUPPORT: "SUPPORT" },
  tenantconfig: {
    settings: {
      workingHours: 9,
      adminCharge: 10,
      managementCharge: 6,
      workingDays: 22,
      clientCurrency: "INR",
      fxValue: 73,
      seatCost: 3.75,
      currency: "USD",
    },
  },
};

export default CONSTANTS;
