/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from "react";
import employeelist from "api/employeeApi";
import store from "app/store";
import { Redirect, useLocation } from "react-router-dom";
import DeactivatedEmployeeTable from "components/DeactivatedEmployeeTable";
import deactivatedList from "api/getDeactivatedList.api";
import { showToast } from "../../components/Toast/toast.slice";
import EmployeeTable from "../../components/EmployeeTable";
import Pagination, { IPagination } from "../../components/Pagination/index";
import Navigator from "../../components/Navigator";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { EmployeeManagementWrapper } from "./styled";
import { EmployeeManagementMenu } from "./EmployeeManagementMenu";

const DeactivatedList = () => {
  if (!localStorage.userAccessToken) return <Redirect to="/" />;
  const location = useLocation();
  const [employee, setEmployee] = useState<any>([]);
  const [pageNumber, setPageNumber] = useState<number>(
    location?.state?.state.backToPage ? location?.state?.state.backToPage : 1
  );
  const [totalCount, setTotalCount] = useState();
  const paginationSetLimit = 5;
  const defaultPagination: IPagination = {
    pageNumber: 1,
    pageSize: 6,
    totalCount: 6,
    totalPages: 5,
    isFirst: true,
    isLast: false,
    setPageNumber,
    paginationSetLimit,
  };
  const [pagination, setPagination] = useState<IPagination>(defaultPagination);
  const [loading, setLoading] = useState(false);
  const employeeListRequest = () => {
    deactivatedList(pageNumber, defaultPagination.pageSize)
      .then((employeeResponse) => {
        setEmployee(employeeResponse.data.employee);
        setPagination({ ...employeeResponse.data._pagination, setPageNumber });
        setTotalCount(employeeResponse.data._pagination.totalCount);
        setLoading(true);
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };
  useEffect(() => {
    employeeListRequest();
  }, [pageNumber]);

  const page = {
    defaultPagination,
    pagination,
    pageNumber,
    totalCount,
  };
  return (
    <div className="d-flex flex-column">
      <Navigator isActive="employee-management" />
      <div className="d-flex flex-column flex-grow-1">
        <EmployeeManagementWrapper className="container-fluid">
          <div aria-label="breadcrumb">
            <ol className="breadcrumb aha-breadcrumb pl-0">
              <li className="breadcrumb-item" aria-current="page">
                <a href="/employee-management">Employee List</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <a href="/deactivatedlist">Deactivated List</a>
              </li>
            </ol>
          </div>
          <h1 className="h3 font-400 emp-mgmt-title">Employee Management</h1>
          <div className="d-lg-flex">
            <EmployeeManagementMenu />
            {loading ? (
              <div className="emp-mgt-table flex-grow-1">
                <div className="emp-heading justify-content-between align-items-center d-md-flex">
                  <h2 className="h3 font-400 mb-0">
                    Deactivated Employee List
                  </h2>
                  <div>
                    Total no of Employees :
                    <span className="total-emp ml-2 font-600">
                      {totalCount}
                    </span>
                  </div>
                </div>
                <DeactivatedEmployeeTable
                  employee={employee}
                  page={page}
                  backToTable={() => {
                    employeeListRequest();
                  }}
                />
              </div>
            ) : (
              <div
                className="aui-block-loader emp-mgt-table"
                role="alert"
                aria-live="assertive"
                aria-label="Page is Loading"
              />
            )}
          </div>
          {pagination && Number(pagination.totalPages) > 0 ? (
            <Pagination
              pageNumber={pagination.pageNumber}
              pageSize={pagination.pageSize}
              totalCount={pagination.totalCount}
              totalPages={pagination.totalPages}
              isFirst={pagination.isFirst}
              isLast={pagination.isLast}
              setPageNumber={pagination.setPageNumber}
              paginationSetLimit={5}
            />
          ) : null}
        </EmployeeManagementWrapper>
      </div>
    </div>
  );
};

export default DeactivatedList;
