import React, { useEffect, useState } from "react";
import PaginationStyle from "./styled";

export interface IPagination {
  pageNumber: number;
  pageSize: number;
  totalCount: number;
  totalPages: number;
  isFirst: boolean;
  isLast: boolean;
  setPageNumber: any;
  paginationSetLimit: number;
}

const Pagination = ({
  pageNumber,
  pageSize,
  totalCount,
  totalPages,
  isFirst,
  isLast,
  setPageNumber,
  paginationSetLimit,
}: IPagination) => {
  const [paginationRangeLimit, setPaginationRangeLimit] = useState<any>();
  const [paginationSetIndex, setPaginationSetIndex] = useState<any>(0);

  useEffect(() => {
    let paginationSetMaxLimit = paginationSetLimit;
    if (totalPages && totalPages < paginationSetLimit) {
      paginationSetMaxLimit = totalPages;
    } else if (totalPages === 0) {
      paginationSetMaxLimit = 1;
    }

    setPaginationRangeLimit(paginationSetMaxLimit);
  }, [paginationSetLimit, totalPages]);

  const modifyNextPaginationRange = () => {
    setPageNumber(pageNumber + 1);
    const nextPage = pageNumber + 1;

    if (nextPage > paginationSetLimit + paginationSetIndex) {
      setPaginationSetIndex(
        Math.floor(nextPage / paginationSetLimit) * paginationSetLimit
      );
      if (Number(totalPages) - nextPage < paginationSetLimit) {
        setPaginationRangeLimit(Number(totalPages) - nextPage + 1);
      }
    }
  };

  const modifyPreviousPaginationRange = () => {
    setPageNumber(pageNumber - 1);
    const prevPage = pageNumber - 1;

    if (prevPage <= paginationSetIndex) {
      setPaginationSetIndex(prevPage - paginationSetLimit);
      setPaginationRangeLimit(paginationSetLimit);
    }
  };

  return (
    <PaginationStyle>
      <div className="d-flex pagination-block flex-row-reverse mt-4">
        <nav aria-label="Page navigation">
          <ul className="pagination aui-pagination">
            <li className="page-item">
              <button
                className={isFirst ? "page-link page-disabled" : "page-link"}
                aria-label="Previous"
                disabled={isFirst}
                onClick={() => modifyPreviousPaginationRange()}
                type="button"
              >
                <span aria-hidden="true">&laquo;</span>
              </button>
            </li>
            {[...Array(paginationRangeLimit)].map((x, i) => (
              <li
                key={x}
                className={
                  1 + i + paginationSetIndex === pageNumber
                    ? "page-item active"
                    : "page-item"
                }
              >
                <button
                  className="page-link"
                  onClick={() => {
                    setPageNumber(1 + i + paginationSetIndex);
                  }}
                  type="button"
                >
                  {1 + i + paginationSetIndex}
                </button>
              </li>
            ))}
            <li className="page-item">
              <button
                className={isLast ? "page-link page-disabled" : "page-link"}
                aria-label="Next"
                disabled={isLast}
                onClick={() => {
                  modifyNextPaginationRange();
                }}
                type="button"
              >
                <span aria-hidden="true">&raquo;</span>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </PaginationStyle>
  );
};

export default Pagination;
