import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import CONSTANTS from "common/constants";
import { parseJwt } from "utils";
import tokenPost from "api/addTokenApi";
import HeaderWrapper from "./styled";

const Global: any = global;

const Header = () => {
  const location = useLocation();
  const [showProfileInfo, setShowProfileInfo] = useState(false);
  const shouldShowMyAccountButton = () => {
    return (
      location.pathname !== "/userkey" && location.pathname !== "/encryptionkey"
    );
  };

  const shouldShowMyProfileButton = () => {
    return (
      location.pathname !== "/userkey" && location.pathname !== "/encryptionkey"
    );
  };

  const token = localStorage.userAccessToken;
  const tokenPayload = parseJwt(token).parsedJWT;
  const { userRole, email } = tokenPayload;

  const signin = () => {
    Global.userManager
      .signinRedirect()
      .then(() => {
        console.log("signinRedirect ok");
      })
      .catch((err: any) => {
        console.log("signinRedirect error:", err);
      });
  };

  const signout = () => {
    tokenPost();
  };

  const toggleProfileInfo = () => {
    setShowProfileInfo(!showProfileInfo);
  };

  return (
    <HeaderWrapper className="aui-main-header aui-pri-header">
      <a href="#maincontent" className="aui-skip-content">
        Skip to main content
      </a>
      <nav className="navbar navbar-expand-lg justify-content-between aui-header-content aui-pri-header-t pl-3">
        <div className="invoice-logo d-flex">
          <img src="/images/AHA-logo.svg" alt="Invoice Logo" />
          <div className="impelsys-logo d-flex align-items-center mt-3">
            <img
              src="/images/Impelsyslogo.svg"
              alt="Impelsys Logo"
              className="logostyle"
            />
          </div>
        </div>
        <button
          className="navbar-toggler ml-2 px-0"
          type="button"
          data-toggle="collapse"
          data-target="#toggleNav"
          aria-controls="toggleNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="aha-icon-hamburger" />
        </button>
        <div
          className="justify-content-lg-end collapse navbar-collapse aui-pri-nav"
          id="toggleNav"
        >
          <ul className="navbar-nav mx-lg-0 flex-lg-row flex-column">
            {shouldShowMyProfileButton() && (
              <li className="nav-item dropdown px-lg-3 mt-2">
                <button
                  type="button"
                  className="btn btn-text"
                  id="navDropdown2"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onMouseEnter={toggleProfileInfo}
                  onMouseLeave={toggleProfileInfo}
                  aria-label="Toggle Profile Dropdown"
                >
                  <i className="aha-icon-profile ml-2" />
                </button>
                <div
                  className={`dropdown-menu aui-header-dropdown ${
                    showProfileInfo ? "show" : ""
                  }`}
                  aria-labelledby="navDropdown2"
                >
                  <p className="dropdown-item-text">{email}</p>
                </div>
              </li>
            )}
            {shouldShowMyAccountButton() && (
              <li className="d-flex nav-item dropdown px-lg-3 flex-column">
                <button
                  type="button"
                  className="btn btn-text dropdown-toggle flex-grow-1 text-left nav-link"
                  id="navDropdown1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  My Account
                </button>
                <div
                  className="dropdown-menu p-lg-0 aui-header-dropdown"
                  aria-labelledby="navDropdown1"
                >
                  {(userRole === CONSTANTS.ROLES.ADMIN ||
                    userRole === CONSTANTS.ROLES.SUPERADMIN) && (
                    <a className="dropdown-item py-2" href="/settings">
                      Settings
                    </a>
                  )}

                  <a className="dropdown-item py-2" href="/ChangePasswordPage">
                    Change Password
                  </a>
                  <a className="dropdown-item py-2" href="/" onClick={signout}>
                    Sign Out
                  </a>
                </div>
              </li>
            )}
          </ul>
        </div>
      </nav>
    </HeaderWrapper>
  );
};

export default Header;
