import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useGoogleLogin } from "react-google-login";
import ssoLogin from "api/googleSSOLoginAPI";
import { refreshTokenSetup } from "common/utils";
import { parseJwt } from "utils";
import CONSTANTS from "common/constants";
import getUserRoleApi from "api/getUserRoleApi";
import userLogin from "../../api/loginApi";
import { showToast } from "../../components/Toast/toast.slice";
import store from "../../app/store";
import { SignInWrapper } from "./styled";
import { roleId } from "../../../amplify/backend/function/invoiceaccounts/src/repo/role.repo";

const HomePage = () => {
  localStorage.clear();
  const [userName, setuserName] = useState("");
  const [password, setPassword] = useState("");
  const signInFormValidation = () => {
    const mailformat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let errormessage = "";
    let isValid = true;
    if (
      !userName.match(mailformat) ||
      password.length < 6 ||
      password.search(/[a-zA-Z]+/) === -1 ||
      password.search(/[0-9]+/) === -1
    ) {
      errormessage = "Invalid Email ID or Password";
      isValid = false;
    }
    const result = {
      isValid,
      visibility: !isValid,
      message: errormessage,
    };
    if (!isValid) store.dispatch(showToast(result));
    return result;
  };
  const history = useHistory();

  const signInFormSubmission = (event: any) => {
    event.preventDefault();
    if (signInFormValidation().isValid) {
      userLogin(userName, password)
        .then((loginResponse) => {
          const userToken = localStorage.userAccessToken;
          const tokenPayload = parseJwt(userToken).parsedJWT;
          const { userRole } = tokenPayload;
          getUserRoleApi(loginResponse.data.userId).then((Response) => {
            const RoleId = Response.data.result.name;
            if (RoleId === userRole) {
              if (Response.data.result.name === CONSTANTS.ROLES.HR) {
                history.push("/employee-management");
              } else if (Response.data.result.name === CONSTANTS.ROLES.ADMIN) {
                history.push("/userkey");
              } else if (
                Response.data.result.name === CONSTANTS.ROLES.SUPERADMIN
              ) {
                history.push("/encryptionkey");
              } else {
                history.push("/employee-management");
              }
            }
          });
        })
        .catch((error: any) => {
          store.dispatch(showToast(error.details[0].message));
        });
    }
  };

  const googleSSO = (event: any) => {
    refreshTokenSetup(event);
    const token = {
      token: event.tokenId,
    };
    ssoLogin(token)
      .then((loginResponse) => {
        history.push("/Dashboard");
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };
  const { signIn } = useGoogleLogin({
    onSuccess: googleSSO,
    onFailure: googleSSO,
    clientId: process.env.REACT_APP_GOOGLE_SSO_CLIENT_ID || " ",
  });

  return (
    <SignInWrapper>
      <div className="home-wrapper">
        <div className="home-wrapper-left" role="presentation">
          <img
            src="../../images/AHA_White_logo.png"
            alt="American Heart Association logo"
          />
        </div>
        <div className="home-wrapper-right">
          <div className="home-content">
            <div className="home-content-logo d-flex flex-row-reverse">
              <img src="./images/Impelsyslogo.png" alt="Impelsys logo" />
            </div>
            <div>
              <div className="home-signin-title d-flex justify-content-between align-items-center">
                <h1 className="signin mb-0 font-400">Sign In</h1>
                <p className="home-helptext mb-0">
                  <span className="font-red">*</span>Indicates a required field
                </p>
              </div>
              <form
                id="signinForm"
                autoComplete="off"
                onSubmit={signInFormSubmission}
              >
                <div className="form-group row required">
                  <label htmlFor="userName" className="col-12">
                    Please enter your username or work email address
                  </label>
                  <div className="col-12">
                    <input
                      value={userName}
                      onChange={(event) => setuserName(event.target.value)}
                      type="email"
                      className="form-control"
                      id="userName"
                      required
                    />
                  </div>
                </div>
                <div className="form-group row required">
                  <label htmlFor="password" className="col-12">
                    Password
                  </label>
                  <div className="col-12">
                    <input
                      value={password}
                      onChange={(event) => setPassword(event.target.value)}
                      type="password"
                      className="form-control"
                      id="password"
                      required
                    />
                  </div>
                </div>
                <div className="home-submit d-sm-flex justify-content-between align-items-center flex-row-reverse">
                  <button type="submit" className="btn btn-round btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </SignInWrapper>
  );
};

export default HomePage;
