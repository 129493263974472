/* eslint-disable no-irregular-whitespace */
/* eslint-disable react/destructuring-assignment */

import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

interface Props {
  project: any;
  page: any;
}
const ProjectManagementTable = (props: Props) => {
  const history = useHistory();
  const { page } = props;
  const countValue = page.totalCount % page.defaultPagination.pageSize;
  const { pageNumber } = page;

  const renderProject = (project: any, index: any) => {
    return (
      <tr className="aui-table-status-red" key={index}>
        <td data-title="Project Name">
          <div className="aui-td">
            <button
              className="btn btn-href btn-username font-red p-0 font-weight-bold"
              onClick={() => {
                let backToPage = pageNumber;
                if (page.pagination.isLast) {
                  backToPage = countValue === 1 ? pageNumber - 1 : pageNumber;
                  if (backToPage === 0) {
                    backToPage = 1;
                  }
                }
                history.push(
                  {
                    pathname: `/project/${project.projectId}`,
                  },
                  {
                    state: {
                      backToPage,
                    },
                  }
                );
              }}
              type="button"
            >
              {project.projectName}
            </button>
          </div>
        </td>
        <td data-title="Team Size">
          <div className="aui-td font-500">{project.teamSize}</div>
        </td>
        <td data-title="Project Code">
          <div className="aui-td font-500">{project.projectCode}</div>
        </td>
        <td data-title="Project Manager">
          <div className="aui-td font-500">{project.projectManager}</div>
        </td>
        {/* <td data-title="Scrum Master">
          <div className="aui-td font-500">{project.scrumMaster}</div>
        </td> */}
        <td data-title="Start Date">
          <div className="aui-td font-500">{project.startDate}</div>
        </td>
        <td data-title="End Date">
          <div className="aui-td font-500">{project.endDate}</div>
        </td>
      </tr>
    );
  };
  return (
    <div className="projectlist-table">
      <table className="aui-responsive-table" aria-label="Project list details">
        <thead>
          <tr>
            <th scope="col">
              <div className="aui-th">Project Name</div>
            </th>
            <th scope="col">
              <div className="aui-th">Team Size</div>
            </th>
            <th scope="col">
              <div className="aui-th">Project Code</div>
            </th>
            <th scope="col">
              <div className="aui-th">Project Manager</div>
            </th>
            {/* <th scope="col">
              <div className="aui-th">Scrum Master</div>
            </th> */}
            <th scope="col">
              <div className="aui-th">Start Date</div>
            </th>
            <th scope="col">
              <div className="aui-th">End Date</div>
            </th>
          </tr>
        </thead>
        <tbody>{props.project.map(renderProject)} </tbody>
      </table>
    </div>
  );
};

export default ProjectManagementTable;
