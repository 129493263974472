export function parseJwt(token) {
  if (token && typeof token === "string") {
    const base64Url = token.split(".")[1];
    const base64 = base64Url?.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => {
          return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
        })
        .join("")
    );
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const istokenexpired = isTokenExpired(JSON.parse(jsonPayload));
    const parsedJWT = JSON.parse(jsonPayload);
    return { istokenexpired, parsedJWT };
  }
  return { istokenexpired: {}, parsedJWT: {} };
}
export async function isTokenExpired(jwt) {
  const tokenPayload = parseJwt(jwt);
  return Date.now() >= tokenPayload.exp * 1000;
}
