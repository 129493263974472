/* eslint-disable import/prefer-default-export */
import store from "app/store";
import { successToast } from "components/Toast/toast.slice";

export const onSuccess = (employeeId: any) => {
  const successmessage = `Employee with Employee ID ${employeeId} successfully removed from project `;
  const isValid = false;
  const result = {
    isValid,
    visibility: !isValid,
    message: successmessage,
  };
  store.dispatch(successToast(result));
  return result;
};
