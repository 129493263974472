import styled from "styled-components";

export const SignInWrapper = styled.div`
  .home-wrapper {
    width: 100%;
    min-height: 100vh;
    position: relative;
    @media only screen and (min-width:992px){
      display: flex;
    }
  }
  .home-wrapper-left {
    min-height: 345px;
    background: #c10e21 url("../../images/Signin_bg.png") no-repeat;
    background-size: cover;
    padding: 15px;
    margin-bottom: 470px;
    @media only screen and (min-width:576px){
      padding: 20px;
      height: 472px;
      margin-bottom: 344px;
    }
    @media only screen and (min-width:768px){
      padding: 30px;
      height: 551px;
      margin-bottom: 378px;
    }
    @media only screen and (min-width:992px){
      width: 50%;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0px;
    }
    img {
      height: 112px;
      display: block;
      margin: 0 auto;
      @media only screen and (min-width:576px){
        height: 122px;
      }
      @media only screen and (min-width:768px){
        height: 160px;
      }
      @media only screen and (min-width:992px){
        height: auto;
        display: inline;
      }
    }
  }
  .home-wrapper-right {
    height: auto;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #E3E3E3;
    border-radius: 11px;
    opacity: 1;
    display: flex;
    padding: 36px 24px;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 150px;
    left: 15px;
    right: 15px;
    @media only screen and (min-width:576px){
      padding: 44px 36px;
      top: 172px;
      left: 20px;
      right: 20px;
    }
    @media only screen and (min-width:768px){
      padding: 44px 36px;
      top: 250px;
      left: 30px;
      right: 30px;
    }
    @media only screen and (min-width:992px){
      min-height: 100vh;
      width: 50%;
      position: static;
      padding: 70px;
      border-radius: 0;
      box-shadow: none;
    }
    @media only screen and (min-width:1200px){
      min-height: 100vh;
      width: 50%;
      position: static;
      padding: 35px 80px;
      border-radius: 0;
      box-shadow: none;
    }
  }
  .home-content-logo{
    img{
      width: 150px;
    @media only screen and (min-width:576px){
      width: auto;
    }
    } 
  }
  .home-content {
    width: 100%;
  }
  .signin{
    font-size: 22px;
    @media only screen and (min-width:576px){
      font-size: 26px;
    }
    @media only screen and (min-width:768px){
      font-size: 36px;
    }
  }
  .home-signin-title {
    padding-top: 22px;
    border-bottom: 1px solid #c10e21;
    padding-bottom: 17px;
    margin-bottom: 20px;
    @media only screen and (min-width:576px){
      padding-top: 19px;
      margin-bottom: 28px;
    }
    @media only screen and (min-width:768px){
      padding-top: 28px;
    }
    @media only screen and (min-width:992px){
      padding-top: 40px;
    }

  }
  .home-helptext {
    font-size: 12px;
    @media only screen and (min-width:576px){
      font-size: 14px;
    }
  }
  .home-submit {
    padding-top: 8px;
    > *{
      display: block;
      width: 100%;
      text-align: center;
      @media only screen and (min-width:576px){
        display: inline-block;
        width:auto;
      }
    }
      @media only screen and (min-width:576px){
        padding-top: 18px;
      }
      @media only screen and (min-width:768px){
        padding-top: 22px;
      }
      @media only screen and (min-width:992px){
        padding-top: 12px;
      }
  }
  .home-fp {
    color: #c10e21;
  }
  .google-signin-title {
    border-bottom: 1px solid #e3e3e3;
    padding-top: 10px;
    padding-bottom: 15px;
    margin-bottom: 25px;
    font-size: 22px;
    color: #777777;
  }
  .btn-google {
    border-radius: 0;
    border: 1px solid #BCC3CA;
    color: #777777;
    padding: 5px 25px;
  }
  .btn-google img {
    width:30px;
  }
  }
`;
export const AnotherWraper = styled.div``;
