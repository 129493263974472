import CONSTANTS from "common/constants";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { parseJwt } from "utils";

export const ProjectManagementMenu = () => {
  const [userRoles, setUserRoles] = useState<any>();
  const userToken = localStorage.userAccessToken;
  const tokenPayload = parseJwt(userToken).parsedJWT;
  const { userRole } = tokenPayload;
  useEffect(() => {
    setUserRoles(userRole);
  });
  return (
    <div className="aui-sidenav">
      <nav className="navbar-expand-lg overflow-hidden">
        <button
          className="navbar-toggler float-right m-3"
          type="button"
          data-toggle="collapse"
          data-target="#sideNavbar"
          aria-controls="sideNavbar"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="aha-icon-hamburger-round" />
        </button>
        <div className="collapse navbar-collapse" id="sideNavbar">
          <ul className="w-100 p-0 ul-list">
            <li>
              <NavLink
                exact
                to="/project-list"
                className="nav-sidebar"
                activeClassName="nav-sidebar-active"
              >
                Project List
              </NavLink>
            </li>
            {(userRoles === CONSTANTS.ROLES.ADMIN ||
              userRoles === CONSTANTS.ROLES.SUPERADMIN) && (
              <li>
                <NavLink
                  exact
                  to="/addnewproject"
                  className="nav-sidebar"
                  activeClassName="nav-sidebar-active"
                >
                  Add New Project
                </NavLink>
              </li>
            )}
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default ProjectManagementMenu;
