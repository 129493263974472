import React, { ReactChild, ReactFragment, ReactPortal } from "react";

import "./styled.scss";

const Logo = (props: {
  color: any;
  title: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined;
}) => {
  return <div className={`d-flex circle ${props.color}`}>{props.title}</div>;
};

export default Logo;
