import config from "../config";
import store from "../app/store";
import { validJSON } from "../common/utils";
import { showToast } from "../components/Toast/toast.slice";

const googleSSOLogin = async (token: any): Promise<any> => {
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(token),
  };
  return new Promise((resolve, reject) => {
    fetch(
      `${config[config.env].apiEnspoints.accounts}/users/adminLogin`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        if (validJSON(result)) {
          const loginResponse = JSON.parse(result);
          if (loginResponse.statusCode === 200 && loginResponse.success) {
            localStorage.setItem("userAccessToken", loginResponse.data.token);
            resolve(loginResponse);
          } else {
            throw loginResponse.error;
          }
        } else {
          const errorResponse = {
            error: "Error",
            message: "Invalid Response JSON Format",
            code: "Invalid JSON",
          };
          throw errorResponse;
        }
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
        reject(error);
      });
  });
};
export default googleSSOLogin;
