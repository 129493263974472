import styled from "styled-components";

const CustomSelect = styled.div`
  .download-heading {
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .dropdown {
    width: 120px;
    height: 35px;
    @media screen and (min-width: 576px) and (max-width: 767px) {
      width: 80px;
      height: 35px;
    }
    @media screen and (min-width: 768px) and (max-width: 991px) {
      width: 100px;
      height: 35px;
    }
    @media screen and (min-width: 320px) and (max-width: 576px) {
      width: 100px;
      height: 35px;
    }
  }
  .ExportButton {
    margin-top: 2px;
  }
  .ExportSection {
    @media screen and (min-width: 320px) and (max-width: 576px) {
      display: flex;
      justify-content: center;
    }
  }
  .aui-label {
    @media screen and (min-width: 320px) and (max-width: 576px) {
      justify-content: center;
      display: flex;
    }
  }
  .inputs {
    @media screen and (min-width: 320px) and (max-width: 576px) {
      justify-content: center;
      display: flex;
    }
  }
`;
export default CustomSelect;
