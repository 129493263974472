import styled from "styled-components";

const RenderInvoiceWrapper = styled.div`
  .select-update-button {
    background-color: #c10e2a;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
  }
`;
export default RenderInvoiceWrapper;
