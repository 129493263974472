import React from "react";
import { Switch } from "react-router-dom";
import CONSTANTS from "common/constants";
import ChangePasswordPage from "pages/ChangePassword/ChangePassword";
import UserKeyPage from "pages/UserKey/UserKeyPage";
import EncryptionKeyPage from "pages/EncryptionKey/EncryptionKeyPage";
import UploadCSV from "components/UploadCSV";
import EDCInvoice from "../pages/ProjectPage/EDCInvoice";
import UserProfileEmployee from "../pages/EmployeeManagement/UserProfileEmployee";
import Settings from "../pages/Settings";
import InvoicePage from "../pages/ProjectPage/Invoice";
import InvoiceDate from "../pages/ProjectPage/InvoiceDate";
import UserProfile from "../pages/EmployeeManagement/UserProfile";
import Home from "../pages/Login/LoginPage";
import Route from "./Route";
import Dashboard from "../pages/Dashboard/Dashboard";
import EmployeeManagement from "../pages/EmployeeManagement/EmployeeManagementPage";
import AddNewEmployee from "../pages/EmployeeManagement/AddNewEmployee";
import ProjectManagement from "../pages/ProjectManagement/ProjectManagementPage";
import AddNewProject from "../pages/ProjectManagement/AddNewProject";
import ProjectPage from "../pages/ProjectPage/ShopCPRMainPage";
import TeamMember from "../pages/ProjectPage/TeamMember";
import DeactivatedList from "../pages/EmployeeManagement/DeactivatedList";
import ProtectedRoute from "./protectedRoute";

export default function Routes() {
  return (
    <Switch>
      <Route
        path="/ChangePasswordPage"
        exact
        component={ChangePasswordPage as any}
        hideHeader
      />
      <ProtectedRoute
        path="/Dashboard"
        exact
        component={Dashboard as any}
        requiredRole={[CONSTANTS.ROLES.ADMIN]}
      />
      <ProtectedRoute
        path="/encryptionkey"
        exact
        component={EncryptionKeyPage as any}
        requiredRole={[CONSTANTS.ROLES.SUPERADMIN]}
      />
      <ProtectedRoute
        path="/employee-management"
        exact
        component={EmployeeManagement as any}
        requiredRole={[CONSTANTS.ROLES.ADMIN, CONSTANTS.ROLES.HR]}
      />
      <ProtectedRoute
        path="/employee-upload"
        exact
        component={UploadCSV as any}
        requiredRole={[CONSTANTS.ROLES.ADMIN, CONSTANTS.ROLES.HR]}
      />
      <ProtectedRoute
        path="/userkey"
        exact
        component={UserKeyPage as any}
        requiredRole={[CONSTANTS.ROLES.ADMIN, CONSTANTS.ROLES.HR]}
      />
      <ProtectedRoute
        path="/addnewemployee"
        exact
        component={AddNewEmployee as any}
        requiredRole={[CONSTANTS.ROLES.ADMIN, CONSTANTS.ROLES.HR]}
      />
      <ProtectedRoute
        path="/userprofile/:employeeId"
        exact
        component={UserProfile as any}
        requiredRole={[CONSTANTS.ROLES.ADMIN, CONSTANTS.ROLES.HR]}
      />
      <ProtectedRoute
        path="/deactivatedList/:employeeId"
        exact
        component={UserProfileEmployee as any}
        requiredRole={[CONSTANTS.ROLES.ADMIN, CONSTANTS.ROLES.HR]}
      />
      <ProtectedRoute
        path="/deactivatedlist"
        exact
        component={DeactivatedList as any}
        requiredRole={[CONSTANTS.ROLES.ADMIN, CONSTANTS.ROLES.HR]}
      />
      <ProtectedRoute
        path="/project-list"
        exact
        component={ProjectManagement as any}
        requiredRole={[CONSTANTS.ROLES.ADMIN]}
      />
      <ProtectedRoute
        path="/addnewproject"
        exact
        component={AddNewProject as any}
        requiredRole={[CONSTANTS.ROLES.ADMIN]}
      />
      <ProtectedRoute
        path="/project/:projectId"
        exact
        component={ProjectPage as any}
        requiredRole={[CONSTANTS.ROLES.ADMIN]}
      />
      <ProtectedRoute
        path="/teammember/:projectId"
        exact
        component={TeamMember as any}
        requiredRole={[CONSTANTS.ROLES.ADMIN]}
      />
      <ProtectedRoute
        path="/invoice/:projectId"
        exact
        component={InvoicePage as any}
        requiredRole={[CONSTANTS.ROLES.ADMIN]}
      />
      <ProtectedRoute
        path="/invoicedate/:projectId/:month/:year/:type"
        exact
        component={InvoiceDate as any}
        requiredRole={[CONSTANTS.ROLES.ADMIN]}
      />
      <ProtectedRoute
        path="/edcinvoice/:projectId/:month/:year/:type"
        exact
        component={EDCInvoice as any}
        requiredRole={[CONSTANTS.ROLES.ADMIN]}
      />
      <ProtectedRoute
        path="/settings"
        exact
        component={Settings as any}
        requiredRole={[CONSTANTS.ROLES.ADMIN]}
      />
      <Route path="/*" exact component={Home as any} hideHeader />
    </Switch>
  );
}
