import { useHistory } from "react-router-dom";
import addSalaryHistory from "api/employeeSalaryHistoryApi";
import { getEmployees } from "api/getEmployees";
import getRoleName from "api/rolesAPI";
import updateSalary from "api/updateSalaryApi";
import updateSalaryTillDate from "api/updateSalaryHistoryApi";
import store from "app/store";
import CONSTANTS from "common/constants";
import Dropdown from "components/CustomSelect";
import { showToast, successToast } from "components/Toast/toast.slice";
import React, { useEffect, useState } from "react";
import { parseJwt } from "utils";
import getEmpRole from "api/getRoleAPI";
import getworkCategorizationName from "../../api/WorkCategorizationsAPI";

interface Props {
  employee: any;
  page: any;
  backToTable: any;
  utilizationSortBy: any;
  roleFilterBy: any;
  workCategorizationFilterBy: any;
  searchBy: any;
}
const EmployeeTable = (props: Props) => {
  const history = useHistory();
  const { backToTable } = props;
  const { page } = props;
  const countValue = page.totalCount % page.defaultPagination.pageSize;
  const { pageNumber } = page;
  const [role, setRole] = useState("");
  const [utilizationOrder, setUtilizationOrder] = useState("");
  const [workCategorization, setWorkCategorization] = useState("");
  const [isEditUtil, setIsEditUtil] = useState(false);
  const [isEditRole, setIsEditRole] = useState(false);
  const [isEditWorkCategorization, setIsEditWorkCategorization] =
    useState(false);
  const [isSearch, setIsSearch] = useState("search");
  const [roleName, setRoleName] = useState<any>([]);
  const [workCategorizationName, setWorkCategorizationName] = useState<any>([]);
  const [roleList, setRoleList] = useState<any>([]);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState();
  const [selectedEmployeeName, setSelectedEmployeeName] = useState("");
  const [vendorCost, selectedVendorCost] = useState<any>();
  const [salary, setSalary] = useState("");
  const [salaryEffective, setSalaryEffective] = useState("");
  const [loading, setLoading] = useState(false);
  const [workCategorizationList, setWorkCategorizationList] = useState<any>([]);
  const [userRoles, setUserRoles] = useState<any>();
  const userToken = localStorage.userAccessToken;
  const tokenPayload = parseJwt(userToken).parsedJWT;
  const { userRole } = tokenPayload;
  useEffect(() => {
    setUserRoles(userRole);
  });

  const roleRequest = () => {
    getEmpRole()
      .then((Response) => {
        setRoleName(Response.data.uniqueRolesArray);
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };

  useEffect(() => {
    const updatedRoleList = getRoleList();
    setRoleList(updatedRoleList);
  }, [roleName]);

  const getRoleList = () => {
    const updatedRoleList = [
      {
        label: "ALL ROLES",
        ariaLabel: "all roles",
        value: CONSTANTS.ALL,
      },
    ];

    if (roleName.length > 0) {
      roleName.forEach((role: any) => {
        updatedRoleList.push({
          label: role,
          value: role,
          ariaLabel: role,
        });
      });
    }
    return updatedRoleList;
  };

  const workCategorizationRequest = () => {
    getworkCategorizationName()
      .then((Response) => {
        setWorkCategorizationName(Response.data.workCategorizations);
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };
  const getWorkCategorizationList = () => {
    workCategorizationList[0] = {
      label: "ALL WORK CATEGORIZATION",
      ariaLabel: "All Work Categorizations",
      value: CONSTANTS.ALL,
    };
    if (
      workCategorizationList.length < workCategorizationName.length &&
      workCategorizationName.length !== 0
    ) {
      for (let i = 0; i < workCategorizationName.length; i += 1) {
        workCategorizationList.push({
          label: workCategorizationName[i].name.toUpperCase(),
          value: workCategorizationName[i].name,
          ariaLabel: workCategorizationName[i].name.toUpperCase(),
        });
      }
    }
    return workCategorizationList;
  };
  const success = (employeeId: any) => {
    const successmessage = `The Salary for Employee ID ${employeeId} is updated successfully`;
    const isValid = false;
    const result = {
      isValid,
      visibility: !isValid,
      message: successmessage,
    };
    store.dispatch(successToast(result));
    return result;
  };

  const resetModal = () => {
    setSalaryEffective("");
    setSalary("");
  };

  const editSalary = (event: any) => {
    event.preventDefault();
    const employeeUpdate = {
      employeeId: selectedEmployeeId,
      salary: parseFloat(salary),
      salaryeffectivefromDate: new Date(),
    };
    updateSalary(employeeUpdate)
      .then((patchResponse: any) => {
        success(employeeUpdate.employeeId);
        updateSalaryTillDate(employeeUpdate)
          .then((putResponse: any) => {
            success(employeeUpdate.employeeId);
            resetModal();
          })
          .catch((error: any) => {
            store.dispatch(showToast(error));
          });
        backToTable();
      })
      .catch((error: any) => {
        store.dispatch(showToast(error.details[0].message));
      });
  };
  const [employeeData, setEmployeeData] = useState("");

  useEffect(() => {
    setSelectedEmployeeId(selectedEmployeeId);
    roleRequest();
    if (isEditRole) {
      const { roleFilterBy } = props;
      roleFilterBy(role);
    }
    workCategorizationRequest();
    if (isEditWorkCategorization) {
      const { workCategorizationFilterBy } = props;
      workCategorizationFilterBy(workCategorization);
    }
    if (isEditUtil) {
      const { utilizationSortBy } = props;
      utilizationSortBy(utilizationOrder);
    }
    if (employeeData !== "") {
      setIsSearch("cross");
      const { searchBy } = props;
      searchBy(employeeData);
    }
    if (employeeData === "") {
      setIsSearch("search");
      const { searchBy } = props;
      searchBy("");
    }

    if (CONSTANTS.WINDOW.AUI.Search) {
      CONSTANTS.WINDOW.AUI.Search.init();
    }
  }, [
    selectedEmployeeId,
    role,
    workCategorization,
    utilizationOrder,
    employeeData,
  ]);
  const searchHandle = (event: any) => {
    setEmployeeData(event.target.value);
    const { searchBy } = props;
    searchBy(event.target.value);
    setLoading(true);
    setTimeout(() => setLoading(false), 2000);
  };
  const renderEmployee = (employee: any, index: any) => {
    const dbDate = new Date(employee.vendorCostExpiryDate);
    dbDate.setHours(0, 0, 0, 0);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    return (
      <tr className="aui-table-status-red" key={index}>
        <td data-title="ID">
          <div className="aui-td">
            <strong>{employee.employeeId}</strong>
          </div>
        </td>
        <td data-title="Name">
          <div className="aui-td">
            <div>
              <button
                className="btn btn-href btn-username txtleft font-red"
                onClick={() => {
                  let backToPage = pageNumber;
                  if (page.pagination.isLast) {
                    backToPage = countValue === 1 ? pageNumber - 1 : pageNumber;
                    if (backToPage === 0) {
                      backToPage = 1;
                    }
                  }
                  history.push(
                    {
                      pathname: `/userprofile/${employee.employeeId}`,
                    },
                    {
                      state: {
                        backToPage,
                      },
                    }
                  );
                }}
                type="button"
              >
                {employee.fullName}
              </button>
              <p className="mb-0">{employee.impelsysEmail}</p>
              <p>{employee.ahaEmail}</p>
            </div>
          </div>
        </td>
        <td data-title="Role">
          <div className="aui-td">{employee.role.join(", ")}</div>
        </td>
        {userRoles !== CONSTANTS.ROLES.HR && (
          <td data-title="Monthly Employee Cost">
            <div className="aui-td d-flex positive-relative teccenterstyle">
              {(parseInt(employee.onlySalary, 10) / 12).toFixed(2)}
              {(userRoles === CONSTANTS.ROLES.ADMIN ||
                userRoles === CONSTANTS.ROLES.SUPERADMIN) && (
                <button
                  type="button"
                  className="btn btn-href font-500 w-auto button-transistion p-0 ml-2"
                  data-toggle="modal"
                  onClick={() => {
                    setSelectedEmployeeId(employee.employeeId);
                    setSelectedEmployeeName(employee.fullName);
                    selectedVendorCost(employee.vendorCost);
                  }}
                  data-target={`#salaryModal-${index}`}
                  data-keyboard="false"
                  aria-label="edit salary"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="demo-icon aha-icon-pencil" />
                </button>
              )}
              <div
                className="modal fade show aui-modal "
                data-backdrop="false"
                id={`salaryModal-${index}`}
                tabIndex={-1}
                aria-modal="true"
                role="dialog"
              >
                <div className="modal-dialog modal-dialog-centered modal-sm">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="h4" id={`editSalary-${index}`}>
                        {selectedEmployeeName} ({selectedEmployeeId})
                      </h4>
                    </div>
                    <form autoComplete="off">
                      <div className="modal-body p-0">
                        {vendorCost &&
                        dbDate.getTime() >= currentDate.getTime() ? (
                          <div className="form-group row row-pt-3">
                            <label
                              className="col-6 col-lg-6 col-form-label"
                              htmlFor={`vendorCost-${index}`}
                            >
                              Annual Vendor Cost (₹)
                            </label>
                            <div className="col-6">
                              <input
                                type="text"
                                aria-label="vendorcost"
                                id={`vendorCost-${index}`}
                                className="form-control input-style bggrey"
                                value={parseFloat(vendorCost) * 12}
                                disabled
                              />
                            </div>
                          </div>
                        ) : null}
                        <div className="form-group row  row-pt-3 required">
                          <label
                            className="col-6 col-lg-6 col-form-label"
                            htmlFor={`salary-${index}`}
                          >
                            Annual Gross Salary (₹)
                          </label>
                          <div className="col-6">
                            <input
                              name="salary"
                              type="number"
                              aria-label="salary"
                              className="form-control"
                              placeholder={`${parseFloat(employee.onlySalary)}`}
                              id={`salary-${index}`}
                              value={salary}
                              onChange={(event: any) => {
                                setSalary(event.target.value);
                              }}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer mt-4">
                        <div className="w-100">
                          <div className="d-flex flex-row-reverse">
                            <button
                              type="button"
                              className="btn btn-primary btn-round"
                              onClick={(event: any) => {
                                editSalary(event);
                              }}
                              data-dismiss="modal"
                              disabled={!salary}
                            >
                              Submit
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary btn-round mr-4"
                              onClick={resetModal}
                              data-dismiss="modal"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </td>
        )}
        <td data-title="Team">
          <div className="aui-td font-bold">
            {employee.team.join(", ") ? employee.team.join(", ") : "NA"}
          </div>
        </td>
        <td data-title="Work Categorization">
          <div className="aui-td font-bold">
            {employee.workCategorization ? employee.workCategorization : "NA"}
          </div>
        </td>
        <td data-title="Utilization">
          <div className="btn aui-td font-500 button-transition">
            <div className="util_hover">
              <span>{employee.utilization}</span>
              {employee.utilization > 0 ? (
                <div className="utiltooltip">
                  <ul className="utiltooltip_ul_list">
                    <li className="utiltooltip_li_list">
                      <div>
                        <div>
                          <h4 className="headingstyle">
                            {employee.fullName} ({employee.employeeId})
                          </h4>
                        </div>
                        <form autoComplete="off">
                          <div className="modal-body p-0 pt-3">
                            {employee.projects.map((obj: any) => {
                              return (
                                <div className="form-group row row-pt-3">
                                  <label className="col-6 col-lg-6 col-form-label">
                                    {obj.projectName}
                                  </label>
                                  <div className="col-6">
                                    <input
                                      className="form-control"
                                      value={`${Math.round(
                                        parseInt(
                                          `${obj.projectUtilization} `,
                                          10
                                        )
                                      )}%`}
                                      disabled
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </form>
                      </div>
                    </li>
                  </ul>
                </div>
              ) : null}
            </div>
          </div>
        </td>
      </tr>
    );
  };
  const { employee } = props;

  return (
    <div
      className={`employeelist-table ${
        userRole === "HR" ? "employee-hr-table" : ""
      }`}
    >
      <div className="d-flex spaceeven">
        <div
          id="aui-search"
          className="col-md-7 col-lg-5 p-0 aui-search-dropdown dropdown-style"
        >
          <input
            id="aui-select-employee"
            className="form-control"
            type="text"
            value={employeeData}
            onChange={searchHandle}
            placeholder="Search"
            aria-label="Search Employee"
          />
          <button
            type="button"
            aria-label="employee search icon"
            onClick={() => {
              if (isSearch === "cross") {
                setEmployeeData("");
                setIsSearch("search");
                const { searchBy } = props;
                searchBy("");
              }
            }}
            className="aui-td btn btn-text ml-2 w-auto bordernone searchicon "
          >
            <i
              className={
                isSearch === "cross"
                  ? "aha-icon-cross pr-2 position-relative "
                  : "aha-icon-search pr-2 position-relative "
              }
            />
          </button>
        </div>
        <div className="d-flex justify-content-end pb-4">
          {roleList && (
            <Dropdown
              items={roleList}
              selectedValue={role}
              callParentOnSelect={(value: any) => {
                setRole(value);
                setIsEditRole(true);
              }}
              id="roleName"
              disabled={false}
              isLoading={false}
            />
          )}
        </div>
        <div className="d-flex justify-content-end pb-4">
          {getWorkCategorizationList() && (
            <Dropdown
              items={workCategorizationList}
              selectedValue={workCategorization}
              callParentOnSelect={(value: any) => {
                setWorkCategorization(value);
                setIsEditWorkCategorization(true);
              }}
              id="WorkCategorizationName"
              disabled={false}
              isLoading={false}
            />
          )}
        </div>
      </div>
      <table className="aui-responsive-status-table">
        <thead>
          <tr>
            <th scope="col">
              <div className="aui-th">ID</div>
            </th>
            <th scope="col">
              <div className="aui-th">Name & Email</div>
            </th>
            <th scope="col">
              <div className="aui-th"> Role</div>
            </th>
            {userRoles !== CONSTANTS.ROLES.HR && (
              <th scope="col">
                <div className="aui-th">Monthly Employee Cost</div>
              </th>
            )}
            <th scope="col">
              <div className="aui-th">Team</div>
            </th>
            <th scope="col">
              <div className="aui-th">Work Categorization</div>
            </th>
            <th scope="col">
              <div className="aui-th sort-style">
                Utilization (%)
                <button
                  type="button"
                  aria-label={`sort icon ${
                    utilizationOrder === "ASC" ? "descending" : "ascending"
                  }`}
                  onClick={() => {
                    setUtilizationOrder(
                      utilizationOrder === "ASC" ? "DESC" : "ASC"
                    );
                    setIsEditUtil(true);
                  }}
                  className="aui-td btn btn-text ml-2 w-auto bordernone"
                >
                  <i
                    className={
                      utilizationOrder === "ASC"
                        ? "demo-icon aha-icon-up-dir sort-icon"
                        : "demo-icon aha-icon-down-dir sort-icon"
                    }
                  />
                </button>
              </div>
            </th>
          </tr>
        </thead>
        {loading ? null : (
          <tbody>
            {employee.length > 0 ? (
              employee.map(renderEmployee)
            ) : (
              <tr className="aui-status-table-emptystate">
                <td colSpan={7}>
                  <div className="aui-td">No data to display</div>
                </td>
              </tr>
            )}
          </tbody>
        )}
      </table>
      {loading ? (
        <table
          className="aui-responsive-table aui-table-cols aui-table-loader"
          role="alert"
          aria-live="assertive"
          aria-label="Table is Loading"
        />
      ) : null}
    </div>
  );
};
export default EmployeeTable;
