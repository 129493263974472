import styled from "styled-components";

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContent = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  width: 500px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ModalBody = styled.div`
  text-align: center;
`;

export const ModalButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  background-color: #c10e21;
  color: #ffffff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff7875;
  }
`;
